import React from 'react'
import styles from './foundationcontainer.module.css'
import { Route, Routes } from 'react-router-dom'
import SocialRightPart from '../../SocialPage/RightPart/SocialRightPart'
import { PoliticalPartyFoundation } from './PoliticalParty/PoliticalPartyFoundation'

// Para fundar una Organization en / organizations/*
export const FoundationContainer = () => {
  return (
    <div className={styles.socialContainer}>

      <div className={styles.feedContainer}>


        <Routes>
          <Route path="/company" element={<p>En desarrollo</p>} />
          <Route path="/political-party" element={<PoliticalPartyFoundation/>} />
          <Route path="/trade-union" element={<p>En desarrollo</p>} />
          <Route path="/religious-organization" element={<p>En desarrollo</p>} />
          <Route path="/ngo" element={<p>En desarrollo</p>} />
          <Route path="/think-tank" element={<p>En desarrollo</p>} />
          <Route path="/advocacy-group" element={<p>En desarrollo</p>} />
          <Route path="/secret-society" element={<p>En desarrollo</p>} />
        </Routes>


      </div>

      <div className={styles.socialRightPart}>

        <SocialRightPart />

      </div>

    </div>
  )
}
