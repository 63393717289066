import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import styles from './rightpart.module.css'

const SocialRightPart = ({ withSearchBar = true }) => {
    return (
        <div className={styles.container} >
 
                {withSearchBar &&
                    <div className={styles.searchBar}>
                        <div >
                            <SearchIcon className={styles.searchIcon} />
                        </div>
                        <input type="text" placeholder='Search' className=''>
                        </input>


                    </div>
                }

                <div className={styles.card}>
                    <h1>Who to follow</h1>
                    <div className={styles.profile}>
                        <img src='https://pbs.twimg.com/profile_images/874276197357596672/kUuht00m_400x400.jpg' />
                        <div className={styles.profileInfo}>
                            <p className={styles.name}>Donald J. Trump</p>
                            <p className={styles.username}>@realDonaldTrump</p>
                        </div>
                        <button>Follow</button>
                    </div>

                    <div className={styles.profile}>
                        <img src='https://pbs.twimg.com/profile_images/1592241313700782081/T2pTYU8d_400x400.jpg' />
                        <div className={styles.profileInfo}>
                            <p className={styles.name}>Kamala Harris</p>
                            <p className={styles.username}>@KamalaHarris</p>
                        </div>
                        <button>Follow</button>
                    </div>

                    <div className={styles.profile}>
                        <img src='https://pbs.twimg.com/profile_images/1665141083624423428/geiQrLwM_400x400.jpg' />
                        <div className={styles.profileInfo}>
                            <p className={styles.name}>Robert F. Kennedy Jr</p>
                            <p className={styles.username}>@RobertKennedyJr</p>
                        </div>
                        <button>Follow</button>
                    </div>
                    <button className={styles.showMore}>
                        Show more
                    </button>
                </div>

                <div className={styles.card}>
                    <h1>Spanish trends <small>(ES)</small></h1>

                    <div className={styles.trendingItem}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <p className={styles.trendCategoryAndRank}>
                                1 · Trending
                            </p>
                            <MoreHorizIcon className={styles.horizIcon} />
                        </div>

                        <p className={styles.name}>
                            Elecciones España
                        </p>
                    </div>


                    <div className={styles.trendingItem}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <p className={styles.trendCategoryAndRank}>
                                2 · Trending
                            </p>
                            <MoreHorizIcon className={styles.horizIcon} />
                        </div>

                        <p className={styles.name}>
                            Precios altos
                        </p>
                    </div>

                    <div className={styles.trendingItem}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <p className={styles.trendCategoryAndRank}>
                                3 · Trending
                            </p>
                            <MoreHorizIcon className={styles.horizIcon} />
                        </div>

                        <p className={styles.name}>
                            Guerra
                        </p>
                    </div>




            </div>

        </div>
    )
}

export default SocialRightPart