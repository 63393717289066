import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { registerUser } from '../../Store/Auth/Action';
import { useNavigate } from 'react-router-dom';

import styles from './signupform.module.css'

const validationSchema = Yup.object().shape({
  mail: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Minimum eight characters'),
  name: Yup.string()
    .required('Name is required')
    .min(3, 'Minimum three characters'),
  username: Yup.string()
    .required('Username is required')
    .min(3, 'Minimum three characters'),
  sex: Yup.string()
    .required('Sex is required'),
  race: Yup.string()
    .required('Race is required'),
  culture: Yup.string()
    .required('Culture is required'),
  belief: Yup.string()
    .required('Belief is required')
});

const SignupForm = ({ loading }) => {

  // Utilizamos el useNavigate para que, una vez se envíe el formulario, se cambie a la url de /verify. Navigate lo pasamos al Action de registerUser.
  const navigate = useNavigate();

  const getGeolocationInfo = async () => {
    try {

      const geolocationData = await fetch("https://ipinfo.io/json?token=fde0b4c13cf86e").then(
        (response) => response.json());

      console.log(geolocationData);

      return {
        country: geolocationData.country,
        city: geolocationData.city
      }

    } catch (error) {
      console.error('Error fetching language:', error)
    }
  }


  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      mail: '',
      password: '',
      name: '',
      username: '',
      sex: '',
      race: '',
      culture: '',
      belief: '',
    },
    validationSchema,
    onSubmit: async (values) => {

      try {
        loading(true);

        const geolocationInfo = await getGeolocationInfo();

        const valuesWithGeolocation = {
          ...values,
          city: geolocationInfo.city,
          country: geolocationInfo.country
        }

        const response = await dispatch(registerUser(valuesWithGeolocation))

        if (response) {
          navigate('/verify');
        } else {
          console.error("Registration failed:", response.message);
        }


        loading(false);

      } catch (error) {

        console.log(error)
        loading(false);

      } finally {
        loading(false);
      }
    },
  });

  const [isMailFocused, setIsMailFocused] = useState(false);

  const handleMailFocus = () => {
    setIsMailFocused(true);
  };

  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };

  const [isNameFocused, setIsNameFocused] = useState(false);

  const handleNameFocus = () => {
    setIsNameFocused(true);
  };

  const [isUsernameFocused, setIsUsernameFocused] = useState(false);

  const handleUsernameFocus = () => {
    setIsUsernameFocused(true);
  };

  const [isSexFocused, setIsSexFocused] = useState(false);

  const handleSexFocus = () => {
    setIsSexFocused(true);
  };

  const [isRaceFocused, setIsRaceFocused] = useState(false);

  const handleRaceFocus = () => {
    setIsRaceFocused(true);
  };

  const [isCultureFocused, setIsCultureFocused] = useState(false);

  const handleCultureFocus = () => {
    setIsCultureFocused(true);
  };

  const [isBeliefFocused, setIsBeliefFocused] = useState(false);

  const handleBeliefFocus = () => {
    setIsBeliefFocused(true);
  };

  return (

    <div className={styles.formWrapper}>
      <form onSubmit={formik.handleSubmit} className={styles.formContainer}>

        <div className={styles.inputGroup}>

          <TextField
            className={styles.textField}
            label="Email"
            name="mail"
            variant="outlined"

            value={formik.values.mail}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={handleMailFocus}
            error={formik.touched.mail && Boolean(formik.errors.mail)}
            helperText={formik.touched.mail && formik.errors.mail}
            InputProps={{
              style: {
                backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                border: isMailFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                color: 'white'
              },
            }}
            InputLabelProps={{
              style: {
                color: '#71767b', // Reemplaza 'white' con el color deseado
              },
            }}
          />


          <TextField
            className={styles.textField}
            label="Password"
            name="password"
            type="password"
            variant="outlined"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={handlePasswordFocus}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              style: {
                backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                border: isPasswordFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                color: 'white'
              },
            }}
            InputLabelProps={{
              style: {
                color: '#71767b', // Reemplaza 'white' con el color deseado
              },
            }}
          />

        </div>
        <div className={styles.inputGroup}>
          <TextField
            className={styles.textField}
            label="Name"
            name="name"
            variant="outlined"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={handleNameFocus}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            InputProps={{
              style: {
                backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                border: isNameFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                color: 'white'
              },
            }}
            InputLabelProps={{
              style: {
                color: '#71767b', // Reemplaza 'white' con el color deseado
              },
            }}
          />

          <TextField
            className={styles.textField}
            label="Username"
            name="username"
            variant="outlined"
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={handleUsernameFocus}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
            InputProps={{
              style: {
                backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                border: isUsernameFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                color: 'white'
              },
            }}
            InputLabelProps={{
              style: {
                color: '#71767b', // Reemplaza 'white' con el color deseado
              },
            }}
          />

        </div>
        <div className={styles.inputGroup}>
          <TextField
            className={styles.textField}
            id='sex'
            label="Sex"
            select
            name="sex"
            variant="outlined"
            value={formik.values.sex}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={handleSexFocus}
            error={formik.touched.sex && Boolean(formik.errors.sex)}
            helperText={formik.touched.sex && formik.errors.sex}
            InputProps={{
              style: {
                backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                border: isSexFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                color: 'white'
              },
            }}
            InputLabelProps={{
              style: {
                color: '#71767b', // Reemplaza 'white' con el color deseado
              },
            }}
          >

            <MenuItem value='MALE'>Male</MenuItem>
            <MenuItem value='FEMALE'>Female</MenuItem>

          </TextField>

          <TextField
            className={styles.textField}
            id='race'
            label="Race"
            select
            name="race"
            variant="outlined"
            value={formik.values.race}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={handleRaceFocus}
            error={formik.touched.race && Boolean(formik.errors.race)}
            helperText={formik.touched.race && formik.errors.race}
            InputProps={{
              style: {
                backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                border: isRaceFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                color: 'white'
              },
            }}
            InputLabelProps={{
              style: {
                color: '#71767b', // Reemplaza 'white' con el color deseado
              },
            }}
          >

            <MenuItem value='CAUCASOID'>Caucasoid</MenuItem>
            <MenuItem value='MONGOLOID'>Mongoloid</MenuItem>
            <MenuItem value='NEGROID'>Negroid</MenuItem>
            <MenuItem value='DRAVIDA'>Dravida</MenuItem>

          </TextField>

        </div>

        <div className={styles.inputGroup}>

          <TextField
            className={styles.textField}
            id='culture'
            label="Culture"
            select
            name="culture"
            variant="outlined"
            value={formik.values.culture}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={handleCultureFocus}
            error={formik.touched.culture && Boolean(formik.errors.culture)}
            helperText={formik.touched.culture && formik.errors.culture}
            InputProps={{
              style: {
                backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                border: isCultureFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                color: 'white'
              },
            }}
            InputLabelProps={{
              style: {
                color: '#71767b', // Reemplaza 'white' con el color deseado
              },
            }}
          >

            <MenuItem value='ARGENTINIAN'>Argentinian</MenuItem>
            <MenuItem value='AFRIKAANS'>Afrikaans</MenuItem>
            <MenuItem value='ANGLO_CARIBBEAN'>Anglo Caribbean</MenuItem>
            <MenuItem value='AUSTRALIAN'>Australian</MenuItem>
            <MenuItem value='ALGERIAN'>Algerian</MenuItem>
            <MenuItem value='ALBANIAN'>Albanian</MenuItem>
            <MenuItem value='ARMENIAN'>Armenian</MenuItem>
            <MenuItem value='ASLIAN'>Aslian</MenuItem>
            <MenuItem value='AZERBAIJANI'>Azerbaijani</MenuItem>
            <MenuItem value='ADAMAWAUBANGI'>Adamawa Ubangi</MenuItem>
            <MenuItem value='ATLANTICCONGO'>Atlantic Congo</MenuItem>
            <MenuItem value='AMHARIC'>Amharic</MenuItem>

            <MenuItem value='BAMBARE'>Bambara</MenuItem>
            <MenuItem value='BANTOID'>Bantoid</MenuItem>
            <MenuItem value='BANTU'>Bantu</MenuItem>
            <MenuItem value='BENUE'>Benue</MenuItem>
            <MenuItem value='BASQUE'>Basque</MenuItem>
            <MenuItem value='BERBER'>Berber</MenuItem>
            <MenuItem value='BELARUSIAN'>Belarusian</MenuItem>
            <MenuItem value='BOSNIAKS'>Bosniaks</MenuItem>
            <MenuItem value='BULGARIAN'>Bulgarian</MenuItem>
            <MenuItem value='BOLIVIAN'>Bolivian</MenuItem>
            <MenuItem value='BRAZILIAN'>Brazilian</MenuItem>
            <MenuItem value='BRITISH'>British</MenuItem>
            <MenuItem value='BRETON'>Breton</MenuItem>
            <MenuItem value='BRAHUI'>Brahui</MenuItem>
            <MenuItem value='BIHARI'>Bihari</MenuItem>
            <MenuItem value='BENGALI'>Bengali</MenuItem>
            <MenuItem value='BURMESE'>Burmese</MenuItem>
            <MenuItem value='BAHNARIC'>Bahnaric</MenuItem>
            <MenuItem value='BALOOCHI'>Balochi</MenuItem>

            <MenuItem value='CROATS'>Croats</MenuItem>
            <MenuItem value='CZECH'>Czech</MenuItem>
            <MenuItem value='CHADIC'>Chadic</MenuItem>
            <MenuItem value='CATALAN'>Catalan</MenuItem>
            <MenuItem value='CENTRAL_AMERICAN'>Central American</MenuItem>
            <MenuItem value='CHILEAN'>Chilean</MenuItem>
            <MenuItem value='COLOMBIAN'>Colombian</MenuItem>
            <MenuItem value='CANADIAN'>Canadian</MenuItem>
            <MenuItem value='CHECHEN'>Chechen</MenuItem>

            <MenuItem value='DAGESTAN'>Dagestan</MenuItem>
            <MenuItem value='DANISH'>Danish</MenuItem>
            <MenuItem value='DUTCH'>Dutch</MenuItem>
            <MenuItem value='DUTCH_CARIBBEAN'>Dutch Caribbean</MenuItem>
            <MenuItem value='DARI'>Dari</MenuItem>

            <MenuItem value='EGYPTIAN'>Egyptian</MenuItem>
            <MenuItem value='ECUADORIAN'>Ecuadorian</MenuItem>
            <MenuItem value='ESTONIAN'>Estonian</MenuItem>
            <MenuItem value='ESKIMO'>Eskimo</MenuItem>

            <MenuItem value='FRENCH'>French</MenuItem>
            <MenuItem value='FRANCO_BELGIAN'>Franco Belgian</MenuItem>
            <MenuItem value='FRANCO_CARIBBEAN'>Franco Caribbean</MenuItem>
            <MenuItem value='FLEMISH_BELGIAN'>Flemish Belgian</MenuItem>
            <MenuItem value='FINNISH'>Finnish</MenuItem>

            <MenuItem value='GUJARATI'>Gujarati</MenuItem>
            <MenuItem value='GALICIAN'>Galician</MenuItem>
            <MenuItem value='GERMAN'>German</MenuItem>
            <MenuItem value='GUYANESE'>Guyanese</MenuItem>
            <MenuItem value='GEORGIAN'>Georgian</MenuItem>
            <MenuItem value='GUR'>Gur</MenuItem>
            <MenuItem value='GAN'>Gan</MenuItem>
            <MenuItem value='GREEK'>Greek</MenuItem>

            <MenuItem value='HEBREW'>Hebrew</MenuItem>
            <MenuItem value='HAKKA'>Hakka</MenuItem>
            <MenuItem value='HISPANIC_CARIBBEAN'>Hispanic Caribbean</MenuItem>
            <MenuItem value='HINDI'>Hindi</MenuItem>
            <MenuItem value='HUNGARIAN'>Hungarian</MenuItem>

            <MenuItem value='ICELANDIC'>Icelandic</MenuItem>
            <MenuItem value='ITALIAN'>Italian</MenuItem>
            <MenuItem value='IRAQI'>Iraqi</MenuItem>
            <MenuItem value='INDONESIAN'>Indonesian</MenuItem>
            <MenuItem value='IRISH'>Irish</MenuItem>

            <MenuItem value='JAPANESE'>Japanese</MenuItem>
            <MenuItem value='JORDANIANS'>Jordanians</MenuItem>
            <MenuItem value='JEMER'>Jemer</MenuItem>
            <MenuItem value='JIN'>Jin</MenuItem>

            <MenuItem value='KARENIC'>Karenic</MenuItem>
            <MenuItem value='KHMUIC'>Khmuic</MenuItem>
            <MenuItem value='KANNADA'>Kannada</MenuItem>
            <MenuItem value='KORDOFANIAN'>Kordofanian</MenuItem>
            <MenuItem value='KWA'>Kwa</MenuItem>
            <MenuItem value='KRU'>Kru</MenuItem>
            <MenuItem value='KURDISH'>Kurdish</MenuItem>
            <MenuItem value='KAZAKH'>Kazakh</MenuItem>
            <MenuItem value='KIRGYZ'>Kyrgyz</MenuItem>
            <MenuItem value='KHOISAN'>Khoisan</MenuItem>
            <MenuItem value='KOREAN'>Korean</MenuItem>

            <MenuItem value='LEBANESE'>Lebanese</MenuItem>
            <MenuItem value='LIBYAN'>Libyan</MenuItem>
            <MenuItem value='LATVIAN'>Latvian</MenuItem>
            <MenuItem value='LITHUANIAN'>Lithuanian</MenuItem>
            <MenuItem value='LAO'>Lao</MenuItem>

            <MenuItem value='MAURITANIAN'>Mauritanian</MenuItem>
            <MenuItem value='MACEDONIANS'>Macedonians</MenuItem>
            <MenuItem value='MOROCCAN'>Moroccan</MenuItem>
            <MenuItem value='MEXICAN'>Mexican</MenuItem>
            <MenuItem value='MALAGASY'>Malagasy</MenuItem>
            <MenuItem value='MALAY'>Malay</MenuItem>
            <MenuItem value='MELANESIAN'>Melanesian</MenuItem>
            <MenuItem value='MICRONESIAN'>Micronesian</MenuItem>
            <MenuItem value='MANDARIN'>Mandarin</MenuItem>
            <MenuItem value='MIN'>Min</MenuItem>
            <MenuItem value='MARATHI'>Marathi</MenuItem>
            <MenuItem value='MALDIVAN'>Maldivan</MenuItem>
            <MenuItem value='MALAYALAM'>Malayalam</MenuItem>
            <MenuItem value='MONGOL'>Mongol</MenuItem>

            <MenuItem value='NEPALI'>Nepali</MenuItem>
            <MenuItem value='NEW_ZEALANDER'>New Zealander</MenuItem>
            <MenuItem value='NORWEGIAN'>Norwegian</MenuItem>
            <MenuItem value='NILO_SAHARAN'>Nilo Saharan</MenuItem>

            <MenuItem value='OMANI'>Omani</MenuItem>
            <MenuItem value='OROMO'>Oromo</MenuItem>

            <MenuItem value='PARAGUAYAN'>Paraguayan</MenuItem>
            <MenuItem value='PALESTINIAN'>Palestinian</MenuItem>
            <MenuItem value='PERUVIAN'>Peruvian</MenuItem>
            <MenuItem value='PORTUGUESE'>Portuguese</MenuItem>
            <MenuItem value='PERSIAN'>Persian</MenuItem>
            <MenuItem value='PASHTO'>Pashto</MenuItem>
            <MenuItem value='PHILIPPINE'>Philippine</MenuItem>
            <MenuItem value='POLYNESIAN'>Polynesian</MenuItem>
            <MenuItem value='PUNJABI'>Punjabi</MenuItem>
            <MenuItem value='POLISH'>Polish</MenuItem>
            <MenuItem value='PAPUAN'>Papuan</MenuItem>
            <MenuItem value='PINGHUA'>Pinghua</MenuItem>

            <MenuItem value='QUEBECOIS'>Quebecois</MenuItem>
            <MenuItem value='QIANGIC'>Qiangic</MenuItem>

            <MenuItem value='UKRAINIAN'>Ukrainian</MenuItem>

            <MenuItem value='ROMANI'>Romani</MenuItem>
            <MenuItem value='ROMANIAN'>Romanian</MenuItem>
            <MenuItem value='RAJASTHANI'>Rajasthani</MenuItem>
            <MenuItem value='RUSSIAN'>Russian</MenuItem>

            <MenuItem value='SPANISH'>Spanish</MenuItem>
            <MenuItem value='SINDHI'>Sindhi</MenuItem>
            <MenuItem value='SINHALA'>Sinhala</MenuItem>
            <MenuItem value='SIRIUS'>Sirius</MenuItem>
            <MenuItem value='SUDANESE'>Sudanese</MenuItem>
            <MenuItem value='SCOTTISH'>Scottish</MenuItem>
            <MenuItem value='SOMALI'>Somali</MenuItem>
            <MenuItem value='SENUFO'>Senufo</MenuItem>
            <MenuItem value='SURINAMESE'>Surinamese</MenuItem>
            <MenuItem value='SWEDISH'>Swedish</MenuItem>
            <MenuItem value='SERBIANS'>Serbians</MenuItem>
            <MenuItem value='SLOVAK'>Slovak</MenuItem>
            <MenuItem value='SLOVENIAN'>Slovenian</MenuItem>
            <MenuItem value='SAMI'>Sami</MenuItem>
            <MenuItem value='SAUDI'>Saudi</MenuItem>

            <MenuItem value='TUNISIAN'>Tunisian</MenuItem>
            <MenuItem value='TAMIL'>Tamil</MenuItem>
            <MenuItem value='TELEGU'>Telegu</MenuItem>
            <MenuItem value='TIGRINYA'>Tigrinya</MenuItem>
            <MenuItem value='TIBETAN'>Tibetan</MenuItem>
            <MenuItem value='TATAR'>Tatar</MenuItem>
            <MenuItem value='TURKMEN'>Turkmen</MenuItem>
            <MenuItem value='TURKISH'>Turkish</MenuItem>
            <MenuItem value='THAI'>Thai</MenuItem>

            <MenuItem value='UJYGHUR'>Uyghur</MenuItem>
            <MenuItem value='URUGUAYAN'>Uruguayan</MenuItem>
            <MenuItem value='URDU'>Urdu</MenuItem>
            <MenuItem value='UZBEK'>Uzbek</MenuItem>

            <MenuItem value='VENEZUELAN'>Venezuelan</MenuItem>
            <MenuItem value='VIETNAMESE'>Vietnamese</MenuItem>
            <MenuItem value='VOLTANIGER'>Volta Niger</MenuItem>

            <MenuItem value='WELSH'>Welsh</MenuItem>
            <MenuItem value='WU'>Wu</MenuItem>

            <MenuItem value='XIANG'>Xiang</MenuItem>

            <MenuItem value='YANKEE'>Yankee</MenuItem>
            <MenuItem value='YUE'>Yue</MenuItem>
            <MenuItem value='YEMENI'>Yemeni</MenuItem>

            <MenuItem value='ZHUANG'>Zhuang</MenuItem>

          </TextField>

          <TextField
            className={styles.textField}
            id='belief'
            label="Belief"
            select
            name="belief"
            variant="outlined"
            value={formik.values.belief}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={handleBeliefFocus}
            error={formik.touched.belief && Boolean(formik.errors.belief)}
            helperText={formik.touched.belief && formik.errors.belief}
            InputProps={{
              style: {
                backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                border: isBeliefFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                color: 'white'
              },
            }}
            InputLabelProps={{
              style: {
                color: '#71767b', // Reemplaza 'white' con el color deseado
              },
            }}
          >

            <MenuItem value='ATHEISM'>Atheism</MenuItem>
            <MenuItem value='AGNOSTICISM'>Agnosticism</MenuItem>
            <MenuItem value='ORTHODOX_JUDAISM'>Orthodox Judaism</MenuItem>
            <MenuItem value='CONSERVATIVE_JUDAISM'>Conservative Judaism</MenuItem>
            <MenuItem value='REFORM_JUDAISM'>Reform Judaism</MenuItem>
            <MenuItem value='CATHOLICISM'>Catholicism</MenuItem>
            <MenuItem value='ORTHODOX_CHRISTIANITY'>Orthodox Christianity</MenuItem>
            <MenuItem value='COPTIC_ORTHODOX'>Coptic Orthodox</MenuItem>
            <MenuItem value='PROTESTANTISM'>Protestantism</MenuItem>
            <MenuItem value='SUNNISM'>Sunnism</MenuItem>
            <MenuItem value='SHIISM'>Shiism</MenuItem>
            <MenuItem value='KHARIJISM'>Kharijism</MenuItem>
            <MenuItem value='VAISHNAVISM'>Vaishnavism</MenuItem>
            <MenuItem value='SHAIVISM'>Shaivism</MenuItem>
            <MenuItem value='SHAKTISM'>Shaktism</MenuItem>
            <MenuItem value='SMARTISM'>Smartism</MenuItem>
            <MenuItem value='CONFUCIANISM'>Confucianism</MenuItem>
            <MenuItem value='TAOISM'>Taoism</MenuItem>
            <MenuItem value='SHENISM'>Shenism</MenuItem>
            <MenuItem value='MAHAYANA'>Mahayana</MenuItem>
            <MenuItem value='VAJRAYANA'>Vajrayana</MenuItem>
            <MenuItem value='THERAVADA'>Therevada</MenuItem>


          </TextField>

        </div>

        <div className={styles.importantNotice}>
          <p style={{ color: 'red' }}><small><b>IMPORTANT: </b>Sex, Race, Culture and Belief and the username cannot be changed later</small></p>
        </div>

        <button
          className={styles.submitButton}
          type="submit"
        >
          <strong>Sign up</strong>
        </button>


      </form>
    </div>
  );
};

export default SignupForm;
