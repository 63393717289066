import React from 'react';
import styles from './coldwallet.module.css';

const ColdWallet = () => {
    const cryptoData = [
        { name: 'Bitcoin', symbol: 'BTC', img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1.png', balance: '500.0204' },
        { name: 'Ethereum', symbol: 'ETH', img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png', balance: '500000000000.0204' },
        { name: 'XRP', symbol: 'XRP', img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/52.png', balance: '500000000000.0204' },
        { name: 'Solana', symbol: 'SOL', img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png', balance: '500000000000.0204' },
        { name: 'Dogecoin', symbol: 'DOGE', img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/74.png', balance: '500000000000.0204' },
        { name: 'Cardano', symbol: 'ADA', img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png', balance: '500000000000.0204' },
        { name: 'TRON', symbol: 'TRX', img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png', balance: '500000000000.0204' },
        { name: 'Shiba Inu', symbol: 'SHIB', img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5994.png', balance: '500000000000.0204' },
        { name: 'Litecoin', symbol: 'LTC', img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2.png', balance: '5000000.0204' },
        { name: 'Pepe', symbol: 'PEPE', img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/24478.png', balance: '500000000000.0204' },
    ];

    return (
        <div className={styles.pageContainer}>
            {/* Sidebar fijo */}
            <div className={styles.sidebar}>
                <p><i>Si no lo crees o no lo entiendes, no tengo tiempo para intentar convencerte, lo siento</i></p>
                <p>
                    <a href='https://es.wikipedia.org/wiki/Her%C3%A1clito' target='_blank' rel='noopener noreferrer'>
                        <strong>- Satoshi Nakamoto -</strong>
                    </a>
                </p>
            </div>

            {/* Contenedor de wallets */}
            <div className={styles.walletsContainer}>
                {cryptoData.map((coin, index) => (
                    <div key={index} className={styles.wallet}>
                        <div className={styles.walletHeader}>
                            <img src={coin.img} alt={coin.name} />
                            <p><strong>{coin.name}</strong> ({coin.symbol})</p>
                        </div>

                        <div className={styles.walletContent}>
                            <p>bc1qw508d6qejxtdg4y5r3zarvary0c5xw7kygt080</p>
                            <p className={styles.balance}>{coin.balance}</p>
                        </div>
                        <div className={styles.buttons}>
                            <button className={styles.sendButton}>Send</button>
                            <button className={styles.historyButton}>History</button>
                        </div>

                    </div>
                ))}
            </div>
        </div>
    );
};

export default ColdWallet;
