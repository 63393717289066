import React, { useState, useEffect } from 'react';
import { Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './settingspage.module.css';

const AccountInfo = () => (
  <div className={styles.contentSection}>
    <h2>Your account</h2>
    <p>Below is the email linked to your World Order account.</p>
  </div>
);

const ChangePassword = () => (
  <div className={styles.contentSection}>
    <h2>Cambia tu contraseña</h2>
    <p>Cambia tu contraseña en cualquier momento.</p>
  </div>
);

const DownloadData = () => (
  <div className={styles.contentSection}>
    <h2>Descargar un archivo con tus datos</h2>
    <p>Hazte una idea del tipo de información que se almacena de tu cuenta.</p>
  </div>
);

const DeleteAccount = () => (
  <div className={styles.contentSection}>
    <h2>Delete your account</h2>
    <p>Averigua cómo puedes desactivar tu cuenta.</p>
  </div>
);

export const SettingsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1002);
  const [showMenu, setShowMenu] = useState(window.innerWidth >= 1002);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 1002;
      setIsMobile(mobile);
      setShowMenu(mobile ? !location.pathname.includes('/settings/') : true);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [location.pathname]);

  const menuItems = [
    { path: 'account', label: 'Your account' },
    { path: 'password', label: 'Change your password' },
    { path: 'download', label: 'Download your data' },
    { path: 'delete', label: 'Delete your account' },
    { path: 'premium', label: 'Premium' },
  ];

  const handleMenuItemClick = (path) => {
    navigate(`/settings/${path}`);
    if (isMobile) setShowMenu(false);
  };

  const handleBackClick = () => {
    if (isMobile) {
      setShowMenu(true);
      navigate('/settings');
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.socialContainer}>
        {(!isMobile || showMenu) && (
          <nav className={styles.settingsMenu}>
            <h1 className={styles.menuTitle}>Configuración</h1>
            <ul className={styles.menuList}>
              {menuItems.map((item) => (
                <li key={item.path} className={styles.menuItem}>
                  <Link
                    to={`/settings/${item.path}`}
                    className={styles.menuLink}
                    onClick={() => handleMenuItemClick(item.path)}
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        )}

        {(!isMobile || !showMenu) && (
          <div className={styles.settingsContent}>
            {isMobile && (
              <button className={styles.backButton} onClick={handleBackClick}>
                ← Atrás
              </button>
            )}
            <Routes>
              <Route path="account" element={<AccountInfo />} />
              <Route path="password" element={<ChangePassword />} />
              <Route path="download" element={<DownloadData />} />
              <Route path="delete" element={<DeleteAccount />} />
            </Routes>
          </div>
        )}
      </div>
    </div>
  );
};