import React from 'react';
import styles from './userscofounderslist.module.css';

export const UserCoFoundersList = ({ searchResults, setCoFoundersInfo, setSearchResults, setSearchQuery }) => {

    return (
        <div className={styles.listContainer}>
            {searchResults.map((player) => (
                <div className={styles.listItem} key={player.id} onClick={() => {
                    setCoFoundersInfo(player)
                    setSearchResults([]);
                    setSearchQuery('');
                }}>
                    <img src={player.profileImageUrl} />
                    <div>
                        <p>{player.name}</p>
                        <p>{player.username}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};