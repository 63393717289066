import React, { useEffect, useState } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from 'react-router-dom';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import GreenPremiumBadge from '../../SocialPage/static/PremiumBadge.png';
import GoldenPremiumBadge from '../../SocialPage/static/GoldenPremiumBadge.png';

import PublicationCard from '../../SocialPage/PublicationCard/PublicationCard';
import ProfileModal from './ProfileModal';
import { useDispatch, useSelector } from 'react-redux';
import { followUnfollowPlayer, getPlayerProfile, getPlayerProfileByUsername, workDiscipline } from '../../../Store/PlayerProfile/Action';

import styles from './playerProfile.module.css';
import { Tab, Tabs } from '../../Tabs/Tabs';
import { cleanPlayerPosts } from '../../../Store/Post/Action';
import { getPlayerPosts } from './getPlayerPosts';

import LightbulbIcon from '@mui/icons-material/Lightbulb';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import BoltIcon from '@mui/icons-material/Bolt';

import WhatshotIcon from '@mui/icons-material/Whatshot';
import { SkillsModal } from './PlayerProfileModals/SkillsModal';

import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import Person4Icon from '@mui/icons-material/Person4';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import { capitalizeFirstLetter, replaceUnderscoresWithSpaces } from '../../../Utils/capitalizeFirtsLetter';
import { logout } from '../../../Store/Auth/Action';

import SensorDoorOutlinedIcon from '@mui/icons-material/SensorDoorOutlined';
import { Avatar } from '@mui/material';
import { formatDateToReadableString } from '../../../Utils/DurationToDateTime';

import LoadingRing from '../../../Static/LoadingRingV2.svg'
import { api, ensureAuthorizationHeader } from '../../../Config/api';

const PlayerProfile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authSlice = useSelector(store => store.auth);
    const { ownPlayerProfile, playerProfile, following } = useSelector(store => store.playerProfile);
    // const { profilePosts } = useSelector(store => store.post);
    const { username } = useParams();
    const [loadingProfile, setLoadingProfile] = useState(true);

    // Contendrá el perfil del jugador dependiendo si es el propio o el de otro jugador
    const actualPlayerProfile = username === authSlice.username ? ownPlayerProfile : playerProfile;

    const handleLogout = () => {
        console.log("logout")
        dispatch(logout())
    }

    // Para navegar a la City donde se encuentra el player
    const handleNavigateToProfileCity = () => {
        navigate(`/profile/city/${actualPlayerProfile?.whatCityIsItIn?.username}`);
    }

    // Para navegar al State donde se encuentra el player
    const handleNavigateToProfileState = () => {
        navigate(`/profile/state/${actualPlayerProfile?.whatCityIsItIn?.region?.stateOwner?.username}`);
    }


    useEffect(() => {

        setLoadingProfile(true);

        ensureAuthorizationHeader();

        const loadProfile = async () => {

            if (username === authSlice.username) {
                // Si ya se ha cargado el perfil de usuario propio, no lo volvemos a cargar
                if (!ownPlayerProfile) {
                    dispatch(getPlayerProfile(authSlice.JWT));
                }
            } else {
                dispatch(getPlayerProfileByUsername(username));
            }

            dispatch(cleanPlayerPosts());

            try {
                const playerPostsResponse = await getPlayerPosts(username, 0, 0);

                setProfilePosts(playerPostsResponse?.posts?.content || []);
                setIndexesAndPages({
                    pageForPosts: playerPostsResponse.pageForPosts,
                    postsIndexToStartTheSearch: playerPostsResponse.postsIndexToStartTheSearch,
                    pageForReposts: playerPostsResponse.pageForReposts,
                    repostsIndexToStartTheSearch: playerPostsResponse.repostsIndexToStartTheSearch,
                });
            } catch (error) {
                console.error('Failed to fetch player posts:', error);
            } finally {
                setLoadingProfile(false);
            }
        };

        console.log(ownPlayerProfile)

        loadProfile();
    }, [username, authSlice.username]);

    useEffect(() => {
        ensureAuthorizationHeader();
    }, []);

    const [profilePosts, setProfilePosts] = useState([]);

    const [indexesAndPages, setIndexesAndPages] = useState({
        pageForPosts: 0,
        postsIndexToStartTheSearch: 0,
        pageForReposts: 0,
        repostsIndexToStartTheSearch: 0
    });

    const [openProfileModal, setOpenProfileModal] = useState(false);
    const handleOpenProfileModel = () => setOpenProfileModal(true);
    const handleCloseProfileModal = () => setOpenProfileModal(false);

    const [openSkillsModal, setOpenSkillsModal] = useState(false);
    const handleOpenSkillsModal = () => setOpenSkillsModal(true);
    const handleCloseSkillsModal = () => setOpenSkillsModal(false);

    const handleBack = () => navigate(-1);
    const handleFollowUser = () => dispatch(followUnfollowPlayer(username));
    const handleWorkDiscipline = () => dispatch(workDiscipline());

    // Porcentaje de experiencia que tiene el player para el siguiente nivel. Hacemos la regla de tres para saber cuánto porcentaje lleva completado
    const experienceProgress = actualPlayerProfile?.levelExperienceStreak?.experience * 100 / actualPlayerProfile?.levelExperienceStreak?.requiredExperience;

    let month = null;
    let year = null;
    const joinedDate = actualPlayerProfile?.targetUser?.dateJoined ? new Date(actualPlayerProfile.targetUser.dateJoined) : null;

    if (joinedDate && !isNaN(joinedDate.getTime())) {
        month = new Intl.DateTimeFormat('en', { month: 'short' }).format(joinedDate);
        year = joinedDate.getFullYear();
    }

    const RenderPremiumBadge = ({ playerProfile }) => {
        const activeSubscriptionType = playerProfile?.targetUser?.premiumSubscription?.activeSubscriptionType;
        if (activeSubscriptionType === 'GREEN') {
            return <img className={styles.premiumBadge} alt='' src={GreenPremiumBadge} />;
        } else if (activeSubscriptionType === 'GOLD') {
            return <img className={styles.premiumBadge} alt='' src={GoldenPremiumBadge} />;
        }
        return null;
    };

    return (
        <div className={styles.mainContent}>
            {loadingProfile ?
                <div className={styles.loadingContainer}>
                    <img src={LoadingRing} />
                </div>
                :
                <div style={{ border: '1px solid #2c2f32' }}>
                    <section className={styles.fixedHeader}>
                        <KeyboardBackspaceIcon className={styles.arrowIcon} onClick={handleBack} />
                        <h1 className={styles.headerTitle}>{actualPlayerProfile?.targetUser?.name}</h1>
                        <div className={styles.rightContainer}>
                            <WhatshotIcon className={styles.hotIcon} />
                            <p>{actualPlayerProfile?.levelExperienceStreak?.streak}</p>
                        </div>
                    </section>

                    <img className={styles.backgroundImage} src={actualPlayerProfile?.targetUser?.backgroundImageUrl != null ? actualPlayerProfile?.targetUser?.backgroundImageUrl : 'https://cdn.pixabay.com/photo/2023/07/25/19/47/milky-way-8149815_640.jpg'} alt='' />

                    <div className={styles.profileHeader}>
                        <Avatar className={styles.profileImage} src={actualPlayerProfile?.targetUser?.profileImageUrl} alt='' />

                        <div style={{ display: 'flex', gap: '0.6rem' }}>

                            {username === authSlice.username &&
                                <button onClick={handleLogout} className={styles.logoutButton}><b><SensorDoorOutlinedIcon /></b></button>
                            }

                            {username === authSlice.username ? (
                                <button onClick={handleOpenProfileModel} className={`${styles.editFollowUnfollowButton} ${styles.borderButton}`}><b>Edit <span className={styles.profileWord}>profile</span></b></button>
                            ) : (
                                <button onClick={handleFollowUser}
                                    className={`${styles.editFollowUnfollowButton} ${following ? styles.unfollowButton : styles.followButton}`}>
                                    {following ? <strong>Unfollow</strong> : <strong>Follow</strong>}
                                </button>
                            )}

                        </div>


                    </div>

                    <section className={styles.profileInfo}>
                        <div className={styles.userDetails}>
                            <div className={styles.userInfo}>
                                <h1 className={styles.name}>{actualPlayerProfile?.targetUser?.name}</h1>
                                <RenderPremiumBadge playerProfile={actualPlayerProfile} />
                            </div>
                            <h1 className={styles.username}>{actualPlayerProfile?.targetUser?.username}</h1>
                        </div>

                        <div className={styles.userDetails}>
                            <p>{actualPlayerProfile?.targetUser?.about}</p>
                            <div className={styles.userStats}>
                                <div className={styles.userStat}>
                                    <LocationOnIcon className={styles.userStatsIcons} />
                                    <p><span className={styles.grayText} onClick={() => handleNavigateToProfileCity()}>{actualPlayerProfile?.whatCityIsItIn?.name}</span>, <span className={styles.grayText} onClick={() => handleNavigateToProfileState()}>{actualPlayerProfile?.whatCityIsItIn?.region?.stateOwner?.name}</span></p>
                                </div>
                                <div className={styles.userStat}>
                                    <CalendarMonthIcon className={styles.userStatsIcons} />
                                    <p>Joined {month} {year}</p>
                                </div>
                                {actualPlayerProfile?.playerSex == 'MALE'
                                    ?
                                    <div className={styles.userStat}>
                                        <MaleIcon />
                                        <p>Male</p>
                                    </div>
                                    :
                                    <div className={styles.userStat}>
                                        <FemaleIcon />
                                        <p>Female</p>
                                    </div>
                                }


                            </div>

                            <div className={styles.userStats}>
                                <div className={styles.userStat}>
                                    <span className={styles.followingFollowersNumber}>{actualPlayerProfile?.followingNumber}</span>
                                    <span className={styles.grayText}>Following</span>
                                </div>
                                <div className={styles.userStat}>
                                    <span className={styles.followingFollowersNumber}>{actualPlayerProfile?.followersNumber}</span>
                                    <span className={styles.grayText}>Followers</span>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className={styles.tabContainer}>
                        <Tabs>
                            <Tab label="Profile">
                                <div className={styles.tab}>

                                    <div className={styles.profileElement}>

                                        <p>Level:</p>

                                        <div className={styles.levelAndExperienceBar}>

                                            <p><strong>{actualPlayerProfile?.levelExperienceStreak?.level}</strong></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${experienceProgress}%` }}></div>
                                            </div>

                                        </div>

                                    </div>

                                    {username === authSlice.username && !(actualPlayerProfile?.levelExperienceStreak?.hasAlreadyWorkedDiscipline) ?
                                        <button className={styles.workDiscipline}
                                            onClick={handleWorkDiscipline}>
                                            Work discipline
                                        </button> : ''

                                    }



                                    <div className={styles.profileElement}>
                                        <p>Skills:</p>


                                        <div
                                            className={`${username === authSlice.username ? styles.skills : ''} ${styles.skillsAttributesContainer}`}
                                            onClick={username === authSlice.username ? () => handleOpenSkillsModal() : null}
                                        >
                                            <div className={styles.skillForProfile}>
                                                <LightbulbIcon style={{ color: 'white' }} />
                                                <div className={styles.skillDetails}>
                                                    <p>Intelligence</p>
                                                    <p><strong>{actualPlayerProfile?.skills?.intelligence}</strong></p>
                                                </div>

                                            </div>
                                            <div className={styles.skillForProfile}>
                                                <FitnessCenterIcon style={{ color: 'white' }} />
                                                <div className={styles.skillDetails}>
                                                    <p>Strength</p>
                                                    <p><strong>{actualPlayerProfile?.skills?.strength}</strong></p>
                                                </div>
                                            </div>
                                            <div className={styles.skillForProfile}>
                                                <BoltIcon style={{ color: 'white' }} />

                                                <div className={styles.skillDetails}>
                                                    <p>Endurance</p>
                                                    <p><strong>{actualPlayerProfile?.skills?.endurance}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className={styles.profileElement}>

                                        <p>Attributes:</p>


                                        <div
                                            className={styles.skillsAttributesContainer}
                                            onClick={username === authSlice.username ? () => handleOpenSkillsModal() : null}
                                        >
                                            <div className={styles.attributeForProfile}>
                                                <TheaterComedyIcon style={{ color: 'white' }} />
                                                <div className={styles.attributeDetails}>
                                                    <p>Culture</p>
                                                    <p><strong>{capitalizeFirstLetter(actualPlayerProfile?.culture)}</strong></p>
                                                </div>

                                            </div>
                                            <div className={styles.attributeForProfile}>
                                                <PsychologyAltIcon style={{ color: 'white' }} />
                                                <div className={styles.attributeDetails}>
                                                    <p>Belief</p>
                                                    <p><strong>{capitalizeFirstLetter(actualPlayerProfile?.playerBelief)}</strong></p>
                                                </div>
                                            </div>
                                            <div className={styles.attributeForProfile}>
                                                <Person4Icon style={{ color: 'white' }} />
                                                <div className={styles.attributeDetails}>
                                                    <p>Race</p>
                                                    <p><strong>{capitalizeFirstLetter(actualPlayerProfile?.race)}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>

                                        <p>Ideologies:</p>


                                        <div>

                                            <ul>
                                                <li><span>Main:</span> <strong>{replaceUnderscoresWithSpaces(actualPlayerProfile?.ideology?.mainIdeology)}</strong></li>
                                                <li><span>Secondary:</span> <strong>{replaceUnderscoresWithSpaces(actualPlayerProfile?.ideology?.secondaryIdeology)}</strong></li>
                                                <li><span>Economic:</span> <strong>{replaceUnderscoresWithSpaces(actualPlayerProfile?.ideology?.economicIdeology)}</strong></li>
                                                <li><span>Form of Government:</span> <strong>{replaceUnderscoresWithSpaces(actualPlayerProfile?.ideology?.formOfGovernmentIdeology)}</strong></li>
                                                <li><span>Power Structure:</span> <strong>{replaceUnderscoresWithSpaces(actualPlayerProfile?.ideology?.powerStructureIdeology)}</strong></li>
                                            </ul>

                                        </div>

                                    </div>
                                    {actualPlayerProfile?.positionList?.length > 0 &&
                                        <div className={styles.profileElement}>
                                            <p>Positions:</p>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                {actualPlayerProfile?.positionList.map((position) => (
                                                    <div className={styles.positionListElement} onClick={() => navigate(position?.organization?.url)}>

                                                        <p className={styles.positionTitle}>
                                                            {capitalizeFirstLetter(position?.positionType)}
                                                        </p>

                                                        <div className={styles.positionInfo}>
                                                            <img src={position?.organization?.logoUrl} style={{ borderRadius: '6px' }} />
                                                            <div className={styles.nationalityInfo}>
                                                                <p>{position?.organization?.name}</p>
                                                                <p style={{ color: '#767b80', fontWeight: 'normal' }}>Since {formatDateToReadableString(position?.positionStartDate)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                    }

                                    {actualPlayerProfile?.associationList
                                        ?.filter((association) => association.organizationType === 'POLITICAL_PARTY')
                                        .map((association) => (
                                            <div className={styles.profileElement}>
                                                <p>Political party:</p>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                    <div
                                                        key={association?.username} // Usa una clave única
                                                        className={styles.nationalityListElement}
                                                        onClick={() => navigate(association?.url)}
                                                    >
                                                        <img
                                                            src={association?.logoUrl}
                                                            alt="State shield"
                                                            style={{ borderRadius: '6px' }}
                                                        />
                                                        <div className={styles.nationalityInfo}>
                                                            <p>{association?.name}</p>
                                                            <p style={{ color: '#767b80', fontWeight: 'normal' }}>
                                                                Since {formatDateToReadableString(association?.dateAffiliation)}
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))}

                                    <div className={styles.profileElement}>
                                        <p>Nationality:</p>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                                            {actualPlayerProfile?.nationalities && actualPlayerProfile?.nationalities.map((nationality) => (
                                                <div className={styles.nationalityListElement} onClick={() => navigate(`/profile/state/${nationality?.issuingState?.username}`)}>
                                                    <img src={nationality?.issuingState?.shieldUrl} />
                                                    <div className={styles.nationalityInfo}>
                                                        <p>{nationality?.issuingState?.name}</p>
                                                        <p style={{ color: '#767b80', fontWeight: 'normal' }}>Since {formatDateToReadableString(nationality?.expeditionDate)}</p>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>


                                </div>
                            </Tab>
                            <Tab label="Posts">
                                {profilePosts.map((item) =>
                                    <PublicationCard item={item} key={item.id} />
                                )}
                            </Tab>
                            <Tab label="Replies">
                                <div>Replies</div>
                            </Tab>
                            <Tab label="Personal">
                                <div>Personal</div>
                            </Tab>
                        </Tabs>
                    </section>

                    <section>
                        <ProfileModal handleClose={handleCloseProfileModal} open={openProfileModal} ownPlayerProfile={ownPlayerProfile} />
                        <SkillsModal handleClose={handleCloseSkillsModal} open={openSkillsModal} />
                    </section>
                </div>}
        </div>
    );
};

export default PlayerProfile;
