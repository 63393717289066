import React from 'react'
import { TextField } from '@mui/material';

export const CustomTextArea = ({
    id,
    name,
    label,
    value,
    onChange,
    onBlur,
    onFocus,
    error,
    helperText,
    maxLength,
}) => {
    return (
        <TextField
            fullWidth
            multiline
            rows={4}
            id={id}
            name={name}
            label={label}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            error={error}
            helperText={helperText}
            sx={{
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: '#2c2f32',
                    },
                    '&:hover fieldset': {
                        borderColor: '#099f5d',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#099f5d',
                    },
                },
                '& .MuiInputLabel-root': {
                    color: '#71767b',
                    '&.Mui-focused': {
                        color: '#71767b',
                    },
                },
                '& .MuiSelect-icon': {
                    color: '#71767b',
                },
            }}
            InputProps={{
                style: {
                    backgroundColor: 'black',
                    color: 'white',
                },
            }}
            inputProps={{
                maxLength: maxLength
            }}
        />
    );
};