import PublicIcon from '@mui/icons-material/Public';
import SearchIcon from '@mui/icons-material/Search';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PersonIcon from '@mui/icons-material/Person';

import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';

import HomeFilled from './HomeIconFilled.svg'
import HomeOutlined from './HomeIconOutlined.svg'

export const mobileNavigationMenu=[
    {
        title:"Social",
        filledIcon:<img src={HomeFilled} style={{width:'23px'}}/>,
        outlinedIcon:<img src={HomeOutlined} style={{width:'23px'}}/>,
        path:"/social"
    },
    {
        title:"Explore",
        filledIcon:<SearchIcon style={{ fontSize: '1.8rem' }}/>,    
        outlinedIcon:<SearchIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/explore"
    },
    {
        title:"More",
        filledIcon:<DeviceHubOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        outlinedIcon:<DeviceHubOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/more"
    },
    {
        title:"Worldview",
        filledIcon:<PublicIcon style={{ fontSize: '1.8rem' }}/>,    
        outlinedIcon:<PublicIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/worldview"
    },
    {
        title:"Profile",
        filledIcon:<PersonIcon style={{ fontSize: '1.8rem' }}/>,
        outlinedIcon:<PersonOutlineOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/profile/user"
    }
]