function parseISO8601Duration(duration) {
    // Expresión regular para extraer las partes de la duración
    const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
    const match = duration.match(regex);
    
    if (!match) {
        throw new Error('Invalid duration format');
    }
    
    // Extrae horas, minutos y segundos
    const hours = parseInt(match[1] || '0', 10);
    const minutes = parseInt(match[2] || '0', 10);
    const seconds = parseInt(match[3] || '0', 10);
    
    // Convierte a minutos totales
    return (hours * 60) + minutes + (seconds / 60);
}

export function formatCurrentDateWithDuration(duration) {
    // Obtiene la fecha y hora actual
    const currentDate = new Date();
    
    // Convierte el string Duration a minutos
    const totalMinutes = parseISO8601Duration(duration);

    // Crea una nueva fecha a partir de la fecha y hora actual
    const newDate = new Date(currentDate);
    
    // Añade los minutos de duración a la fecha
    newDate.setMinutes(newDate.getMinutes() + totalMinutes);
    
    // Opciones de formato para la fecha
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    };
    
    // Formatea la fecha
    return newDate.toLocaleDateString('en-US', options).replace(',', ' at');
}

export function formatDateTimeToReadableString(isoDateString) {
    // Crea un objeto Date a partir de la cadena ISO8601
    const date = new Date(isoDateString);

    // Opciones de formato para la fecha y la hora
    const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false, // Formato 24 horas
    };

    // Formatea la fecha como "Month Day, Year, HH:MM"
    return date.toLocaleDateString('en-US', options).replace(',', '');
}

export function formatDateToReadableString(isoDateString) {
    // Crea un objeto Date a partir de la cadena ISO8601
    const date = new Date(isoDateString);

    // Opciones de formato para la fecha y la hora
    const options = {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
    };

    // Formatea la fecha como "Month Day, Year, HH:MM"
    return date.toLocaleDateString('en-US', options).replace(',', '');
}

export const formatDateWithQuarter = (date = new Date()) => {
    // Formato de fecha
    const options = { 
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC'
    };
  
    const formattedDate = date.toLocaleString('en-US', options);
  
    // Calcular el trimestre usando el mes UTC
    const month = date.getUTCMonth(); // 0-11
    const quarter = Math.floor(month / 3) + 1;
  
    return `${formattedDate} | Q${quarter}`;
  };

  export const formatDateTimeForMessagesAndEmail = (isoDate) => {
    const date = new Date(isoDate);
    const now = new Date(); // Fecha y hora actuales

    // Verificar si la fecha es de hoy
    const isToday =
        date.getDate() === now.getDate() &&
        date.getMonth() === now.getMonth() &&
        date.getFullYear() === now.getFullYear();

    if (isToday) {
        // Si es hoy, mostrar solo la hora (hh:mm)
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    } else {
        // Si no es hoy, mostrar solo la fecha (dd/mm/yyyy)
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Meses empiezan desde 0
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }
};


