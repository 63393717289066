import { TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react'

export const LinkedOutDropdownSearch = ({
    id,
    name,
    label,
    value,
    onChange,
    onBlur,
    onFocus,
    error,
    helperText,
    children,
    width = '100%',
    height = '70px' // Valor por defecto
}) => {
    return (
        <TextField
            fullWidth={width === '100%'}
            id={id}
            name={name}
            label={label}
            select
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            error={error}
            helperText={helperText}
            sx={{
                width,
                '& .MuiOutlinedInput-root': {
                    height: height, // Aplicamos la altura al contenedor del input
                    '& fieldset': {
                        height: '45px', // Aseguramos que el borde ocupe toda la altura
                    },
                    '&:hover fieldset': {
                        borderColor: '#099f5d',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#099f5d',
                    },
                    // Ajustamos el padding vertical para centrar el contenido
                    '& .MuiSelect-select': {
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        height: '100% !important', // Forzamos la altura del área seleccionable
                    }
                },
                '& .MuiInputLabel-root': {
                    color: '#71767b',
                    '&.Mui-focused': {
                        color: '#71767b',
                    },
                    // Ajustamos la posición de la etiqueta
                    transform: 'translate(14px, 8px) scale(1)',
                    '&.MuiInputLabel-shrink': {
                        transform: 'translate(14px, -9px) scale(0.75)',
                    }
                },
                '& .MuiSelect-icon': {
                    color: '#71767b',
                },
            }}
            InputProps={{
                style: {
                    backgroundColor: '#202327',
                    color: 'white',
                    height: '100%', // Aseguramos que el input ocupe toda la altura disponible
                },
            }}
            SelectProps={{
                IconComponent: ExpandMoreIcon,
                MenuProps: {
                    PaperProps: {
                        style: {
                            backgroundColor: 'black',
                            color: 'white',
                        }
                    }
                }
            }}
        >
            {children}
        </TextField>
    );
};