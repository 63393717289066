import { useNavigate } from 'react-router-dom';
import styles from './chatpreview.module.css'
import { formatDateTimeForMessagesAndEmail } from '../../DurationToDateTime';
import WorldOrderLogo from './WorldOrderPWALogo.svg'

import GreenPremiumBadge from '../../../Components/SocialPage/static/PremiumBadge.png'
import GoldenPremiumBadge from '../../../Components/SocialPage/static/GoldenPremiumBadge.png'

// Para Email y Messages, se trata de una previsualización individual de conversación para ser mostrado en una lista de chats o emails
export const ChatPreview = ({ chat, messages }) => {

    const navigate = useNavigate();

    const read = (messages ? chat?.lastMessage.read : chat?.read);
    const content = (messages ? chat?.lastMessage?.content : chat?.subject);

    return (
        <div className={`${styles.chatItem} ${!read ? styles.unread : ''}`} onClick={() => {

            messages ? navigate(`/messages/${chat?.id}`) : navigate(`/email/${chat?.uuid}`);
            
        }}>
            <img src={chat?.imageUrl || WorldOrderLogo} alt="" className={`${styles.profilePhoto} ${messages ? styles.messageProfilePhoto : styles.emailProfilePhoto}`} />
            <div className={styles.chatInfo}>
                <div className={styles.chatHeader}>
                    <div className={styles.userInfo}>
                        <span className={styles.name}>{chat?.sender?.name || 'World Order'}</span>
                        {chat?.sender?.activeSubscriptionType == 'GREEN' ? <img src={GreenPremiumBadge} className={styles.badge} /> : (
                            chat?.sender?.activeSubscriptionType == 'GOLDEN' ? <img src={GoldenPremiumBadge} className={styles.badge} /> : ''
                        )
                        }
                        <span className={styles.username}>{chat?.sender?.username}</span>
                        <span className={styles.dot}>·</span>
                        <span className={styles.time}>{formatDateTimeForMessagesAndEmail(chat?.dateTime)}</span>
                    </div>
                </div>
                <p className={styles.lastMessage}>
                    {content}
                </p>
            </div>
        </div>
    );
};