/**
 * Validates if a URL is either an image (excluding Discord) or a YouTube video
 * @param {string} url - The URL to validate
 * @returns {Promise<boolean>} - Returns true if URL is valid and not from Discord
 */
export const validateMediaUrl = async (url) => {
    try {
      // Check if it's a Discord URL
      if (url.includes('cdn.discordapp.com')) {
        return false;
      }
  
      // YouTube URL patterns
      const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/shorts\/)([a-zA-Z0-9_-]+)(\S*)?$/;
  
      // Check if it's a YouTube URL
      if (youtubePattern.test(url)) {
        return true;
      }
  
      // If not YouTube, check if it's an image
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok && response.headers.get('content-type')?.startsWith('image/');
  
    } catch (error) {
      console.error('Error validating media URL:', error);
      return false;
    }
  };