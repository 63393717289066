import { TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react'

export const CustomDropdown = ({
    id,
    name,
    label,
    value,
    onChange,
    onBlur,
    onFocus,
    error,
    helperText,
    children // Aquí se pasarán los MenuItem
}) => {

    return (
        <TextField
            fullWidth
            id={id}
            name={name}
            label={label}
            select
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            error={error}
            helperText={helperText}
            sx={{
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: '#2c2f32',
                    },
                    '&:hover fieldset': {
                        borderColor: '#099f5d',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#099f5d',
                    },
                },
                '& .MuiInputLabel-root': {
                    color: '#71767b',
                    '&.Mui-focused': {
                        color: '#71767b',
                    },
                },
                '& .MuiSelect-icon': {
                    color: '#71767b',
                },
            }}
            InputProps={{
                style: {
                    backgroundColor: 'black',
                    color: 'white',
                },
            }}
            SelectProps={{
                IconComponent: ExpandMoreIcon,
            }}
        >
            {children}
        </TextField>
    );
};