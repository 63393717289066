import React, { useEffect, useState } from 'react';
import styles from './knowledgepage.module.css';
import LoadingRing from '../../Static/LoadingRingV2.svg';
import { api } from '../../Config/api';

import {formatDateTimeToReadableString} from '../../Utils/DurationToDateTime'

export const KnowledgePage = () => {
    const [loading, setLoading] = useState(true);
    const [knowledgeList, setKnowledgeList] = useState([]);
    const [learningImprovingKnowledge, setLearningImprovingKnowledge] = useState(null);

    const apiKnowledgeRoute = `/api/v1/knowledge`;

    useEffect(() => {
        const fetchKnowledge = async () => {
            try {
                const response = await api.get(apiKnowledgeRoute);
                setKnowledgeList(response.data.knowledgeList || []); // Asegúrate de que sea un array
                setLearningImprovingKnowledge(response.data.learningImprovingKnowledge || null);
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching state profile:', error);
            } finally {
                setLoading(false); // Mueve esto al finally para asegurar que se ejecute
            }
        };

        fetchKnowledge();
    }, []);

    const learnImproveKnowledge = async (knowledgeType) => {
        try {
            console.log("dentro")
            const response = await api.put(`${apiKnowledgeRoute}/${knowledgeType}`);
            setLearningImprovingKnowledge(response.data);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className={styles.pageContainer}>
            <div className={styles.mainContent}>

            
            <div className={styles.sidebar}>
                <p><i>Nunca he perdido el sentimiento de contradicción que hay detrás de todo conocimiento.</i></p>
                <p><a href='https://es.wikipedia.org/wiki/Hermann_Hesse' target='_blank' rel='noopener noreferrer'><strong>- Hermann Hesse -</strong></a></p>
            </div>

            {loading ? (
                <div className={styles.loading}>
                    <img src={LoadingRing} style={{ marginBottom: '3rem' }} alt="Loading" />
                </div>
            ) : (
                <div className={styles.knowledgeContainer}>
                    {knowledgeList.length > 0 ? (
                        knowledgeList.map((knowledge) => {
                            const isLearningImproving = learningImprovingKnowledge?.knowledgeType === knowledge?.knowledgeType;
                            return isLearningImproving ? (
                                <LearningImprovingCard key={knowledge.id} item={knowledge} learningImproving={learningImprovingKnowledge} />
                            ) : (knowledge.learned || knowledge.level == 10 ?
                                <LearnedMaxImprovedCard key={knowledge.id} item={knowledge} lear />
                                :
                                <StartLearningImprovingCard key={knowledge.id} item={knowledge} learnImprove={learnImproveKnowledge} learningImproving={learningImprovingKnowledge} />
                            );
                        })
                    ) : (
                        <p>No knowledge data available</p>
                    )}
                </div>
            )}
            </div>
        </div>
    );
};

const StartLearningImprovingCard = ({ item, learnImprove, learningImproving }) => (
    <div className={styles.knowledge}>
        <div className={styles.titleAndRequiredLevel}>
            <p className={styles.skillTitle}>{item?.knowledgeTitle}</p>
            <p style={{ color: '#767b80' }}><i>Req. lvl. {item?.requiredLevel}</i></p>
        </div>
        <div className={styles.contentWrapper}>
            <p>{item?.knowledgeDescription}</p>
        </div>
        <div className={styles.buttons}>
            {item?.isLeveleable ? (
                <>
                    <button
                        className={styles.learningButton}
                        onClick={learningImproving == null || undefined ? () => learnImprove(item?.knowledgeType) : () => { }}
                    >
                        Start improving
                    </button>
                    <div className={styles.actualLevel}>{item?.level}</div>
                </>
            ) : (
                <button
                    className={styles.learningButton}
                    onClick={learningImproving == null || undefined ? () => learnImprove(item?.knowledgeType) : () => { }}
                >
                    Start learning
                </button>
            )}
        </div>
    </div>
);


const LearnedMaxImprovedCard = ({ item }) => (

    <div className={styles.knowledge}>
        <div className={styles.titleAndRequiredLevel}>
            <p className={styles.skillTitle}>{item?.knowledgeTitle}</p>
            <p style={{ color: '#767b80' }}><i>Req. lvl. {item?.requiredLevel}</i></p>
        </div>
        <div className={styles.contentWrapper}>
            <p>{item?.knowledgeDescription}</p>
        </div>

        <div className={styles.learningDiv}>
            <div className={styles.learning}>{item.learned ? 'LEARNED' : 'MAX LEVEL'}</div>
        </div>
    </div>
)




const LearningImprovingCard = ({ item, learningImproving }) => (
    <div className={styles.knowledge}>
        <div className={styles.titleAndRequiredLevel}>
            <p className={styles.skillTitle}>{item?.knowledgeTitle}</p>
            <p style={{ color: '#767b80' }}><i>Req. lvl. {item?.requiredLevel}</i></p>
        </div>
        <div className={styles.contentWrapper}>
            <p>{item?.knowledgeDescription}</p>
            <p style={{ color: '#767b80' }}>End date: <strong>{formatDateTimeToReadableString(learningImproving?.reachingKnowledgeDateTime)}</strong> {item.isLeveleable ? <> / For level: <strong>{item?.level + 1}</strong></> : ''}</p>
        </div>

        <div className={styles.learningDiv}>
            <div className={styles.learning}>{item.isLeveleable ? 'IMPROVING' : 'LEARNING'}</div>
            <div className={styles.learningRing}><img src={LoadingRing} alt="Loading" /></div>
        </div>
    </div>
);
