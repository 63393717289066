import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { api, ensureAuthorizationHeader } from '../../../Config/api';

import styles from './regionprofile.module.css'

import LoadingRing from '../../../Static/LoadingRingV2.svg'
import { Tab, Tabs } from '../../Tabs/Tabs';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined';
import GrassOutlinedIcon from '@mui/icons-material/GrassOutlined';

import ForestOutlinedIcon from '@mui/icons-material/ForestOutlined';

import { PieChartComponent } from '../PieChartComponent/PieChartComponent';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { flattenObject } from '../../../Utils/flattenObject';
import { formatValueForPieChart } from '../../../Utils/formatValueForPieChart';

export const RegionProfile = () => {

    const navigate = useNavigate();

    const [loadingProfile, setLoadingProfile] = useState(true);

    const { username } = useParams();

    const [regionProfile, setRegionProfile] = useState();

    const handleBack = () => navigate(-1);

    const navigateToCity = (cityUsername) => navigate(`/profile/city/${cityUsername}`);
    const navigateToState = (stateUsername) => navigate(`/profile/state/${stateUsername}`);

    const [popsDistributionsQuarter, setPopsDistributionsQuarter] = useState(1);

    const handleChangePopsDistributionsQuarter = (event) => {

        let quarterValue;

        if (event !== 1) {
            quarterValue = event.target.value;


        } else {
            quarterValue = event;
        }

        setPopsDistributionsQuarter(quarterValue);

        const currentQuarterData = regionProfile?.quarterlyStatistics.find(stat => stat.quarter === event.target.value);


        if (currentQuarterData) {

            // Aquí procesar los datos según tus necesidades
            setPercentageDistribution({
                popType: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allPopTypesDistribution),
                sex: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allSexDistribution),
                superCulture: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allSuperCulturesDistribution),
                culture: processPopsDistributionsData(flattenObject(currentQuarterData?.hitStatistics?.allCulturesDistribution)),
                superBelief: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allSuperBeliefsDistribution),
                belief: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allBeliefsDistribution),
                ageRange: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allAgeDistribution),
                race: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allRacesDistribution),
                qualification: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allQualificationLevelDistribution),
                // Agrega los demás procesamientos según sea necesario
            });


            // Procesar y establecer los datos absolutos
            setAbsoluteDistribution({
                popType: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allPopTypesDistribution),
                sex: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allSexDistribution),
                superCulture: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allSuperCulturesDistribution),
                culture: processAbsolutePopsDistributionsData(flattenObject(currentQuarterData?.hitStatistics?.allCulturesDistribution)),
                superBelief: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allSuperBeliefsDistribution),
                belief: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allBeliefsDistribution),
                ageRange: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allAgeDistribution),
                race: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allRacesDistribution),
                qualification: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allQualificationLevelDistribution),
            });

        }
    };

    const processPopsDistributionsData = (data) => {
        // Filtrar los 9 valores más grandes y agrupar el resto en "Otros"
        const entries = Object.entries(data)
            .filter(([key, value]) => key.endsWith("Percentage") && value > 0) // Filtrar valores mayores a 0 y que sean porcentajes
            .sort((a, b) => b[1] - a[1]) // Ordenar de mayor a menor

        const topEntries = entries.slice(0, 9);
        const othersValue = entries.slice(9).reduce((acc, [_, value]) => acc + value, 0);

        const result = topEntries.map(([label, value], index) => ({
            id: index,
            label: formatValueForPieChart(label),
            value,
        }));

        if (othersValue > 0) {
            result.push({
                id: 9,
                label: "Others",
                value: othersValue,
            });
        }

        return result;
    };

    const processAbsolutePopsDistributionsData = (data) => {
        const entries = Object.entries(data)
            .filter(([key]) => !key.endsWith("Percentage")) // Filtrar las claves que no terminan en Percentage
            .filter(([_, value]) => value > 0) // Filtrar valores mayores a 0
            .sort((a, b) => b[1] - a[1]); // Ordenar de mayor a menor

        const topEntries = entries.slice(0, 9);
        const othersValue = entries.slice(9).reduce((acc, [_, value]) => acc + value, 0);

        const result = topEntries.map(([label, value], index) => ({
            id: index,
            label: formatValueForPieChart(label),
            value,
        }));

        if (othersValue > 0) {
            result.push({
                id: 9,
                label: "Others",
                value: othersValue,
            });
        }

        return result;
    };

    const [percentageDistribution, setPercentageDistribution] = useState({
        popType: [],
        sex: [],
        culture: [],
        superCulture: [],
        belief: [],
        superBelief: [],
        race: [],
        qualification: [],
        ageRange: [],
    });

    const [absoluteDistribution, setAbsoluteDistribution] = useState({
        popType: [],
        sex: [],
        culture: [],
        superCulture: [],
        belief: [],
        superBelief: [],
        race: [],
        qualification: [],
        ageRange: [],
    });

    useEffect(() => {
        const fetchStateProfile = async () => {
            setLoadingProfile(true);
            try {
                // Asegúrate de que el JWT esté configurado en la petición a la api
                ensureAuthorizationHeader();

                const response = await api.get(`/api/v1/region/profile/${username}`);
                setRegionProfile(response.data); // Asume que la respuesta tiene una propiedad 'data' que contiene el perfil del estado

            } catch (error) {
                console.error('Error fetching state profile:', error);
            } finally {
                setTimeout(() => {
                    setLoadingProfile(false);
                }, 300)
            }
        };

        fetchStateProfile();
    }, [username]);


    return (
        <div className={styles.mainContent}>
            {loadingProfile


                ?
                <div className={styles.loadingContainer}>
                    <img src={LoadingRing} />
                </div>
                :
                <div style={{ border: '1px solid #2c2f32', minHeight: '100vh' }}>
                    <section className={styles.fixedHeader}>
                        <KeyboardBackspaceIcon className={styles.arrowIcon} onClick={handleBack} />
                        <h1 className={styles.headerTitle}>{regionProfile?.regionName}</h1>
                        <div className={styles.rightContainer}>

                        </div>
                    </section>

                    <img className={styles.backgroundImage} src="https://cdn.pixabay.com/photo/2023/07/25/19/47/milky-way-8149815_640.jpg" alt='' />

                    <div className={styles.profileHeader}>
                        <img className={styles.profileImage} src={regionProfile?.regionShieldUrl} alt='' />
                    </div>

                    <section className={styles.profileInfo}>
                        <div className={styles.userDetails}>
                            <div className={styles.userInfo}>
                                <h1 className={styles.name}>{regionProfile?.regionName}</h1>
                                {/* <img className={styles.stateBadge} alt='' src={StateBadge} />*/}
                            </div>
                        </div>

                        <div className={styles.userDetails}>
                            <p>{regionProfile?.regionDescription}</p>
                            <div className={styles.userStats}>

                                <div className={styles.userStat}>
                                    <LandscapeOutlinedIcon className={styles.userStatsIcons} />
                                    <p>{regionProfile?.squareKilometers} km2</p>
                                </div>

                                <div className={styles.userStat}>
                                    <GrassOutlinedIcon className={styles.userStatsIcons} />
                                    <p>{regionProfile?.arableHectares} ha</p>
                                </div>

                                <div className={styles.userStat}>
                                    <ForestOutlinedIcon className={styles.userStatsIcons} />
                                    <p>{regionProfile?.biodiversityIndex}/{regionProfile?.biodiversityPotential} biodiversity</p>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section className={styles.tabContainer}>
                        <Tabs>
                            <Tab label="Profile">
                                <div className={styles.tab}>

                                    <div className={styles.profileElement}>
                                        <p>City:</p>

                                        <div>

                                            <div className={styles.cityPreview} onClick={() => navigateToCity(regionProfile?.cityUsername)}>
                                                <div className={styles.imageContainer}>
                                                    <img src={regionProfile?.cityShieldUrl} />
                                                </div>
                                                <p>{regionProfile?.cityName}</p>
                                            </div>

                                        </div>

                                    </div>

                                    {regionProfile?.autonomyName &&

                                        <div className={styles.profileElement}>

                                            <p>Autonomy:</p>

                                            <div>

                                                <div className={styles.cityPreview}>
                                                    <div className={styles.imageContainer}>
                                                        <img src={regionProfile?.autonomyShieldUrl} />
                                                    </div>
                                                    <p>{regionProfile?.autonomyName}</p>
                                                </div>

                                            </div>
                                        </div>
                                    }

                                    <div className={styles.profileElement}>
                                        <p>State:</p>

                                        <div>

                                            <div className={styles.cityPreview} onClick={() => navigateToState(regionProfile?.stateUsername)}>
                                                <div className={styles.imageContainer}>
                                                    <img src={regionProfile?.stateShieldUrl} />
                                                </div>
                                                <p>{regionProfile?.stateName}</p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className={`${styles.profileElement} ${styles.fullWidthElement}`}>

                                        <button className={styles.territorialOrganizationButton}>Show people and organizations</button>


                                    </div>

                                </div>
                            </Tab>
                            <Tab label="Structures">
                                <div>Coming... not soon</div>
                            </Tab>
                            <Tab label="Indexes">

                                <div className={styles.tab}>

                                    <div className={styles.elementTitle}>
                                        <p>ENERGY</p>
                                        <hr />
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Solar index:</p>

                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.energyIndices?.solarIndex}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.energyIndices?.solarIndex}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Wind index:</p>

                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.energyIndices?.windIndex}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.energyIndices?.windIndex}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.elementTitle} style={{ marginTop: '25px' }}>
                                        <p>AGRICULTURE</p>
                                        <hr />
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Tomato index:</p>
                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.agriculturalIndices?.tomatoIndex || 0}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.agriculturalIndices?.tomatoIndex || 0}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Potato index:</p>
                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.agriculturalIndices?.potatoIndex || 0}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.agriculturalIndices?.potatoIndex || 0}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Banana index:</p>
                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.agriculturalIndices?.bananaIndex || 0}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.agriculturalIndices?.bananaIndex || 0}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Apple index:</p>
                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.agriculturalIndices?.appleIndex || 0}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.agriculturalIndices?.appleIndex || 0}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Sugar cane index:</p>
                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.agriculturalIndices?.sugarCaneIndex || 0}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.agriculturalIndices?.sugarCaneIndex || 0}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Barley index:</p>
                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.agriculturalIndices?.barleyIndex || 0}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.agriculturalIndices?.barleyIndex || 0}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Poppy index:</p>
                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.agriculturalIndices?.poppyIndex || 0}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.agriculturalIndices?.poppyIndex || 0}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Ephedra Distachya index:</p>
                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.agriculturalIndices?.ephedraDistachyaIndex || 0}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.agriculturalIndices?.ephedraDistachyaIndex || 0}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Erythroxylum Coca index:</p>
                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.agriculturalIndices?.erythroxylumCocaIndex || 0}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.agriculturalIndices?.erythroxylumCocaIndex || 0}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Cannabis Sativa index:</p>
                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.agriculturalIndices?.cannabisSativaIndex || 0}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.agriculturalIndices?.cannabisSativaIndex || 0}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Nicotiana Tabacum index:</p>
                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.agriculturalIndices?.nicotianaTabacumIndex || 0}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.agriculturalIndices?.nicotianaTabacumIndex || 0}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.elementTitle}>
                                        <p>MINING, OIL AND GAS</p>
                                        <hr />
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Petroleum index:</p>

                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.resourceIndices?.petroleumIndex}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.resourceIndices?.petroleumIndex}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Natural gas index:</p>

                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.resourceIndices?.naturalGasIndex}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.resourceIndices?.naturalGasIndex}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Uranium index:</p>

                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.resourceIndices?.uraniumIndex}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.resourceIndices?.uraniumIndex}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Platinum index:</p>

                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.resourceIndices?.platinumIndex}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.resourceIndices?.platinumIndex}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Gold index:</p>

                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.resourceIndices?.goldIndex}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.resourceIndices?.goldIndex}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Iron index:</p>

                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.resourceIndices?.ironIndex}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.resourceIndices?.ironIndex}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Carbon index:</p>

                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.resourceIndices?.carbonIndex}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.resourceIndices?.carbonIndex}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Aluminium index:</p>

                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.resourceIndices?.aluminiumIndex}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.resourceIndices?.aluminiumIndex}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Rare earth index:</p>

                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.resourceIndices?.rareEarthIndex}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.resourceIndices?.rareEarthIndex}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Lithium index:</p>

                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.resourceIndices?.lithiumIndex}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.resourceIndices?.lithiumIndex}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Nickel index:</p>

                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.resourceIndices?.nickelIndex}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.resourceIndices?.nickelIndex}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Cobalt index:</p>

                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.resourceIndices?.cobaltIndex}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.resourceIndices?.cobaltIndex}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Copper index:</p>

                                        <div className={styles.levelAndExperienceBar}>
                                            <p><span style={{ color: 'white' }}><strong>{regionProfile?.resourceIndices?.copperIndex}</strong></span></p>
                                            <div className={styles.experienceBarContainer}>
                                                <div className={styles.experienceBar} style={{ width: `${regionProfile?.resourceIndices?.copperIndex}%` }}></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </Tab>
                            <Tab label="Statistics">
                                <div className={styles.statisticsQuarterAndYear}>
                                    <div className={styles.elementTitle}>
                                        <div className={styles.popsDistributions}>
                                            <p>POPS DISTRIBUTIONS</p>
                                        </div>
                                        <hr />
                                    </div>

                                </div>

                                <div className={styles.quarterDropdown}>
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <InputLabel
                                            id="demo-select-small-label"
                                            sx={{
                                                color: 'white', // Color del label por defecto
                                                '&.Mui-focused': {
                                                    color: '#099f5d', // Cambia el color del label cuando el Select está enfocado
                                                },
                                            }} // Cambia el color del InputLabel a blanco
                                        >
                                            Quarter
                                        </InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={popsDistributionsQuarter}
                                            label="Quarter"
                                            onChange={handleChangePopsDistributionsQuarter}
                                            sx={{
                                                backgroundColor: 'black',
                                                color: 'white', // Cambia el color del texto seleccionado a blanco
                                                '& .MuiSelect-select': {
                                                    backgroundColor: 'black', // Color de fondo del select
                                                    color: 'white', // Color del valor seleccionado
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#767b80', // Borde blanco
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#767b80', // Borde blanco al pasar el mouse
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#767b80', // Borde blanco cuando está enfocado
                                                },
                                                '& .MuiSelect-icon': {
                                                    color: '#767b80', // Cambia el color del icono del "triangulito"
                                                },
                                            }}
                                        >
                                            <MenuItem value={1}>Q1 <span style={{ color: '#767b80' }}><small>2024</small></span></MenuItem>
                                            <MenuItem value={2}>Q2 <span style={{ color: '#767b80' }}><small>2024</small></span></MenuItem>
                                            <MenuItem value={3}>Q3 <span style={{ color: '#767b80' }}><small>2024</small></span></MenuItem>
                                            <MenuItem value={4}>Q4 <span style={{ color: '#767b80' }}><small>2024</small></span></MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className={styles.statisticsContainer}>
                                    <div className={styles.pieChartWrapper}>
                                        <PieChartComponent
                                            name='Pop type'
                                            distributionData={percentageDistribution?.popType}
                                            absoluteNumbersDistributionData={absoluteDistribution?.popType} />
                                    </div>
                                    <div className={styles.pieChartWrapper}>
                                        <PieChartComponent
                                            name='Sex'
                                            distributionData={percentageDistribution?.sex}
                                            absoluteNumbersDistributionData={absoluteDistribution?.sex} />
                                    </div>
                                    <div className={styles.pieChartWrapper}>
                                        <PieChartComponent
                                            name='Cultural group'
                                            distributionData={percentageDistribution?.superCulture}
                                            absoluteNumbersDistributionData={absoluteDistribution?.superCulture} />
                                    </div>
                                    <div className={styles.pieChartWrapper}>
                                        <PieChartComponent
                                            name='Culture'
                                            distributionData={percentageDistribution?.culture}
                                            absoluteNumbersDistributionData={absoluteDistribution?.culture} />
                                    </div>
                                    <div className={styles.pieChartWrapper}>
                                        <PieChartComponent
                                            name='Belief group'
                                            distributionData={percentageDistribution?.superBelief}
                                            absoluteNumbersDistributionData={absoluteDistribution?.superBelief} />
                                    </div>
                                    <div className={styles.pieChartWrapper}>
                                        <PieChartComponent
                                            name='Belief'
                                            distributionData={percentageDistribution?.belief}
                                            absoluteNumbersDistributionData={absoluteDistribution?.belief} />
                                    </div>
                                    <div className={styles.pieChartWrapper}>
                                        <PieChartComponent
                                            name='Race'
                                            distributionData={percentageDistribution?.race}
                                            absoluteNumbersDistributionData={absoluteDistribution?.race} />
                                    </div>
                                    <div className={styles.pieChartWrapper}>
                                        <PieChartComponent
                                            name='Qualification'
                                            distributionData={percentageDistribution?.qualification}
                                            absoluteNumbersDistributionData={absoluteDistribution?.qualification} />
                                    </div>
                                    <div className={styles.pieChartWrapper}>
                                        <PieChartComponent
                                            name='Age range'
                                            distributionData={percentageDistribution?.ageRange}
                                            absoluteNumbersDistributionData={absoluteDistribution?.ageRange} />
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </section>

                    <section>
                        {/* Para los modal */}
                    </section>
                </div>
            }


        </div >
    );
};
