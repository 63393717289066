import React, { useEffect, useState } from 'react'
import styles from './messagespage.module.css'
import { useParams } from 'react-router-dom';
import { ChatPreview } from '../../Utils/Components/ChatPreview/ChatPreview';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import NewMessageIcon from '../Assets/NewMessageIcon.svg'
import ConfigurationIcon from '../Assets/ConfigurationIcon.svg'
import MagnifyingGlassIcon from '../Assets/MagnifyingGlassIcon.svg'
import InfoIcon from '../Assets/InfoIcon.svg'

export const MessagesPage = () => {

    const { chatId } = useParams();
    const [chatContent, setChatContent] = useState(null);

    const handleBackToList = () => {
        setChatContent(null);
        setActualChat(null);
    };

    useEffect(() => {
        setChatContent(chatId)
        // Tomar los datos en base al chatId, en caso de que exista. Si la petición es rechazada, simplemente chatContent será null. Si no hay chatId igualmente será null,
        // y si correctamente recibe el contenido, lo escribirá en chatContent
    }, [chatId])

    const chatsDeEjemplo = [
        {
            id: '1',
            profilePhotoUrl: 'https://pbs.twimg.com/profile_images/1308769664240160770/AfgzWVE7_400x400.jpg',
            name: 'Joe Biden',
            username: '@JoeBiden',
            timeSinceLastMessage: '1h',
            lastMessage: {
                // Último mensaje o información de este, por ejemplo: You shared a post, o Alex shared a post, o Hola que como estas.
                content: 'Kamala me ha robado la reelección, me voy a vengar.',
                // Indica en caso de ser true que o bien ya has leído el mensaje o bien que eres tú el que lo envía
                read: true
            }
        },
        {
            id: '2',
            profilePhotoUrl: 'https://pbs.twimg.com/profile_images/1592241313700782081/T2pTYU8d_400x400.jpg',
            name: 'Kamala Harris',
            username: '@KamalaHarris',
            timeSinceLastMessage: '1h',
            lastMessage: {
                // Último mensaje o información de este, por ejemplo: You shared a post, o Alex shared a post, o Hola que como estas.
                content: 'Le robé al viejo, como quitarle caramelo a un niño',
                // Indica en caso de ser true que o bien ya has leído el mensaje o bien que eres tú el que lo envía
                read: true
            }
        },
        {
            id: '3',
            profilePhotoUrl: 'https://pbs.twimg.com/profile_images/874276197357596672/kUuht00m_400x400.jpg',
            name: 'Donald J. Trump',
            username: '@JoeBiden',
            timeSinceLastMessage: '1h',
            lastMessage: {
                // Último mensaje o información de este, por ejemplo: You shared a post, o Alex shared a post, o Hola que como estas.
                content: 'I said CHINA!',
                // Indica en caso de ser true que o bien ya has leído el mensaje o bien que eres tú el que lo envía
                read: true
            }
        },
        {
            id: '4',
            profilePhotoUrl: 'https://pbs.twimg.com/profile_images/1807539856311988224/5X7brHkg_400x400.jpg',
            name: 'Pedro Sánchez',
            username: '@sanchezcastejon',
            timeSinceLastMessage: '1h',
            lastMessage: {
                // Último mensaje o información de este, por ejemplo: You shared a post, o Alex shared a post, o Hola que como estas.
                content: '6 años ya en la Moncloa, ni en mis mejores sueños me lo habría imaginado',
                // Indica en caso de ser true que o bien ya has leído el mensaje o bien que eres tú el que lo envía
                read: true
            }
        },
        {
            id: '5',
            profilePhotoUrl: 'https://pbs.twimg.com/profile_images/1553931112262549505/XTcdwp0b_400x400.jpg',
            name: 'Javier Milei',
            username: '@JMilei',
            timeSinceLastMessage: '1h',
            lastMessage: {
                // Último mensaje o información de este, por ejemplo: You shared a post, o Alex shared a post, o Hola que como estas.
                content: 'O sea, digamos que vendí la patria a Elon Musk',
                // Indica en caso de ser true que o bien ya has leído el mensaje o bien que eres tú el que lo envía
                read: true
            }
        },
    ]


    const mensajesDeChatDeEjemplo = [
        {
            id: '1',
            senderId: '1',
            senderName: 'Administración',
            content: 'Este es un ejemplo de un chat entre dos jugadores de World Order. Próximamente podrás iniciar conversaciones con jugadores siempre y cuando tú les sigas y ellos te sigan a tí.',
            sender: false,
            profileImageUrl: '',
            repliedToMessageId: '',
            attachments: [],
            timestamp: '2023-09-17T10:31:00Z',
        },
        {
            id: '1',
            senderId: '1',
            senderName: 'Administración',
            content: 'Y qué pasa si quiero abrir un MD a alguien que no me sigue?',
            sender: true,
            // Read está solo si somo el que envía el mensaje, para ver si nos han leído
            read: true,
            profileImageUrl: '',
            repliedToMessageId: '',
            attachments: [],
            timestamp: '2024-10-18T10:31:00Z',
        },
        {
            id: '1',
            senderId: '1',
            senderName: 'Administración',
            content: 'No te preocupes. Con el Green Premium podrás abrir MDs a cualquiera, independientemente de si te sigue o no.',
            sender: false,
            profileImageUrl: '',
            repliedToMessageId: '',
            attachments: [],
            timestamp: '2024-10-18T10:31:00Z',
        },
        {
            id: '1',
            senderId: '1',
            senderName: 'Administración',
            content: 'Genial, entonces le abriré al señor ministro para mandarle nudes.',
            sender: true,
            read: true,
            profileImageUrl: '',
            repliedToMessageId: '',
            attachments: [],
            timestamp: '2024-10-19T10:31:00Z',
        },
        {
            id: '1',
            senderId: '1',
            senderName: 'Administración',
            content: 'Ten en cuenta que otro jugador te podrá bloquear en el futuro, deshabilitando la posibilidad de chatear con él!',
            sender: false,
            profileImageUrl: '',
            repliedToMessageId: '',
            attachments: [],
            timestamp: '2023-09-17T10:31:00Z',
        },
        {
            id: '1',
            senderId: '1',
            senderName: 'Administración',
            content: 'Y qué hay de los grupos? Se podrán hacer?',
            sender: true,
            // Read está solo si somo el que envía el mensaje, para ver si nos han leído
            read: true,
            profileImageUrl: '',
            repliedToMessageId: '',
            attachments: [],
            timestamp: '2024-10-18T10:31:00Z',
        },
        {
            id: '1',
            senderId: '1',
            senderName: 'Administración',
            content: 'Aunque llevará más tiempo, sí. En un futuro se podrá crear grupos de hasta 50 jugadores.',
            sender: false,
            profileImageUrl: '',
            repliedToMessageId: '',
            attachments: [],
            timestamp: '2024-10-18T10:31:00Z',
        },
        {
            id: '1',
            senderId: '1',
            senderName: 'Administración',
            content: 'TOP',
            sender: true,
            read: true,
            profileImageUrl: '',
            repliedToMessageId: '',
            attachments: [],
            timestamp: '2024-10-19T10:31:00Z',
        },

    ]

    let lastDate = null;

    // Datos del grupo o del chat que se ha clicado: foto de perfil y nombre
    const [actualChat, setActualChat] = useState(null);


    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const currentYear = new Date().getFullYear();

        const format = {
            month: 'long',
            day: 'numeric',
        };

        // Si el año es diferente al actual, añadimos el año al formato
        if (date.getFullYear() !== currentYear) {
            format.year = 'numeric';
        }

        return date.toLocaleDateString('en-US', format);
    };


    return (
        <div className={styles.pageContainer}>
            <div className={styles.layoutContainer} data-has-chat={Boolean(chatContent).toString()}>

                <div className={styles.chatsList}>

                    <div className={styles.messagesHeader}>
                        <p>Messages</p>
                        <div>
                            <img src={ConfigurationIcon} />
                            <img src={NewMessageIcon} />
                        </div>
                    </div>

                    <div className={styles.searchBar}>
                        <div >
                            <img src={MagnifyingGlassIcon} />
                        </div>
                        <input type="text" placeholder='Search Direct Messages'>
                        </input>
                    </div>

                    <div className={styles.chatsContent}>
                        {chatsDeEjemplo.map((chat, index) => (
                            <ChatPreview key={index} chat={chat} setActualChat={(chatInfo) => setActualChat(chatInfo)} messages={true} />
                        ))}
                    </div>
                </div>

                <div className={styles.mainContent}>
                    {chatContent ?
                        <div>
                            <div className={styles.chatContentHeader}>
                                <div>
                                    <button
                                        className={styles.backButton}
                                        onClick={handleBackToList}
                                    >
                                        <KeyboardBackspaceIcon className={styles.arrowIcon} />
                                    </button>
                                    <img src={actualChat?.profilePhotoUrl} />
                                    <p>{actualChat?.name}</p>
                                </div>
                                <button className={styles.infoIcon} onClick={() => alert('Próximamente...')}>
                                    <img src={InfoIcon} alt="info" />
                                </button>
                            </div>
                            {/* Contenido del chat seleccionado */}
                            <div className={styles.chatMessagesContainer}>
                                <div className={styles.chatMessagesWrapper}>
                                    <div className={styles.messagesContainer}>
                                        {mensajesDeChatDeEjemplo.map((message, index) => {
                                            const messageDate = formatDate(message.timestamp);

                                            const showDateHeader = lastDate !== messageDate;
                                            lastDate = messageDate;

                                            return (
                                                <div key={index}>
                                                    {showDateHeader && (
                                                        <div className={styles.dateHeader}>
                                                            <p>{messageDate}</p>
                                                        </div>
                                                    )}
                                                    <div className={`${styles.messageBubble} ${message.sender ? styles.messageAsSender : styles.messageAsReceiver}`}>
                                                        <p>{message.content}</p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={styles.writeMessageContainer}>
                                        <div className={styles.writeMessage}>
                                            <div className={styles.searchIconWrapper}>
                                                <img src={MagnifyingGlassIcon} className={styles.writeMessageIcon} alt="search" />
                                            </div>
                                            <input
                                                type="text"
                                                placeholder='Write a Message'
                                                className={styles.messageInput}
                                            />
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                        :
                        <div className={styles.noChatSelected}>
                            <div>
                                <p className={styles.selectAMessage}>Select a message</p>
                                <p className={styles.noChatSelectedDescription}>Choose from your existing conversations, start a<br />new one, or just keep smoking.</p>
                                <button>New message</button>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </div>
    );
};