import React, { useEffect, useState } from 'react'
import styles from './governmentcabinet.module.css'
import { useNavigate, useParams } from 'react-router-dom';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupsIcon from '@mui/icons-material/Groups';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import Person2Icon from '@mui/icons-material/Person2';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { api, ensureAuthorizationHeader } from '../../Config/api';

export const GovernmentCabinet = () => {

    // Username del State
    const { username } = useParams();

    const [loading, setLoading] = useState(true);

    const [houseData, setHouseData] = useState();


    useEffect(() => {
        const fetchHouseData = async () => {
            setLoading(true);
            try {
                ensureAuthorizationHeader();
                const { data } = await api.get(
                    `/api/org/house?stateUsername=${username}`
                );
                setHouseData(data); // Maneja la respuesta de la API aquí

            } catch (error) {
                console.error("Error fetching house data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchHouseData();
    }, [username]);


    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [selectedLegislator, setSelectedLegislator] = useState(null);

    const navigate = useNavigate();


    return (
        <div className={styles.container}>

            <div>

                <div className={styles.sidebar}>

                    <div className={styles.regionContainer}>

                        <div className={`${styles.region} ${styles.currentRegion}`} onClick={() => {
                            console.log(houseData?.houseSVG)
                            navigate(`/profile/state/${username}`)
                        }
                        }>
                            <img className={styles.shield} src={'https://upload.wikimedia.org/wikipedia/commons/7/7d/Escudo_de_Espa%C3%B1a_%28variant%29.svg'} />
                            <div className={styles.regionDetails}>
                                <p className={styles.regionTitle}><strong>{houseData?.stateStructure?.name}</strong></p>
                                <p className={styles.regionSubtitle}>{username}</p>
                            </div>
                        </div>

                        <div className={`${styles.region} ${styles.currentRegion}`} onClick={() => navigate(`/profile/region/prueba`)}>
                            <img className={styles.shield} src={'https://pbs.twimg.com/profile_images/1852848209544273920/n6ME-Z2t_400x400.jpg'} />
                            <div className={styles.regionDetails}>
                                <p className={styles.regionTitle}><strong>Government Cabinet</strong></p>
                                <p className={styles.regionSubtitle}>Executive Branch</p>
                            </div>
                        </div>

                        <div className={`${styles.region} ${styles.currentRegion}`} onClick={() => navigate(`/profile/user/${selectedLegislator?.username}`)}>
                            <img className={styles.legislator} src={selectedLegislator?.imageHref} />
                            <div className={styles.regionDetails}>
                                <p className={styles.regionTitle}><strong>{selectedLegislator?.name}</strong></p>
                                <p className={styles.regionSubtitle}>{selectedLegislator?.username}</p>
                            </div>
                        </div>

                        {selectedLegislator &&
                            <div className={`${styles.region} ${styles.currentRegion}`} onClick={() => { }}>
                                <img className={styles.shield} src={'https://pbs.twimg.com/profile_images/1852848209544273920/n6ME-Z2t_400x400.jpg'} />
                                <div className={styles.regionDetails}>

                                    <p className={styles.regionTitle}><strong>{''}</strong></p>

                                    <p className={styles.regionSubtitle}>Parliamentary group</p>
                                </div>
                            </div>
                        }

                    </div>


                    <div className={styles.mapModeContainer}>
                        <div className={styles.menuToogle} onClick={() => setModalIsOpen(!modalIsOpen)}>
                            <span className={styles.line} />
                            <span className={styles.line} />
                            <span className={styles.line} />
                        </div>
                        <div
                            className={`${modalIsOpen ? `${styles.show} ${styles.menu} ${styles.open}` : `${styles.show} ${styles.menu} ${styles.hidden}`}`}
                        >
                            <p className={styles.houseMenu}>Cabinet menu</p>
                            <div className={styles.closeButton} onClick={() => setModalIsOpen(false)}>
                                <span className={styles.lineX} />
                                <span className={styles.lineX} />
                            </div>

                            <div className={styles.buttonsContainer}>
                                <button>
                                    <div>
                                        <CalendarMonthIcon />
                                        Agenda
                                    </div>
                                    <ChevronRightIcon />
                                </button>
                                <button>
                                    <div>
                                        <HowToVoteIcon />
                                        Elections
                                    </div>
                                    <ChevronRightIcon />
                                </button>
                                {/* TODO: hacer aquí la sección twitter embebida de esta organization */}
                                <button>
                                    <div>
                                        <NewspaperIcon />
                                        Press office
                                    </div>
                                    <ChevronRightIcon />
                                </button>
                                <button>
                                    <div>
                                        <LocationOnIcon />
                                        Location
                                    </div>
                                    <ChevronRightIcon />
                                </button>
                            </div>
                        </div>
                    </div>


                </div>

                <div className={styles.mapContainer}>
                    <div className={styles.topOfficials}>
                        <div className={styles.fleb}>
                            <div className={styles.imagesContainer}>
                                <img src='https://upload.wikimedia.org/wikipedia/commons/3/36/Seal_of_the_President_of_the_United_States.svg' className={styles.profileImage} />
                                <img src='https://pbs.twimg.com/profile_images/736392853992001537/eF4LJLkn_400x400.jpg' className={styles.profileImage} />
                            </div>
                            <p className={styles.title}>Head of Government</p>
                            <p className={styles.name}>Donald J. Trump</p>
                            <p className={styles.username}>@realDonaldTrump</p>
                        </div>

                        <div className={styles.fleb}>
                            <div className={styles.imagesContainer}>
                                <img src='https://upload.wikimedia.org/wikipedia/commons/6/6a/Seal_of_the_Vice_President_of_the_United_States.svg' className={styles.profileImage} />
                                <img src='https://pbs.twimg.com/profile_images/1817220042578173953/5r-Qpvgt_400x400.jpg' className={styles.profileImage} />
                            </div>
                            <p className={styles.title}>Vice Head of Government</p>
                            <p className={styles.name}>JD Vance</p>
                            <p className={styles.username}>@vance</p>
                        </div>
                    </div>

                    <div className={styles.ministriesContainer}>
                        <div className={styles.fleb}>
                            <div className={styles.imagesContainer}>
                                <img src='https://upload.wikimedia.org/wikipedia/commons/f/fc/Seal_of_the_United_States_Secretary_of_State.svg' className={styles.profileImage} />
                                <img src='https://pbs.twimg.com/profile_images/1724869849602535424/4djzEA1s_400x400.jpg' className={styles.profileImage} />
                            </div>
                            <p className={styles.title}>Secretary of State</p>
                            <p className={styles.name}>Marco Rubio</p>
                            <p className={styles.username}>@MarcoRubio</p>
                        </div>

                        <div className={styles.fleb}>
                            <div className={styles.imagesContainer}>
                                <img src='https://media.defense.gov/2023/Mar/30/2003190439/-1/-1/0/230330-F-ZS275-9999.JPG' className={styles.profileImage} />
                                <img src='https://www.stripes.com/migration/kg4qwg-robert-salesses,-deputy-assistant-secretary-of-def/alternates/LANDSCAPE_910/Robert%20Salesses%20Deputy%20Assistant%20Secretary%20of%20Def' className={styles.profileImage} />
                            </div>
                            <p className={styles.title}>Secretary of Defense</p>
                            <p className={styles.name}>Robert Salesses</p>
                            <p className={styles.username}>@salesses</p>
                        </div>

                        <div className={styles.fleb}>
                            <div className={styles.imagesContainer}>
                                <img src='https://upload.wikimedia.org/wikipedia/commons/c/cb/Seal_of_the_United_States_Department_of_the_Treasury.svg' className={styles.profileImage} />
                                <img src='https://pbs.twimg.com/profile_images/1880011976601935872/_0yiAy___400x400.jpg' className={styles.profileImage} />
                            </div>
                            <p className={styles.title}>Secretary of Treasury</p>
                            <p className={styles.name}>Scott Bessent</p>
                            <p className={styles.username}>@Scott_Bessent</p>
                        </div>
                    </div>
                </div>

            </div>


        </div>

    );
};