import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './politicalpartyfoundation.module.css';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';
import { Avatar, FormControlLabel, FormGroup, MenuItem, Modal, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomDropdown } from '../../../../Utils/Components/CustomDropdown';
import { CustomTextArea } from '../../../../Utils/Components/CustomTextArea';
import { CustomTextField } from '../../../../Utils/Components/CustomTextField';
import { Checkbox } from '@mui/material';
import ProfileImageUploader from '../../../../Utils/Components/ImageCropResize/ProfileImageUploader';
import { dataUrlToFile } from '../../../ProfileContainer/PlayerProfile/dataUrlToFile';
import BackgroundImageUploader from '../../../../Utils/Components/ImageCropResize/Background/BackgroundImageUploader';
import CloseIcon from '@mui/icons-material/Close';
import { api, multipartApi } from '../../../../Config/api';
import CheckIcon from '@mui/icons-material/Check';
import RequirementsDisplay from './RequirementsDisplay/RequirementsDisplay';
import { useSelector } from 'react-redux';
import { UserCoFoundersList } from '../Utils/UserCoFoundersList';

export const PoliticalPartyFoundation = () => {

    const [openProfilePhotoModal, setOpenProfilePhotoModal] = useState(false);
    const [openBackgroundPhotoModal, setOpenBackgroundPhotoModal] = useState(false);

    const authSlice = useSelector(store => store.auth);

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [backgroundImageFile, setBackgroundImageFile] = useState(null);
    // Foto extraído directamente de los archivos del usuario, falta refinar, extraer URL y asignársela al valor de formik
    const [profileImageFile, setProfileImageFile] = useState(null);

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required')
            .min(3, 'Minimum three characters'),
        username: Yup.string()
            .required('Username is required')
            .min(3, 'Minimum three characters'),
        culture: Yup.string()
            .when('secondaryIdeology', {
                is: 'NATIONALISM',
                then: (schema) => schema.required('Culture is required'),
                otherwise: (schema) => schema.notRequired()
            }),

        chairpersonElections: Yup.string().required('All internal rules are required'),
        chairpersonTermOfOffice: Yup.string()
            .when('chairpersonElections', {
                is: 'CHAIRPERSON_ELECTS',
                then: (schema) => schema.notRequired(),
                otherwise: (schema) => schema.required('All internal rules are required')
            }),
        chairpersonRemoval: Yup.string().required('All internal rules are required'),

        committeeElections: Yup.string().required('All internal rules are required'),
        committeeTermOfOffice: Yup.string()
            .when('committeeElections', {
                is: 'CHAIRPERSON_ELECTS',
                then: (schema) => schema.notRequired(),
                otherwise: (schema) => schema.required('All internal rules are required')
            }),
        committeeRemoval: Yup.string().required('All internal rules are required'),

        electionOfElectoralCandidates: Yup.string().required('All internal rules are required'),
        coalitionBuilding: Yup.string().required('All internal rules are required'),
        membership: Yup.string().required('All internal rules are required'),
        expulsions: Yup.string().required('All internal rules are required'),
        changes: Yup.string().required('All internal rules are required'),
        religion: Yup.string().required('Religion is required'),
        coFounders: Yup.array().min(2, 'At least two co-founders are required'),
    });

    const formik = useFormik({
        initialValues: {
            backgroundImage: '',
            profileImage: '',
            name: '',
            username: '',
            description: '',
            culture: '',
            religion: '',
            mainIdeology: '',
            secondaryIdeology: '',
            economicIdeology: '',
            formOfGovernmentIdeology: '',
            powerStructureIdeology: '',
            chairpersonElections: '',
            chairpersonTermOfOffice: '',
            chairpersonRemoval: '',
            committeeElections: '',
            committeeTermOfOffice: '',
            committeeRemoval: '',
            electionOfElectoralCandidates: '',
            coalitionBuilding: '',
            membership: '',
            expulsions: '',
            changes: '',
            // Contiene el username de los cofundadores a los que se les enviará la solicitud de cofundación
            coFounders: [],
            // Contiene el mensaje personalizado que se enviará a los potenciales cofundadores en el correo electrónico
            emailMessage: '',
            createOnlyIfAllCoFoundersAccept: false,
        },
        validationSchema,
        onSubmit: async (values) => {

            // Si el valor de religion es 'none', cambiarlo a null
            if (values.religion === 'NONE') {
                values.religion = null;
            }

            if (values.culture === '') {
                values.culture = null;
            } else {
                // TODO faltaría por revisar si el secondaryIdeology es nationalism y no se ha seleccionado ninguna cultura
                if (values.secondaryIdeology !== 'NATIONALISM') {
                    values.culture = null;
                }
            }

            if (values.chairpersonTermOfOffice === '') {
                values.chairpersonTermOfOffice = null;
            }

            if (values.committeeTermOfOffice === '') {
                values.committeeTermOfOffice = null;
            }

            // Incluir las fotos de perfil y de fondo, además del json con todos los datos del formik, excepto backgroundImage y profileImage
            const formData = new FormData();

            // Convertimos en File la URI de la imagen cropeada y redimensionada, ya que habíamos pasado el formik por parámetro para que esa uri se asignara.
            if (values.profileImage) {
                const profileImageFile = dataUrlToFile(formik.values.profileImage, 'profileImage.jpeg')
                formData.append('profileImageFile', profileImageFile);
            }

            if (values.backgroundImage) {
                const backgroundImageFile = dataUrlToFile(formik.values.backgroundImage, 'backgroundImage.jpeg')
                formData.append('backgroundImageFile', backgroundImageFile);
            }

            // 1. Preparar el objeto de datos excluyendo las imágenes
            // 1. Preparar el objeto de datos excluyendo las imágenes y reagrupando las ideologías
            const formDataObject = {
                ...values,
                ideology: {
                    mainIdeology: values.mainIdeology,
                    secondaryIdeology: values.secondaryIdeology,
                    economicIdeology: values.economicIdeology,
                    formOfGovernmentIdeology: values.formOfGovernmentIdeology,
                    powerStructureIdeology: values.powerStructureIdeology
                }
            };

            // Eliminar las propiedades originales de ideología y las imágenes
            delete formDataObject.mainIdeology;
            delete formDataObject.secondaryIdeology;
            delete formDataObject.economicIdeology;
            delete formDataObject.formOfGovernmentIdeology;
            delete formDataObject.powerStructureIdeology;
            delete formDataObject.profileImage;
            delete formDataObject.backgroundImage;

            // 2. Convertir los datos del formulario a JSON y añadirlo como un blob
            formData.append(
                'data',
                new Blob([JSON.stringify(formDataObject)], {
                    type: 'application/json'
                })
            );

            try {
                setLoading(true);

                // Make the POST request using multipartApi
                // We pass the formData as the second parameter which contains both the images and JSON data
                const response = await multipartApi.post('api/org/found/political-party', formData);

                // Check if the response status is 200 (OK)
                // In REST APIs, 200 indicates a successful request
                if (response.status === 200) {
                    // If successful, navigate to the organizations page
                    navigate('/org');
                } else {
                    // If the status isn't 200, you might want to handle it accordingly
                    // For example, you could throw an error or show a message to the user
                    console.error('Unexpected response status:', response.status);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        },
    });

    // Maneja la carga de la nueva imagen de fondo
    const handleUploadBackgroundImage = (event) => {

        const file = event.target.files[0];
        if (file) {
            const url = URL.createObjectURL(file);
            setBackgroundImageFile(file);
            setOpenBackgroundPhotoModal(true);
            //formik.setFieldValue('backgroundImage', url); // Actualiza el valor en formik
        }
    };

    // Abrir el input file cuando se hace clic en la foto de perfil
    const handleBackgroundImageClick = () => {
        document.getElementById('backgroundImageInput').click();
    };

    // Maneja la carga de la nueva imagen de perfil
    const handleUploadProfileImage = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfileImageFile(file);
            setOpenProfilePhotoModal(true);
            // formik.setFieldValue('profileImage', url); // Actualiza el valor en formik
        }
    };


    // Abrir el input file cuando se hace clic en la imagen
    const handleProfileImageClick = () => {
        // Primero activamos el input file
        document.getElementById('profileImageInput').click();
    };

    const [coFoundersInfo, setCoFoundersInfo] = useState([]);

    const handleRemoveCofounder = (username) => {
        setCoFoundersInfo((prevCoFounders) => prevCoFounders.filter(cofounder => cofounder.username !== username));

        // Actualizar Formik
        const updatedCoFounders = formik.values.coFounders.filter(
            cofounderUsername => cofounderUsername !== username
        );
        formik.setFieldValue('coFounders', updatedCoFounders);
    };

    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [abortController, setAbortController] = useState(null);




    const handleSearch = async (query) => {
        if (abortController) {
            abortController.abort();
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        try {
            const response = await api.get(`/api/user/searchPotentialCoFounders?nameOrUsername=${query}`, { signal: newAbortController.signal });
            setSearchResults(response.data);
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('Failed to fetch search results:', error);
            }
        }
    };

    useEffect(() => {
        if (searchQuery.trim()) {
            const delayDebounceFn = setTimeout(() => {
                handleSearch(searchQuery); // Realiza la búsqueda si hay un query
            }, 300);

            return () => clearTimeout(delayDebounceFn);
        } else {
            // Si no hay query, limpia los resultados
            setSearchResults([]);
        }

    }, [searchQuery]);

    const handleAddCoFounder = (selectedPlayer) => {

        // Verifica si el jugador ya está en coFoundersInfo
        const isAlreadyCofounder = coFoundersInfo.some(
            cofounder => cofounder.username === selectedPlayer.username
        );

        // Verifica que no se haya llegado al límite de 5 coFundadores invitados (6 incluyendo al que inicia la fundación)
        const maxCoFoundersValidation = coFoundersInfo.length < 5;

        // Si no está en la lista y no se ha llegado al límite de coFundadores invitados, lo añadimos
        if (!isAlreadyCofounder && maxCoFoundersValidation) {

            // Verifica que el jugador seleccionado no sea el propio jugador
            const isThePlayer = authSlice.username === selectedPlayer.username;

            if (!isThePlayer) {

                // Actualiza el estado coFoundersInfo
                setCoFoundersInfo((prevInfo) => [...prevInfo, selectedPlayer]);

                // Añade el username al array coFounders de Formik
                formik.setFieldValue('coFounders', [...formik.values.coFounders, selectedPlayer.username]);
            }
        };
    }

    // Contiene la cantidad de arauzita que tiene el player, si tiene aprendido el conocimiento requerido y si ya está fundando otra organization, de cualquier tipo, en cuyo caso no podrá empezar a crear otra
    const [requirementsAndPlace, setRequirementsAndPlace] = useState({});
    // Indica si cumple todos los requisitios para fundar un partido político
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        // Traemos los datos para comprobar si el player tiene aprendido el conocimiento requerido de Young Idealist,
        // si está fundando otra organization, el lugar donde tendría la sede la nueva organization y la arauzita necesaria
        const initializeComponent = async () => {
            try {
                const response = await api.get('/api/org/foundation/requirements');
                setRequirementsAndPlace(response.data);
            } catch (error) {
                console.error('Failed to fetch foundation requirements:', error);
            }
        };

        initializeComponent();
    }, []);




    return (
        <div className={styles.mainContent}>
            <div className={styles.content} style={{ border: '1px solid #2c2f32', borderBottom: '0px' }}>
                <section className={styles.fixedHeader}>
                    <KeyboardBackspaceIcon className={styles.arrowIcon} onClick={() => navigate(-1)} />
                    <h1 className={styles.headerTitle}>Found a political party</h1>
                </section>

                <form onSubmit={formik.handleSubmit} className={styles.form}>
                    {/* Imagen de fondo, clickable */}
                    <img
                        className={styles.backgroundImage}
                        src={formik.values.backgroundImage || 'https://cdn.pixabay.com/photo/2023/07/25/19/47/milky-way-8149815_640.jpg'}
                        alt='background'
                        onClick={handleBackgroundImageClick} // Al hacer clic, se abre el input
                    />

                    {/* Input de tipo file oculto para el backgroundImage */}
                    <input
                        type="file"
                        id="backgroundImageInput"
                        className={styles.imageInput}
                        onChange={handleUploadBackgroundImage}
                        accept="image/*"
                    />

                    {/* Input de tipo file oculto */}
                    <input
                        type="file"
                        id="profileImageInput"
                        className={styles.imageInput}
                        onChange={handleUploadProfileImage}
                        accept="image/*"
                    />

                    <Avatar className={styles.profileImage} src={formik.values.profileImage} onClick={handleProfileImageClick} alt='' />

                    <div className={styles.noImagesForm}>


                        <div className={styles.titleSection}>
                            <div className={styles.textAndInfo}>
                                <p className={styles.title}>GENERAL INFO</p>
                            </div>
                            <hr />
                        </div>

                        <CustomTextField
                            id={'name'}
                            name={'name'}
                            label={'Name'}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            maxLength={30}
                        />

                        <CustomTextField
                            id={'username'}
                            name={'username'}
                            label={'Username'}
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            error={formik.touched.username && Boolean(formik.errors.username)}
                            helperText={formik.touched.username && formik.errors.username}
                            maxLength={25}
                        />
                        {/* TODO: poner que se necesitan 3 caracteres como mínimo */}

                        <CustomTextArea
                            id={'description'}
                            name={'description'}
                            label={'Description'}
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                            maxLength={160}
                        />

                        <div className={styles.titleSection} style={{ paddingTop: '15px' }}>
                            <div className={styles.textAndInfo}>
                                <p className={styles.title}>IDEOLOGY</p>
                            </div>
                            <hr />
                        </div>

                        <CustomDropdown
                            id={'mainIdeology'}
                            name={'mainIdeology'}
                            label={'Main ideology'}
                            value={formik.values.mainIdeology}
                            onChange={formik.handleChange}
                            error={formik.touched.mainIdeology && Boolean(formik.errors.mainIdeology)}
                            helperText={formik.touched.mainIdeology && formik.errors.mainIdeology}
                        >
                            <MenuItem value='CONSERVATISM'>Conservatism</MenuItem>
                            <MenuItem value='SOCIAL_DEMOCRACY'>Social Democracy</MenuItem>
                            <MenuItem value='LIBERALISM'>Liberalism</MenuItem>
                            <MenuItem value='COMMUNISM'>Communism</MenuItem>
                            <MenuItem value='ANARCHISM'>Anarchism</MenuItem>
                            <MenuItem value='FASCISM'>Fascism</MenuItem>
                        </CustomDropdown>

                        <CustomDropdown
                            id={'secondaryIdeology'}
                            name={'secondaryIdeology'}
                            label={'Secondary ideology'}
                            value={formik.values.secondaryIdeology}
                            onChange={formik.handleChange}
                            error={formik.touched.secondaryIdeology && Boolean(formik.errors.secondaryIdeology)}
                            helperText={formik.touched.secondaryIdeology && formik.errors.secondaryIdeology}
                        >
                            <MenuItem value='NATIONALISM'>Nationalism</MenuItem>
                            <MenuItem value='REGIONALISM'>Regionalism</MenuItem>
                            <MenuItem value='LIBERTARIANISM'>Libertarianism</MenuItem>
                            <MenuItem value='MERITOCRACY'>Meritocracy</MenuItem>
                            <MenuItem value='TECHNOCRACY'>Technocracy</MenuItem>
                            <MenuItem value='ENVIRONMENTALISM'>Environmentalism</MenuItem>
                            <MenuItem value='INTERNATIONALISM'>Internationalism</MenuItem>
                            <MenuItem value='SYNDICALISM'>Syndicalism</MenuItem>
                            <MenuItem value='FEMINISM'>Feminism</MenuItem>
                        </CustomDropdown>


                        {formik.values.secondaryIdeology === 'NATIONALISM' &&

                            <CustomDropdown
                                id={'culture'}
                                name={'culture'}
                                label={'Culture'}
                                value={formik.values.culture}
                                onChange={formik.handleChange}
                                error={formik.touched.culture && Boolean(formik.errors.culture)}
                                helperText={formik.touched.culture && formik.errors.culture}
                            >
                                <MenuItem value='ARGENTINIAN'>Argentinian</MenuItem>
                                <MenuItem value='AFRIKAANS'>Afrikaans</MenuItem>
                                <MenuItem value='ANGLO_CARIBBEAN'>Anglo Caribbean</MenuItem>
                                <MenuItem value='AUSTRALIAN'>Australian</MenuItem>
                                <MenuItem value='ALGERIAN'>Algerian</MenuItem>
                                <MenuItem value='ALBANIAN'>Albanian</MenuItem>
                                <MenuItem value='ARMENIAN'>Armenian</MenuItem>
                                <MenuItem value='ASLIAN'>Aslian</MenuItem>
                                <MenuItem value='AZERBAIJANI'>Azerbaijani</MenuItem>
                                <MenuItem value='ADAMAWAUBANGI'>Adamawa Ubangi</MenuItem>
                                <MenuItem value='ATLANTICCONGO'>Atlantic Congo</MenuItem>
                                <MenuItem value='AMHARIC'>Amharic</MenuItem>

                                <MenuItem value='BAMBARE'>Bambara</MenuItem>
                                <MenuItem value='BANTOID'>Bantoid</MenuItem>
                                <MenuItem value='BANTU'>Bantu</MenuItem>
                                <MenuItem value='BENUE'>Benue</MenuItem>
                                <MenuItem value='BASQUE'>Basque</MenuItem>
                                <MenuItem value='BERBER'>Berber</MenuItem>
                                <MenuItem value='BELARUSIAN'>Belarusian</MenuItem>
                                <MenuItem value='BOSNIAKS'>Bosniaks</MenuItem>
                                <MenuItem value='BULGARIAN'>Bulgarian</MenuItem>
                                <MenuItem value='BOLIVIAN'>Bolivian</MenuItem>
                                <MenuItem value='BRAZILIAN'>Brazilian</MenuItem>
                                <MenuItem value='BRITISH'>British</MenuItem>
                                <MenuItem value='BRETON'>Breton</MenuItem>
                                <MenuItem value='BRAHUI'>Brahui</MenuItem>
                                <MenuItem value='BIHARI'>Bihari</MenuItem>
                                <MenuItem value='BENGALI'>Bengali</MenuItem>
                                <MenuItem value='BURMESE'>Burmese</MenuItem>
                                <MenuItem value='BAHNARIC'>Bahnaric</MenuItem>
                                <MenuItem value='BALOOCHI'>Balochi</MenuItem>

                                <MenuItem value='CROATS'>Croats</MenuItem>
                                <MenuItem value='CZECH'>Czech</MenuItem>
                                <MenuItem value='CHADIC'>Chadic</MenuItem>
                                <MenuItem value='CATALAN'>Catalan</MenuItem>
                                <MenuItem value='CENTRAL_AMERICAN'>Central American</MenuItem>
                                <MenuItem value='CHILEAN'>Chilean</MenuItem>
                                <MenuItem value='COLOMBIAN'>Colombian</MenuItem>
                                <MenuItem value='CANADIAN'>Canadian</MenuItem>
                                <MenuItem value='CHECHEN'>Chechen</MenuItem>

                                <MenuItem value='DAGESTAN'>Dagestan</MenuItem>
                                <MenuItem value='DANISH'>Danish</MenuItem>
                                <MenuItem value='DUTCH'>Dutch</MenuItem>
                                <MenuItem value='DUTCH_CARIBBEAN'>Dutch Caribbean</MenuItem>
                                <MenuItem value='DARI'>Dari</MenuItem>

                                <MenuItem value='EGYPTIAN'>Egyptian</MenuItem>
                                <MenuItem value='ECUADORIAN'>Ecuadorian</MenuItem>
                                <MenuItem value='ESTONIAN'>Estonian</MenuItem>
                                <MenuItem value='ESKIMO'>Eskimo</MenuItem>

                                <MenuItem value='FRENCH'>French</MenuItem>
                                <MenuItem value='FRANCO_BELGIAN'>Franco Belgian</MenuItem>
                                <MenuItem value='FRANCO_CARIBBEAN'>Franco Caribbean</MenuItem>
                                <MenuItem value='FLEMISH_BELGIAN'>Flemish Belgian</MenuItem>
                                <MenuItem value='FINNISH'>Finnish</MenuItem>

                                <MenuItem value='GUJARATI'>Gujarati</MenuItem>
                                <MenuItem value='GALICIAN'>Galician</MenuItem>
                                <MenuItem value='GERMAN'>German</MenuItem>
                                <MenuItem value='GUYANESE'>Guyanese</MenuItem>
                                <MenuItem value='GEORGIAN'>Georgian</MenuItem>
                                <MenuItem value='GUR'>Gur</MenuItem>
                                <MenuItem value='GAN'>Gan</MenuItem>
                                <MenuItem value='GREEK'>Greek</MenuItem>

                                <MenuItem value='HEBREW'>Hebrew</MenuItem>
                                <MenuItem value='HAKKA'>Hakka</MenuItem>
                                <MenuItem value='HISPANIC_CARIBBEAN'>Hispanic Caribbean</MenuItem>
                                <MenuItem value='HINDI'>Hindi</MenuItem>
                                <MenuItem value='HUNGARIAN'>Hungarian</MenuItem>

                                <MenuItem value='ICELANDIC'>Icelandic</MenuItem>
                                <MenuItem value='ITALIAN'>Italian</MenuItem>
                                <MenuItem value='IRAQI'>Iraqi</MenuItem>
                                <MenuItem value='INDONESIAN'>Indonesian</MenuItem>
                                <MenuItem value='IRISH'>Irish</MenuItem>

                                <MenuItem value='JAPANESE'>Japanese</MenuItem>
                                <MenuItem value='JORDANIANS'>Jordanians</MenuItem>
                                <MenuItem value='JEMER'>Jemer</MenuItem>
                                <MenuItem value='JIN'>Jin</MenuItem>

                                <MenuItem value='KARENIC'>Karenic</MenuItem>
                                <MenuItem value='KHMUIC'>Khmuic</MenuItem>
                                <MenuItem value='KANNADA'>Kannada</MenuItem>
                                <MenuItem value='KORDOFANIAN'>Kordofanian</MenuItem>
                                <MenuItem value='KWA'>Kwa</MenuItem>
                                <MenuItem value='KRU'>Kru</MenuItem>
                                <MenuItem value='KURDISH'>Kurdish</MenuItem>
                                <MenuItem value='KAZAKH'>Kazakh</MenuItem>
                                <MenuItem value='KIRGYZ'>Kyrgyz</MenuItem>
                                <MenuItem value='KHOISAN'>Khoisan</MenuItem>
                                <MenuItem value='KOREAN'>Korean</MenuItem>

                                <MenuItem value='LEBANESE'>Lebanese</MenuItem>
                                <MenuItem value='LIBYAN'>Libyan</MenuItem>
                                <MenuItem value='LATVIAN'>Latvian</MenuItem>
                                <MenuItem value='LITHUANIAN'>Lithuanian</MenuItem>
                                <MenuItem value='LAO'>Lao</MenuItem>

                                <MenuItem value='MAURITANIAN'>Mauritanian</MenuItem>
                                <MenuItem value='MACEDONIANS'>Macedonians</MenuItem>
                                <MenuItem value='MOROCCAN'>Moroccan</MenuItem>
                                <MenuItem value='MEXICAN'>Mexican</MenuItem>
                                <MenuItem value='MALAGASY'>Malagasy</MenuItem>
                                <MenuItem value='MALAY'>Malay</MenuItem>
                                <MenuItem value='MELANESIAN'>Melanesian</MenuItem>
                                <MenuItem value='MICRONESIAN'>Micronesian</MenuItem>
                                <MenuItem value='MANDARIN'>Mandarin</MenuItem>
                                <MenuItem value='MIN'>Min</MenuItem>
                                <MenuItem value='MARATHI'>Marathi</MenuItem>
                                <MenuItem value='MALDIVAN'>Maldivan</MenuItem>
                                <MenuItem value='MALAYALAM'>Malayalam</MenuItem>
                                <MenuItem value='MONGOL'>Mongol</MenuItem>

                                <MenuItem value='NEPALI'>Nepali</MenuItem>
                                <MenuItem value='NEW_ZEALANDER'>New Zealander</MenuItem>
                                <MenuItem value='NORWEGIAN'>Norwegian</MenuItem>
                                <MenuItem value='NILO_SAHARAN'>Nilo Saharan</MenuItem>

                                <MenuItem value='OMANI'>Omani</MenuItem>
                                <MenuItem value='OROMO'>Oromo</MenuItem>

                                <MenuItem value='PARAGUAYAN'>Paraguayan</MenuItem>
                                <MenuItem value='PALESTINIAN'>Palestinian</MenuItem>
                                <MenuItem value='PERUVIAN'>Peruvian</MenuItem>
                                <MenuItem value='PORTUGUESE'>Portuguese</MenuItem>
                                <MenuItem value='PERSIAN'>Persian</MenuItem>
                                <MenuItem value='PASHTO'>Pashto</MenuItem>
                                <MenuItem value='PHILIPPINE'>Philippine</MenuItem>
                                <MenuItem value='POLYNESIAN'>Polynesian</MenuItem>
                                <MenuItem value='PUNJABI'>Punjabi</MenuItem>
                                <MenuItem value='POLISH'>Polish</MenuItem>
                                <MenuItem value='PAPUAN'>Papuan</MenuItem>
                                <MenuItem value='PINGHUA'>Pinghua</MenuItem>

                                <MenuItem value='QUEBECOIS'>Quebecois</MenuItem>
                                <MenuItem value='QIANGIC'>Qiangic</MenuItem>

                                <MenuItem value='UKRAINIAN'>Ukrainian</MenuItem>

                                <MenuItem value='ROMANI'>Romani</MenuItem>
                                <MenuItem value='ROMANIAN'>Romanian</MenuItem>
                                <MenuItem value='RAJASTHANI'>Rajasthani</MenuItem>
                                <MenuItem value='RUSSIAN'>Russian</MenuItem>

                                <MenuItem value='SPANISH'>Spanish</MenuItem>
                                <MenuItem value='SINDHI'>Sindhi</MenuItem>
                                <MenuItem value='SINHALA'>Sinhala</MenuItem>
                                <MenuItem value='SIRIUS'>Sirius</MenuItem>
                                <MenuItem value='SUDANESE'>Sudanese</MenuItem>
                                <MenuItem value='SCOTTISH'>Scottish</MenuItem>
                                <MenuItem value='SOMALI'>Somali</MenuItem>
                                <MenuItem value='SENUFO'>Senufo</MenuItem>
                                <MenuItem value='SURINAMESE'>Surinamese</MenuItem>
                                <MenuItem value='SWEDISH'>Swedish</MenuItem>
                                <MenuItem value='SERBIANS'>Serbians</MenuItem>
                                <MenuItem value='SLOVAK'>Slovak</MenuItem>
                                <MenuItem value='SLOVENIAN'>Slovenian</MenuItem>
                                <MenuItem value='SAMI'>Sami</MenuItem>
                                <MenuItem value='SAUDI'>Saudi</MenuItem>

                                <MenuItem value='TUNISIAN'>Tunisian</MenuItem>
                                <MenuItem value='TAMIL'>Tamil</MenuItem>
                                <MenuItem value='TELEGU'>Telegu</MenuItem>
                                <MenuItem value='TIGRINYA'>Tigrinya</MenuItem>
                                <MenuItem value='TIBETAN'>Tibetan</MenuItem>
                                <MenuItem value='TATAR'>Tatar</MenuItem>
                                <MenuItem value='TURKMEN'>Turkmen</MenuItem>
                                <MenuItem value='TURKISH'>Turkish</MenuItem>
                                <MenuItem value='THAI'>Thai</MenuItem>

                                <MenuItem value='UJYGHUR'>Uyghur</MenuItem>
                                <MenuItem value='URUGUAYAN'>Uruguayan</MenuItem>
                                <MenuItem value='URDU'>Urdu</MenuItem>
                                <MenuItem value='UZBEK'>Uzbek</MenuItem>

                                <MenuItem value='VENEZUELAN'>Venezuelan</MenuItem>
                                <MenuItem value='VIETNAMESE'>Vietnamese</MenuItem>
                                <MenuItem value='VOLTANIGER'>Volta Niger</MenuItem>

                                <MenuItem value='WELSH'>Welsh</MenuItem>
                                <MenuItem value='WU'>Wu</MenuItem>

                                <MenuItem value='XIANG'>Xiang</MenuItem>

                                <MenuItem value='YANKEE'>Yankee</MenuItem>
                                <MenuItem value='YUE'>Yue</MenuItem>
                                <MenuItem value='YEMENI'>Yemeni</MenuItem>

                                <MenuItem value='ZHUANG'>Zhuang</MenuItem>
                            </CustomDropdown>
                        }

                        <CustomDropdown
                            id={'economicIdeology'}
                            name={'economicIdeology'}
                            label={'Economic ideology'}
                            value={formik.values.economicIdeology}
                            onChange={formik.handleChange}
                            error={formik.touched.economicIdeology && Boolean(formik.errors.economicIdeology)}
                            helperText={formik.touched.economicIdeology && formik.errors.economicIdeology}
                        >
                            <MenuItem value='LAISSEZ_FAIRE'>Laissez Faire</MenuItem>
                            <MenuItem value='KEYNESIANISM'>Keynesianism</MenuItem>
                            <MenuItem value='CORPORATISM'>Corporatism</MenuItem>
                            <MenuItem value='PLANNED_ECONOMY'>Planned Economy</MenuItem>
                        </CustomDropdown>

                        <CustomDropdown
                            id={'formOfGovernmentIdeology'}
                            name={'formOfGovernmentIdeology'}
                            label={'Form of government preference'}
                            value={formik.values.formOfGovernmentIdeology}
                            onChange={formik.handleChange}
                            error={formik.touched.formOfGovernmentIdeology && Boolean(formik.errors.formOfGovernmentIdeology)}
                            helperText={formik.touched.formOfGovernmentIdeology && formik.errors.formOfGovernmentIdeology}
                        >
                            <MenuItem value='MONARCHISM'>Monarchism</MenuItem>
                            <MenuItem value='THEOCRACY'>Theocracy</MenuItem>
                            <MenuItem value='REPUBLICANISM'>Republicanism</MenuItem>
                            <MenuItem value='ONE_PARTY_SYSTEM'>One Party System</MenuItem>
                            <MenuItem value='MILITARY_DICTATORSHIP'>Military dictatorship</MenuItem>
                        </CustomDropdown>

                        <CustomDropdown
                            id={'powerStructureIdeology'}
                            name={'powerStructureIdeology'}
                            label={'Power structure preference'}
                            value={formik.values.powerStructureIdeology}
                            onChange={formik.handleChange}
                            error={formik.touched.powerStructureIdeology && Boolean(formik.errors.powerStructureIdeology)}
                            helperText={formik.touched.powerStructureIdeology && formik.errors.powerStructureIdeology}
                        >
                            <MenuItem value='UNITARY_STATE'>Unitary State</MenuItem>
                            <MenuItem value='FEDERALISM'>Federalism</MenuItem>
                        </CustomDropdown>

                        <CustomDropdown
                            id={'religion'}
                            name={'religion'}
                            label={'Religion'}
                            value={formik.values.religion}
                            onChange={formik.handleChange}
                            error={formik.touched.religion && Boolean(formik.errors.religion)}
                            helperText={formik.touched.religion && formik.errors.religion}
                        >
                            <MenuItem value='NONE'>None</MenuItem>

                            <MenuItem value='CATHOLICISM'>Catholicism</MenuItem>
                            <MenuItem value='ORTHODOX'>Orthodox Christianity</MenuItem>
                            <MenuItem value='COPTIC_ORTHODOX'>Coptic Orthodox</MenuItem>
                            <MenuItem value='PROTESTANTISM'>Protestantism</MenuItem>
                            <MenuItem value='MORMONISM'>Mormornism</MenuItem>

                            <MenuItem value='SUNNISM'>Sunnism</MenuItem>
                            <MenuItem value='SHIISM'>Shiism</MenuItem>
                            <MenuItem value='KHARIJISM'>Kharijism</MenuItem>

                            <MenuItem value='VAISHNAVISM'>Vaishnavism</MenuItem>
                            <MenuItem value='SHAIVISM'>Shaivism</MenuItem>
                            <MenuItem value='SHAKTISM'>Shaktism</MenuItem>

                            <MenuItem value='SMARTISM'>Smartism</MenuItem>
                            <MenuItem value='MAHAYANA'>Mahayana</MenuItem>
                            <MenuItem value='VAJRAYANA'>Vajrayana</MenuItem>
                            <MenuItem value='THERAVADA'>Therevada</MenuItem>

                            <MenuItem value='ORTHODOX_JUDAISM'>Orthodox judaism</MenuItem>
                            <MenuItem value='CONSERVATIVE_JUDAISM'>Conservative judaism</MenuItem>
                            <MenuItem value='REFORM_JUDAISM'>Reform judaism</MenuItem>

                            <MenuItem value='SHENNISM'>Shennism</MenuItem>
                            <MenuItem value='TAOISM'>Taoism</MenuItem>
                            <MenuItem value='CONFUCIANISM'>Confucianism</MenuItem>
                        </CustomDropdown>

                        <div className={styles.titleSection} style={{ paddingTop: '15px' }}>
                            <div className={styles.textAndInfo}>
                                <p className={styles.title}>INTERNAL RULES</p>
                            </div>
                            <hr />
                            <p className={styles.infoText}>The party's internal rules establish aspects of <b>the election of internal positions and candidates for elections</b>.</p>
                        </div>

                        <CustomDropdown
                            id={'chairpersonElections'}
                            name={'chairpersonElections'}
                            label={'Chairperson elections'}
                            value={formik.values.chairpersonElections}
                            onChange={formik.handleChange}
                            error={formik.touched.chairpersonElections && Boolean(formik.errors.chairpersonElections)}
                            helperText={formik.touched.chairpersonElections && formik.errors.chairpersonElections}
                        >
                            <MenuItem value='CHAIRPERSON_ELECTS'>Chairperson elects</MenuItem>
                            <MenuItem value='COMMITTEE_ELECTS'>Committee elects</MenuItem>
                            <MenuItem value='AFFILIATES_VOTE'>Affiliates' vote</MenuItem>
                            <MenuItem value='AFFILIATES_VOTE_BALLOTAGE'>Affiliates' vote (ballotage)</MenuItem>
                            <MenuItem value='DELEGATES_VOTE'>Delegates' vote</MenuItem>
                            <MenuItem value='DELEGATES_VOTE_BALLOTAGE'>Delegates' vote (ballotage)</MenuItem>
                        </CustomDropdown>

                        {formik.values.chairpersonElections !== 'CHAIRPERSON_ELECTS' && formik.values.chairpersonElections !== '' &&
                            <CustomDropdown
                                id={'chairpersonTermOfOffice'}
                                name={'chairpersonTermOfOffice'}
                                label={'Term of office'}
                                value={formik.values.chairpersonTermOfOffice}
                                onChange={formik.handleChange}
                                error={formik.touched.chairpersonTermOfOffice && Boolean(formik.errors.chairpersonTermOfOffice)}
                                helperText={formik.touched.chairpersonTermOfOffice && formik.errors.chairpersonTermOfOffice}
                            >
                                <MenuItem value='SIX_MONTHS'>Six months</MenuItem>
                                <MenuItem value='TWELVE_MONTHS'>Twelve months</MenuItem>
                                <MenuItem value='EIGHTEEN_MONTHS'>Eighteen months</MenuItem>
                                <MenuItem value='TWENTY_FOUR_MONTHS'>Twenty-four months</MenuItem>
                            </CustomDropdown>
                        }

                        <CustomDropdown
                            id={'chairpersonRemoval'}
                            name={'chairpersonRemoval'}
                            label={'Chairperson removal'}
                            value={formik.values.chairpersonRemoval}
                            onChange={formik.handleChange}
                            error={formik.touched.chairpersonRemoval && Boolean(formik.errors.chairpersonRemoval)}
                            helperText={formik.touched.chairpersonRemoval && formik.errors.chairpersonRemoval}
                        >
                            <MenuItem value='NOT_POSSIBLE'>Not possible</MenuItem>
                            {
                                /* No queremos que miembros del comité puedan ser destituidos por el presidente y que el presidente pueda ser destituido por el comité */
                                formik.values.committeeRemoval !== 'CHAIRPERSON_DECISION' &&
                                <MenuItem value='COMMITTEE_DECISION'>Committee decision</MenuItem>
                            }
                            <MenuItem value='AFFILIATES_VOTE'>Affiliates' vote</MenuItem>
                            <MenuItem value='DELEGATES_VOTE'>Delegates' vote</MenuItem>
                        </CustomDropdown>

                        <hr style={{ marginBlock: '10px' }} />

                        <CustomDropdown
                            id={'committeeElections'}
                            name={'committeeElections'}
                            label={'Committee elections'}
                            value={formik.values.committeeElections}
                            onChange={formik.handleChange}
                            error={formik.touched.committeeElections && Boolean(formik.errors.committeeElections)}
                            helperText={formik.touched.committeeElections && formik.errors.committeeElections}
                        >

                            <MenuItem value='CHAIRPERSON_ELECTS'>Chairperson elects</MenuItem>
                            <MenuItem value='AFFILIATES_VOTE'>Affiliates' vote</MenuItem>
                            <MenuItem value='AFFILIATES_VOTE_BALLOTAGE'>Affiliates' vote (ballotage)</MenuItem>
                            <MenuItem value='DELEGATES_VOTE'>Delegates' vote</MenuItem>
                            <MenuItem value='DELEGATES_VOTE_BALLOTAGE'>Delegates' vote (ballotage)</MenuItem>
                        </CustomDropdown>

                        {// En caso de que el presidente elija a los miembros del comité, no se muestra la opción de término de cargo en el comité, puesto que todo depende el presidente
                            formik.values.committeeElections !== 'CHAIRPERSON_ELECTS' && formik.values.committeeElections !== '' &&
                            <CustomDropdown
                                id={'committeeTermOfOffice'}
                                name={'committeeTermOfOffice'}
                                label={'Term of office'}
                                value={formik.values.committeeTermOfOffice}
                                onChange={formik.handleChange}
                                error={formik.touched.committeeTermOfOffice && Boolean(formik.errors.committeeTermOfOffice)}
                                helperText={formik.touched.committeeTermOfOffice && formik.errors.committeeTermOfOffice}
                            >
                                <MenuItem value='SIX_MONTHS'>Six months</MenuItem>
                                <MenuItem value='TWELVE_MONTHS'>Twelve months</MenuItem>
                                <MenuItem value='EIGHTEEN_MONTHS'>Eighteen months</MenuItem>
                                <MenuItem value='TWENTY_FOUR_MONTHS'>Twenty-four months</MenuItem>
                            </CustomDropdown>
                        }

                        <CustomDropdown
                            id={'committeeRemoval'}
                            name={'committeeRemoval'}
                            label={'Committee removal'}
                            value={formik.values.committeeRemoval}
                            onChange={formik.handleChange}
                            error={formik.touched.committeeRemoval && Boolean(formik.errors.committeeRemoval)}
                            helperText={formik.touched.committeeRemoval && formik.errors.committeeRemoval}
                        >
                            {
                                /* No queremos que miembros del comité puedan ser destituidos por el presidente y que el presidente pueda ser destituido por el comité a la vez */
                                formik.values.chairpersonRemoval !== 'COMMITTEE_DECISION' &&
                                <MenuItem value='CHAIRPERSON_DECISION'>Chairperson decision</MenuItem>
                            }

                            <MenuItem value='AFFILIATES_DECISION'>Affiliates' decision</MenuItem>
                            <MenuItem value='DELEGATES_DECISION'>Delegates' decision</MenuItem>
                        </CustomDropdown>

                        <hr style={{ marginBlock: '10px' }} />

                        <CustomDropdown
                            id={'electionOfElectoralCandidates'}
                            name={'electionOfElectoralCandidates'}
                            label={'Election of electoral candidates'}
                            value={formik.values.electionOfElectoralCandidates}
                            onChange={formik.handleChange}
                            error={formik.touched.electionOfElectoralCandidates && Boolean(formik.errors.electionOfElectoralCandidates)}
                            helperText={formik.touched.electionOfElectoralCandidates && formik.errors.electionOfElectoralCandidates}
                        >
                            <MenuItem value='CHAIRPERSON_ELECTS'>Chairperson elects</MenuItem>
                            <MenuItem value='AFFILIATES_VOTE'>Affiliates' vote</MenuItem>
                            <MenuItem value='AFFILIATES_VOTE_BALLOTAGE'>Affiliates' vote (ballotage)</MenuItem>
                            <MenuItem value='DELEGATES_VOTE'>Delegates' vote</MenuItem>
                            <MenuItem value='DELEGATES_VOTE_BALLOTAGE'>Delegates' vote (ballotage)</MenuItem>
                        </CustomDropdown>

                        <hr style={{ marginBlock: '10px' }} />

                        <CustomDropdown
                            id={'coalitionBuilding'}
                            name={'coalitionBuilding'}
                            label={'Coalition building'}
                            value={formik.values.coalitionBuilding}
                            onChange={formik.handleChange}
                            error={formik.touched.coalitionBuilding && Boolean(formik.errors.coalitionBuilding)}
                            helperText={formik.touched.coalitionBuilding && formik.errors.coalitionBuilding}
                        >
                            <MenuItem value='CHAIRPERSON_DECISION'>Chairperson decision</MenuItem>
                            <MenuItem value='PARTY_COMMITTEE_DECISION'>Party committee decision</MenuItem>
                            <MenuItem value='AFFILIATES_VOTE'>Affiliates' vote</MenuItem>
                            <MenuItem value='DELEGATES_VOTE'>Delegates' vote</MenuItem>
                        </CustomDropdown>

                        <hr style={{ marginBlock: '10px' }} />

                        <CustomDropdown
                            id={'membership'}
                            name={'membership'}
                            label={'Membership'}
                            value={formik.values.membership}
                            onChange={formik.handleChange}
                            error={formik.touched.membership && Boolean(formik.errors.membership)}
                            helperText={formik.touched.membership && formik.errors.membership}
                        >
                            <MenuItem value='OPEN'>Open</MenuItem>
                            <MenuItem value='BY_CHAIRPERSON_APPROVAL'>By chairperson approval</MenuItem>
                        </CustomDropdown>

                        <CustomDropdown
                            id={'expulsions'}
                            name={'expulsions'}
                            label={'Expulsions'}
                            value={formik.values.expulsions}
                            onChange={formik.handleChange}
                            error={formik.touched.expulsions && Boolean(formik.errors.expulsions)}
                            helperText={formik.touched.expulsions && formik.errors.expulsions}
                        >
                            <MenuItem value='CHAIRPERSONS_DECISION'>Chairpersons' decision</MenuItem>
                            <MenuItem value='COMMITTEE_DECISION'>Committee decision</MenuItem>
                        </CustomDropdown>

                        <hr style={{ marginBlock: '10px' }} />

                        <CustomDropdown
                            id={'changes'}
                            name={'changes'}
                            label={'Changes in internal rules'}
                            value={formik.values.changes}
                            onChange={formik.handleChange}
                            error={formik.touched.changes && Boolean(formik.errors.changes)}
                            helperText={formik.touched.changes && formik.errors.changes}
                        >
                            <MenuItem value='CHAIRPERSONS_DECISION'>Chairpersons' decision</MenuItem>
                            <MenuItem value='COMMITTEE_DECISION'>Committee decision</MenuItem>
                            <MenuItem value='AFFILIATES_DECISION'>Affiliates' decision</MenuItem>
                            <MenuItem value='DELEGATES_DECISION'>Delegates' decision</MenuItem>
                        </CustomDropdown>


                        <div className={styles.titleSection} style={{ paddingTop: '15px' }}>
                            <div className={styles.textAndInfo}>
                                <p className={styles.title}>HEADQUARTERS</p>
                            </div>
                            <hr />
                            <p className={styles.infoText}>The headquarters are located in <b>the city where the founder is located</b> at the time of foundation.</p>
                        </div>

                        <div className={styles.headquartersSection}>
                            <div>
                                <img src={requirementsAndPlace?.whatCityIsItIn?.shieldUrl} />
                                <p>{requirementsAndPlace?.whatCityIsItIn?.name}</p>
                            </div>
                            <div>
                                <img src={requirementsAndPlace?.whatCityIsItIn?.region?.stateOwner?.shieldUrl} />
                                <p>{requirementsAndPlace?.whatCityIsItIn?.region?.stateOwner?.name}</p>
                            </div>
                        </div>

                        <div className={styles.titleSection} style={{ paddingTop: '15px' }}>
                            <div className={styles.textAndInfo}>
                                <p className={styles.title}>INVITE CO-FOUNDERS</p>
                            </div>
                            <hr />
                            <p className={styles.infoText}>The co-founders will be part of the party committee, and will pay part of the arauzita needed for the foundation. <strong>At least three co-founders are required</strong></p>
                            {coFoundersInfo.length + 1 === 3 ? (
                                <p className={styles.infoText}>
                                    3/3 min. coFounders <CheckIcon className={styles.checkIcon} />
                                </p>
                            ) : (
                                <p className={styles.infoText}>
                                    {`${coFoundersInfo.length + 1}/3`} min. coFounders
                                </p>
                            )}
                        </div>

                        <CustomTextField
                            id={'coFounders'}
                            label={'Search co-founders'}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            maxLength={30}
                        />

                        {searchResults.length > 0 &&
                            <UserCoFoundersList
                                setCoFoundersInfo={handleAddCoFounder}
                                searchResults={searchResults}
                                setSearchResults={setSearchResults}
                                setSearchQuery={setSearchQuery}
                            />
                        }

                        {coFoundersInfo.map((cofounder, index) => {
                            return (
                                <div key={index} className={styles.coFoundersSection}>
                                    <div className={styles.avatarContainer}>
                                        <Avatar className={styles.cofounderImage} src={cofounder.profileImageUrl} />
                                        <div>
                                            <p>{cofounder.name}</p>
                                            <p>{cofounder.username}</p>
                                        </div>
                                    </div>
                                    <button type='button' className={styles.overlayButton}>
                                        <CloseIcon onClick={() => handleRemoveCofounder(cofounder?.username)} />
                                    </button>
                                </div>
                            );
                        })}

                        <div className={styles.titleSection} style={{ paddingTop: '15px' }}>
                            <CustomTextArea
                                id={'emailMessage'}
                                name={'emailMessage'}
                                label={'Email message'}
                                value={formik.values.emailMessage}
                                onChange={formik.handleChange}
                                error={formik.touched.emailMessage && Boolean(formik.errors.emailMessage)}
                                helperText={formik.touched.emailMessage && formik.errors.emailMessage}
                                maxLength={400}
                            />
                        </div>

                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name='createOnlyIfAllCoFoundersAccept'
                                        checked={formik.values.createOnlyIfAllCoFoundersAccept}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        sx={{
                                            color: '#808080', // Color cuando no está marcado
                                            '&.Mui-checked': {
                                                color: '#099f5d', // Color cuando está marcado
                                            },
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 28, // Opcional: ajustar tamaño
                                            },
                                        }}
                                    />
                                }
                                label="Create only if all co-founders accept"
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        color: '#808080', // Color del texto del label
                                        fontSize: '0.9rem', // Opcional: ajustar tamaño del texto
                                        fontWeight: 400, // Opcional: ajustar peso de la fuente
                                    }
                                }}
                            />
                        </FormGroup>

                        <div className={styles.submitButtonContainer}>


                            <RequirementsDisplay requirementsAndPlace={requirementsAndPlace} styles={styles} setDisabled={setDisabled} cofoundersInfoLength={coFoundersInfo.length} />
                            {
                                // <p className={styles.infoText}>{requirementsAndPlace?.targetUser?.arauzita}/750 arauzita needed {requirementsAndPlace?.targetUser?.arauzita >= 1500 && <CheckIcon className={styles.checkIcon} />} · Young Idealistic learned {requirementsAndPlace?.knowledge?.youngIdealisticLearned && <CheckIcon className={styles.checkIcon} />} · Not affiliated with another political party {!requirementsAndPlace?.hasAnAssociationWithAPoliticalParty && <CheckIcon className={styles.checkIcon} />} · {requirementsAndPlace?.targetUser?.arauzita}/1 position space needed {requirementsAndPlace?.targetUser?.arauzita >= 1500 && <CheckIcon className={styles.checkIcon} />}</p>
                            }


                            <button
                                type='submit'
                                className={`${styles.submitButton} ${disabled ? styles.disabledButton : ''}`}
                                disabled={disabled}
                            >
                                Create
                            </button>
                        </div>

                    </div>
                </form>
            </div>

            <ProfileImageUploader open={openProfilePhotoModal} close={() => setOpenProfilePhotoModal(false)} form={formik} photo={profileImageFile} />
            <BackgroundImageUploader open={openBackgroundPhotoModal} close={() => setOpenBackgroundPhotoModal(false)} form={formik} photo={backgroundImageFile} />
        </div>
    );
};
