import React, { useEffect, useState } from 'react'
import styles from './explore.module.css'
import SocialRightPart from '../SocialPage/RightPart/SocialRightPart'
import SearchIcon from '@mui/icons-material/Search';
import { Tab, Tabs } from '../../Components/Tabs/Tabs';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { api } from '../../Config/api';
import { useNavigate } from 'react-router-dom';
import { RenderPremiumBadge } from '../Utils/RenderPremiumBadge/RenderPremiumBadge';

export const ExplorePage = () => {

    const [currentTab, setCurrentTab] = useState('Trends');
    const [searchResult, setSearchResults] = useState();
    const [searchQuery, setSearchQuery] = useState('');
    const [abortController, setAbortController] = useState(null);

    const navigate = useNavigate();

    const handleSearch = async (query) => {
        if (abortController) {
            abortController.abort();
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        // TODO: Aquí verificar qué currentTab está activo y hacer la búsqueda correspondiente
        try {
            if (currentTab === 'Players') {
                const response = await api.get(`/api/user/searchUsers?nameOrUsername=${query}`, { signal: newAbortController.signal });
                setSearchResults(response.data);
            }
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('Failed to fetch search results:', error);
            }
        }
    };

    useEffect(() => {
        if (searchQuery.trim()) {
            const delayDebounceFn = setTimeout(() => {
                handleSearch(searchQuery); // Realiza la búsqueda si hay un query
            }, 300);

            return () => clearTimeout(delayDebounceFn);
        } else {
            // Si no hay query, limpia los resultados
            setSearchResults([]);
        }

    }, [searchQuery]);

    return (
        <div className={styles.socialContainer}>
            <div className={styles.feedContainer}>
            <div className={styles.mainContent}>
            <div style={{ border: '1px solid #2c2f32', borderBottom: '0px', minHeight: '100vh' }}>


                <div className={styles.searchBarContainer}>
                    <div className={styles.searchBar}>
                        <div >
                            <SearchIcon className={styles.searchIcon} />
                        </div>
                        <input
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            type="text"
                            placeholder='Search'
                            className=''>
                        </input>


                    </div>
                </div>


                <div className={styles.tabContainer}>
                    <Tabs onTabChange={(tab) => setCurrentTab(tab)} >
                        <Tab label="Trends" image='https://i.imgur.com/RmyJlEp.png' imageUrl='/explore'>
                            <img className={styles.tabImage} src='https://i.imgur.com/XbGwm24.png' />
                            <div className={styles.card}>

                                <div className={styles.trendingItem}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <p className={styles.trendCategoryAndRank}>
                                            1 · Trending
                                        </p>
                                        <MoreHorizIcon className={styles.horizIcon} />
                                    </div>

                                    <p className={styles.name}>
                                        Elecciones España
                                    </p>
                                </div>


                                <div className={styles.trendingItem}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <p className={styles.trendCategoryAndRank}>
                                            2 · Trending
                                        </p>
                                        <MoreHorizIcon className={styles.horizIcon} />
                                    </div>

                                    <p className={styles.name}>
                                        Precios altos
                                    </p>
                                </div>

                                <div className={styles.trendingItem}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <p className={styles.trendCategoryAndRank}>
                                            3 · Trending
                                        </p>
                                        <MoreHorizIcon className={styles.horizIcon} />
                                    </div>

                                    <p className={styles.name}>
                                        Guerra
                                    </p>
                                </div>
                            </div>
                        </Tab>
                        <Tab label="Players">

                            {searchResult != null && searchResult != '' ?
                                <div className={styles.cofoundersList}>

                                    {searchResult?.map((user) => {
                                        // TODO incluir la chapa de verificado
                                        return (
                                            <div className={styles.cofounderItem} key={user?.username} onClick={() => navigate(`/profile/user/${user?.username}`)}>
                                                <div className={styles.cofounderInfo}>
                                                    <img
                                                        src={user?.profileImageUrl}
                                                        alt="Co-founder avatar"
                                                        className={styles.cofounderAvatar}
                                                    />
                                                    <div className={styles.cofounderDetails}>
                                                        <div>
                                                            <p className={styles.name}>{user?.name}</p>
                                                            {RenderPremiumBadge({ item: user?.premiumSubscription })}
                                                        </div>

                                                        <p className={styles.username}>{user?.username}</p>
                                                    </div>
                                                </div>
                                                <div className={styles.aboutContainer}>
                                                    <p className={styles.description}>{user?.about}</p>
                                                </div>

                                            </div>)
                                    })}
                                </div>
                                :
                                <div className={styles.noResultsContainer}>
                                    <p className={styles.noResults}>There are no results for your search</p>
                                </div>}

                        </Tab>
                        <Tab label="Posts">

                        </Tab>
                        <Tab label="States">
                            <div className={styles.noResultsContainer}>
                                <p className={styles.noResults}>There are no results for your search</p>
                            </div>
                        </Tab>
                        <Tab label="Orgs">
                            <div className={styles.noResultsContainer}>
                                <p className={styles.noResults}>There are no results for your search</p>
                            </div>
                        </Tab>
                    </Tabs>
                </div>


            </div>
            </div>
            </div>


            <div className={styles.socialRightPart}>

                <SocialRightPart withSearchBar={false} />

            </div>

        </div>
    )
}
