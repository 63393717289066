import React, { useEffect, useState } from 'react'

import styles from './cityprofile.module.css'

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import LocationCityIcon from '@mui/icons-material/LocationCity';

import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import { useNavigate, useParams } from 'react-router-dom';
import { api, ensureAuthorizationHeader } from '../../../Config/api';

import LoadingRing from '../../../Static/LoadingRingV2.svg'
import { Tab, Tabs } from '../../Tabs/Tabs';
import { TravelModal } from './TravelModal/TravelModal';
import { PieChartComponent } from '../PieChartComponent/PieChartComponent';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { formatValueForPieChart } from '../../../Utils/formatValueForPieChart';
import { flattenObject } from '../../../Utils/flattenObject';

export const CityProfile = () => {
    const navigate = useNavigate();

    const [loadingProfile, setLoadingProfile] = useState(true);

    const { username } = useParams();

    const [cityProfile, setCityProfile] = useState();

    const handleBack = () => navigate(-1);

    const navigateToState = (stateUsername) => navigate(`/profile/state/${stateUsername}`);
    const navigateToRegion = (stateRegion) => navigate(`/profile/region/${stateRegion}`);

    const [openTravelModal, setOpenTravelModal] = useState(false);

    const [popsDistributionsQuarter, setPopsDistributionsQuarter] = useState(1);

    const handleChangePopsDistributionsQuarter = (event) => {

        let quarterValue;

        if (event !== 1) {
            quarterValue = event.target.value;


        } else {
            quarterValue = event;
        }

        setPopsDistributionsQuarter(quarterValue);

        const currentQuarterData = cityProfile?.quarterlyStatistics.find(stat => stat.quarter === event.target.value);


        if (currentQuarterData) {

            // Aquí procesar los datos según tus necesidades
            setPercentageDistribution({
                popType: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allPopTypesDistribution),
                sex: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allSexDistribution),
                superCulture: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allSuperCulturesDistribution),
                culture: processPopsDistributionsData(flattenObject(currentQuarterData?.hitStatistics?.allCulturesDistribution)),
                superBelief: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allSuperBeliefsDistribution),
                belief: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allBeliefsDistribution),
                ageRange: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allAgeDistribution),
                race: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allRacesDistribution),
                qualification: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allQualificationLevelDistribution),
                // Agrega los demás procesamientos según sea necesario
            });


            // Procesar y establecer los datos absolutos
            setAbsoluteDistribution({
                popType: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allPopTypesDistribution),
                sex: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allSexDistribution),
                superCulture: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allSuperCulturesDistribution),
                culture: processAbsolutePopsDistributionsData(flattenObject(currentQuarterData?.hitStatistics?.allCulturesDistribution)),
                superBelief: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allSuperBeliefsDistribution),
                belief: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allBeliefsDistribution),
                ageRange: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allAgeDistribution),
                race: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allRacesDistribution),
                qualification: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allQualificationLevelDistribution),
            });

        }
    };

    const processPopsDistributionsData = (data) => {
        // Filtrar los 9 valores más grandes y agrupar el resto en "Otros"
        const entries = Object.entries(data)
            .filter(([key, value]) => key.endsWith("Percentage") && value > 0) // Filtrar valores mayores a 0 y que sean porcentajes
            .sort((a, b) => b[1] - a[1]) // Ordenar de mayor a menor

        const topEntries = entries.slice(0, 9);
        const othersValue = entries.slice(9).reduce((acc, [_, value]) => acc + value, 0);

        const result = topEntries.map(([label, value], index) => ({
            id: index,
            label: formatValueForPieChart(label),
            value,
        }));

        if (othersValue > 0) {
            result.push({
                id: 9,
                label: "Others",
                value: othersValue,
            });
        }

        return result;
    };

    const processAbsolutePopsDistributionsData = (data) => {
        const entries = Object.entries(data)
            .filter(([key]) => !key.endsWith("Percentage")) // Filtrar las claves que no terminan en Percentage
            .filter(([_, value]) => value > 0) // Filtrar valores mayores a 0
            .sort((a, b) => b[1] - a[1]); // Ordenar de mayor a menor

        const topEntries = entries.slice(0, 9);
        const othersValue = entries.slice(9).reduce((acc, [_, value]) => acc + value, 0);

        const result = topEntries.map(([label, value], index) => ({
            id: index,
            label: formatValueForPieChart(label),
            value,
        }));

        if (othersValue > 0) {
            result.push({
                id: 9,
                label: "Others",
                value: othersValue,
            });
        }

        return result;
    };

    const [percentageDistribution, setPercentageDistribution] = useState({
        popType: [],
        sex: [],
        culture: [],
        superCulture: [],
        belief: [],
        superBelief: [],
        race: [],
        qualification: [],
        ageRange: [],
    });

    const [absoluteDistribution, setAbsoluteDistribution] = useState({
        popType: [],
        sex: [],
        culture: [],
        superCulture: [],
        belief: [],
        superBelief: [],
        race: [],
        qualification: [],
        ageRange: [],
    });


    useEffect(() => {
        const fetchStateProfile = async () => {
            setLoadingProfile(true);
            try {
                // Asegúrate de que el JWT esté configurado en la petición a la api
                ensureAuthorizationHeader();

                const response = await api.get(`/api/v1/city/profile/${username}`);
                setCityProfile(response.data); // Asume que la respuesta tiene una propiedad 'data' que contiene el perfil del estado

            } catch (error) {
                console.error('Error fetching state profile:', error);
            } finally {
                setTimeout(() => {
                    setLoadingProfile(false);
                }, 300)
            }
        };

        fetchStateProfile();
    }, [username]);


    return (
        <div className={styles.mainContent}>
            {loadingProfile


                ?
                <div className={styles.loadingContainer}>
                    <img src={LoadingRing} />
                </div>
                :
                <div style={{ border: '1px solid #2c2f32', minHeight: '100vh' }}>
                    <section className={styles.fixedHeader}>
                        <KeyboardBackspaceIcon className={styles.arrowIcon} onClick={handleBack} />
                        <h1 className={styles.headerTitle}>{cityProfile?.cityName}</h1>
                        <div className={styles.rightContainer}>

                        </div>
                    </section>

                    <img className={styles.backgroundImage} src="https://cdn.pixabay.com/photo/2023/07/25/19/47/milky-way-8149815_640.jpg" alt='' />

                    <div className={styles.profileHeader}>
                        <img className={styles.profileImage} src={cityProfile?.cityShieldUrl} alt='' />
                        <div style={{ display: 'flex', gap: '0.6rem' }}>

                            {cityProfile?.cityUsername !== cityProfile?.whatCityIsThePlayerUsername &&
                                <button onClick={() => setOpenTravelModal(true)} className={styles.logoutButton}>
                                    <FlightTakeoffIcon />
                                    <p><b>Travel</b></p>
                                </button>
                            }

                        </div>
                    </div>

                    <section className={styles.profileInfo}>
                        <div className={styles.userDetails}>
                            <div className={styles.userInfo}>
                                <h1 className={styles.name}>{cityProfile?.cityName}</h1>
                            </div>
                        </div>

                        <div className={styles.userDetails}>
                            <p>{cityProfile?.cityDescription}</p>
                            <div className={styles.userStats}>

                                <div className={styles.userStat}>
                                    <LocationCityIcon className={styles.userStatsIcons} />
                                    <p>{cityProfile?.touristAttraction} tourist attraction</p>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section className={styles.tabContainer}>
                        <Tabs>
                            <Tab label="Profile">
                                <div className={styles.tab}>

                                    <div className={styles.profileElement}>
                                        <p>Region:</p>

                                        <div>

                                            <div className={styles.cityPreview} onClick={() => navigateToRegion(cityProfile?.regionUsername)}>
                                                <div className={styles.imageContainer}>
                                                    <img src={cityProfile?.regionShieldUrl} />
                                                </div>
                                                <p>{cityProfile?.regionName}</p>
                                            </div>


                                        </div>

                                    </div>

                                    {cityProfile?.sisterCities &&

                                        <div className={styles.profileElement}>

                                            <p>Sister cities:</p>

                                            <div className={styles.profileElementContent}>

                                                <div className={styles.cityPreview}>
                                                    <div className={styles.imageContainer}>
                                                        <img src={cityProfile?.autonomyShieldUrl} />
                                                    </div>
                                                    <p>{cityProfile?.autonomyName}</p>
                                                </div>

                                            </div>
                                        </div>
                                    }

                                    <div className={styles.profileElement}>
                                        <p>State:</p>

                                        <div>

                                            <div className={styles.cityPreview} onClick={() => navigateToState(cityProfile?.stateUsername)}>
                                                <div className={styles.imageContainer}>
                                                    <img src={cityProfile?.stateShieldUrl} />
                                                </div>
                                                <p>{cityProfile?.stateName}</p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className={`${styles.profileElement} ${styles.fullWidthElement}`}>

                                        <button className={styles.territorialOrganizationButton}>Show people and organizations</button>


                                    </div>

                                </div>
                            </Tab>
                            <Tab label="Structures">
                                <div>Coming... not soon</div>
                            </Tab>
                            <Tab label="Statistics">
                                <div className={styles.statisticsQuarterAndYear}>
                                    <div className={styles.elementTitle}>
                                        <div className={styles.popsDistributions}>
                                            <p>POPS DISTRIBUTIONS</p>
                                        </div>
                                        <hr />
                                    </div>

                                </div>

                                <div className={styles.quarterDropdown}>
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <InputLabel
                                            id="demo-select-small-label"
                                            sx={{
                                                color: 'white', // Color del label por defecto
                                                '&.Mui-focused': {
                                                    color: '#099f5d', // Cambia el color del label cuando el Select está enfocado
                                                },
                                            }} // Cambia el color del InputLabel a blanco
                                        >
                                            Quarter
                                        </InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={popsDistributionsQuarter}
                                            label="Quarter"
                                            onChange={handleChangePopsDistributionsQuarter}
                                            sx={{
                                                backgroundColor: 'black',
                                                color: 'white', // Cambia el color del texto seleccionado a blanco
                                                '& .MuiSelect-select': {
                                                    backgroundColor: 'black', // Color de fondo del select
                                                    color: 'white', // Color del valor seleccionado
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#767b80', // Borde blanco
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#767b80', // Borde blanco al pasar el mouse
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#767b80', // Borde blanco cuando está enfocado
                                                },
                                                '& .MuiSelect-icon': {
                                                    color: '#767b80', // Cambia el color del icono del "triangulito"
                                                },
                                            }}
                                        >
                                            <MenuItem value={1}>Q1 <span style={{ color: '#767b80' }}><small>2024</small></span></MenuItem>
                                            <MenuItem value={2}>Q2 <span style={{ color: '#767b80' }}><small>2024</small></span></MenuItem>
                                            <MenuItem value={3}>Q3 <span style={{ color: '#767b80' }}><small>2024</small></span></MenuItem>
                                            <MenuItem value={4}>Q4 <span style={{ color: '#767b80' }}><small>2024</small></span></MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className={styles.statisticsContainer}>
                                    <div className={styles.pieChartWrapper}>
                                        <PieChartComponent
                                            name='Pop type'
                                            distributionData={percentageDistribution?.popType}
                                            absoluteNumbersDistributionData={absoluteDistribution?.popType} />
                                    </div>
                                    <div className={styles.pieChartWrapper}>
                                        <PieChartComponent
                                            name='Sex'
                                            distributionData={percentageDistribution?.sex}
                                            absoluteNumbersDistributionData={absoluteDistribution?.sex} />
                                    </div>
                                    <div className={styles.pieChartWrapper}>
                                        <PieChartComponent
                                            name='Cultural group'
                                            distributionData={percentageDistribution?.superCulture}
                                            absoluteNumbersDistributionData={absoluteDistribution?.superCulture} />
                                    </div>
                                    <div className={styles.pieChartWrapper}>
                                        <PieChartComponent
                                            name='Culture'
                                            distributionData={percentageDistribution?.culture}
                                            absoluteNumbersDistributionData={absoluteDistribution?.culture} />
                                    </div>
                                    <div className={styles.pieChartWrapper}>
                                        <PieChartComponent
                                            name='Belief group'
                                            distributionData={percentageDistribution?.superBelief}
                                            absoluteNumbersDistributionData={absoluteDistribution?.superBelief} />
                                    </div>
                                    <div className={styles.pieChartWrapper}>
                                        <PieChartComponent
                                            name='Belief'
                                            distributionData={percentageDistribution?.belief}
                                            absoluteNumbersDistributionData={absoluteDistribution?.belief} />
                                    </div>
                                    <div className={styles.pieChartWrapper}>
                                        <PieChartComponent
                                            name='Race'
                                            distributionData={percentageDistribution?.race}
                                            absoluteNumbersDistributionData={absoluteDistribution?.race} />
                                    </div>
                                    <div className={styles.pieChartWrapper}>
                                        <PieChartComponent
                                            name='Qualification'
                                            distributionData={percentageDistribution?.qualification}
                                            absoluteNumbersDistributionData={absoluteDistribution?.qualification} />
                                    </div>
                                    <div className={styles.pieChartWrapper}>
                                        <PieChartComponent
                                            name='Age range'
                                            distributionData={percentageDistribution?.ageRange}
                                            absoluteNumbersDistributionData={absoluteDistribution?.ageRange} />
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </section>

                    <section>
                        <TravelModal open={openTravelModal} handleClose={() => setOpenTravelModal(false)} />
                    </section>
                </div>
            }


        </div >
    );
};
