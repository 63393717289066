import React, { useEffect, useState } from 'react'
import styles from './emailpage.module.css'
import { useNavigate, useParams } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import WorldOrderLogo from './WorldOrderPWALogo.svg'

import ConfigurationIcon from '../Assets/ConfigurationIcon.svg'
import MagnifyingGlassIcon from '../Assets/MagnifyingGlassIcon.svg'
import InfoIcon from '../Assets/InfoIcon.svg'
import { ChatPreview } from '../../Utils/Components/ChatPreview/ChatPreview';
import { useSelector } from 'react-redux';
import { ProfilesDropdownMenu } from './ProfilesDropdownMenu/ProfilesDropdownMenu';
import { api } from '../../Config/api';
import { formatText } from '../../Utils/capitalizeFirtsLetter';

export const EmailPage = () => {

    const { chatId } = useParams();
    const [chatContent, setChatContent] = useState(null);

    const [emailPreviews, setEmailPreviews] = useState({
        received: [],
        sended: [],
    });

    const [profilesModalOpen, setProfilesModalOpen] = useState(false);

    const { auth } = useSelector(store => store)

    // Perfil actual para recibir el correo, puede ser el de una organization si ocupa un cargo en esta, por defecto se carga el perfil del usuario
    // Los correos a organizations, además de incluir al organization destinatario, incluyen al cargo al que se le envía.
    const [actualProfile, setActualProfile] = useState({
        profileImageUrl: auth?.profileImageUrl,
        name: auth?.name,
        username: auth?.username
    });

    const navigate = useNavigate();

    const handleBackToList = () => {
        navigate(-1);
        setChatContent(null);
        setActualEmail(null);
    };

    // El sender siempre es el id de una organización o de un player, pero el destinatario son dos arrays: uno de ids de organizations y otro de ids de players.
    // Ya que es posible que se envíe un mismo correo a players y organizations a la vez. Si hay más de un destinatario, el addresseeName puede ser 
    // Como senderType tenemos organization, player y administration, dependiendo quién haya enviado el correo, servirá para poder navegar a su perfil
    const correoDeEjemplo = {
        id: '1',
        senderProfileUrl: '@eloestepar',

        // TODO probablemente no haga falta traerlo al front, simplemente consultarlo en el backend
        // Indica si se es el remitente (true) o el destinatario (false)
        sender: false,

        // En caso de que el que lo envía sea el cargo de una organization, aquí se indican datos de la organization y el cargo que ocupa el usuario que lo ha enviado dentro de la misma
        senderOrganizationName: 'Coca Cola Inc.',
        senderOrganizationProfileImageUrl: 'https://pbs.twimg.com/profile_images/1651219278664720384/6PrhZDPe_400x400.jpg',
        senderOrganizationProfileUrl: 'org/company/@theCocaCola',
        senderOrganizationPosition: 'Chief Executive Officer',

        // Datos de una organization de la que se habla en el asunto del correo. Por ejemplo, a la hora de fundar una organization sus primeros datos
        // O para asumir un cargo, el Congreso si sales elegido diputado, o si te eligen para un gabinete de gobierno como ministro, o para un contrato en una empresa,
        // o para adquirir acciones de una empresa, etc.
        organizationTargetName: 'Coca Cola Inc.',
        organizationTargetProfileImageUrl: 'https://pbs.twimg.com/profile_images/1651219278664720384/6PrhZDPe_400x400.jpg',
        organizationTargetProfileUsername: '@CocaColaES',
        organizationTargetProfileUrl: '/profile/org/company/@CocaColaES',

        organizationTargetType: 'POLITICAL_PARTY',

        emailType: 'ORGANIZATION_FOUNDATION',

        senderName: 'Administración',
        senderUsername: '@admin',
        subject: 'Contrato laboral',
        // Contenido por defecto dependiendo el tipo de correo, por ejemplo, si es un contrato laboral: "La organización X desea contar con sus servicios para el puesto de Y"
        content: 'Este es un ejemplo de un chat entre dos jugadores de World Order. Próximamente podrás iniciar conversaciones con jugadores siempre y cuando tú les sigas y ellos te sigan a tí.',
        // Contenido escrito por el player que envía el correo, puede ser para convencer al otro player de hacer algo
        customContent: 'Es una muy buena oferta, no me la rechaces',
        profileImageUrl: 'https://pbs.twimg.com/profile_images/1308769664240160770/AfgzWVE7_400x400.jpg',
        repliedToEmailId: '',

        timestamp: '2023-09-17T10:31:00Z',


        // Indica si se trata de un correo en el que se debe aceptar o rechazar algo, ofertas de contrato, compra-venta, fundación, invitaciones, etc.
        isOptionable: true,
        // Indica si al aceptar se reenviará el correo al supervisor (CEO de la empresa, por ejemplo), para su aprobación final
        reviewNeeded: true,
        // En caso de que isOptionable sea true, indica la opción que ya se ha elegido. Es null si todavía no se ha elegido ninguna o el isOptionable es false
        optionSelected: null,
        // Indica la fecha y hora en la que no se podrá votar ya por expiración
        optionableExpirationTimestamp: '2025-09-17T10:31:00Z',
    };

    // TODO al recargar la página esto no se está poniendo bien el actual profile



    useEffect(() => {

        const initializeComponent = async () => {
            try {
                if (chatId) {

                    const response = await api.get(`/api/ig-email/full-as-individual?uuid=${chatId}`);

                    console.log(response.data)

                    // TODO según el chatId, consultar a la api para el contenido del correo
                    setChatContent(response.data);
                }


            } catch (error) {
                console.error('Failed to fetch foundation requirements:', error);
            }
        };

        initializeComponent();

        // Tomar los datos en base al chatId, en caso de que exista. Si la petición es rechazada, simplemente chatContent será null. Si no hay chatId igualmente será null,
        // y si correctamente recibe el contenido, lo escribirá en chatContent
    }, [chatId])

    useEffect(() => {
        const initializeComponent = async () => {
            try {
                const response = await api.get('/api/ig-email/previews-as-individual');
                console.log(response.data)
                setEmailPreviews(prevState => ({
                    ...prevState,
                    received: response.data,
                }));

            } catch (error) {
                console.error('Failed to fetch foundation requirements:', error);
            }
        };

        initializeComponent();
    }, [])

    // Para abrir o cerrar el modal en el que se ven los perfiles a los que puede cambiar, dependiendo los position que ejerza en organizations
    const changeProfileModal = () => { }


    // Datos del remitente del correo actual que se ha clicado: foto de perfil y nombre
    const [actualEmail, setActualEmail] = useState(null);


    const formatDateTime = (timestamp) => {
        const date = new Date(timestamp);
        const currentYear = new Date().getFullYear();

        // Formato para la fecha
        const dateFormat = {
            month: 'long',
            day: 'numeric',
        };

        // Si el año es diferente al actual, añadimos el año al formato
        if (date.getFullYear() !== currentYear) {
            dateFormat.year = 'numeric';
        }

        // Formateamos la fecha
        const formattedDate = date.toLocaleDateString('en-US', dateFormat);

        // Formato para la hora
        const timeFormat = {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true, // Para AM/PM
        };

        // Formateamos la hora
        const formattedTime = date.toLocaleTimeString('en-US', timeFormat);

        return `${formattedDate} at ${formattedTime}`;
    };

    // Función que determina la ruta y realiza la solicitud
    const handleAcceptOrganizationFoundation = async (accept) => {
        const organizationType = chatContent?.potentialCoFounder?.founding?.organization?.organizationType;

        if (!organizationType) {
            console.error("Organization type is missing");
            return;
        }

        // Determinar la ruta basada en el tipo de organización
        let endpoint;
        if (organizationType === 'POLITICAL_PARTY') {
            endpoint = '/api/founding/found/political-party';
        } else if (organizationType === 'COMPANY') {
            endpoint = '/api/founding/found/company';
        } else {
            console.error(`Unknown organization type: ${organizationType}`);
            return;
        }

        try {
            // Realizar la solicitud PATCH
            const organizationUsername = chatContent?.potentialCoFounder?.founding?.organization?.username;
            const response = await api.patch(endpoint, null, {
                params: {
                    organizationUsername,
                    accept,
                },
            });
            console.log(`Request sent`);
            if (response.status === 200) {
                navigate('/org')
            }
        } catch (error) {
            console.error("Error while sending the request:", error);
        }
    };


    // Indica si se está en el Inbox o en el Sended
    const [activeTab, setActiveTab] = useState('Inbox'); // Estado para el tab activo


    return (
        <div className={styles.pageContainer}>



            <div className={styles.layoutContainer} data-has-chat={Boolean(chatContent).toString()}>

                <div className={styles.chatsList}>

                    <div className={styles.messagesHeader}>
                        <div className={styles.tabsContainer}>
                            <p
                                className={`${styles.tab} ${activeTab === 'Inbox' ? styles.activeTab : ''}`}
                                onClick={() => setActiveTab('Inbox')}
                            >
                                Inbox
                            </p>
                            <p
                                className={`${styles.tab} ${activeTab === 'Sended' ? styles.activeTab : ''}`}
                                onClick={() => setActiveTab('Sended')}
                            >
                                Sended
                            </p>
                        </div>
                        <div>
                            <img src={ConfigurationIcon} />
                            <img src={actualProfile?.profileImageUrl} style={{ height: '35px', cursor: 'pointer' }} onClick={() => setProfilesModalOpen(true)} />

                        </div>
                    </div>

                    <div className={styles.searchBar}>
                        <div >
                            <img src={MagnifyingGlassIcon} />
                        </div>
                        <input type="text" placeholder='Search for emails'>
                        </input>
                    </div>

                    <div className={styles.composeEmail}>
                        <button>
                            Compose email
                        </button>
                    </div>

                    <div className={styles.chatsContent}>
                        {activeTab == 'Inbox' ?
                            emailPreviews.received.map((email, index) => {
                                // Transformamos el email
                                const transformedEmail = {
                                    ...email, // Mantenemos el resto de los atributos del email
                                    sender: email.playerSender || email.organizationSender, // Elegimos el sender
                                    imageUrl: (email.playerSender ? email.playerSender.profileImageUrl : email.organizationSender?.logoUrl), // Renombramos la imagen
                                };

                                // Pasamos el email transformado al componente
                                return (
                                    <ChatPreview
                                        key={index}
                                        chat={transformedEmail}
                                        messages={false}
                                    />
                                );
                            })
                            :
                            emailPreviews.received.map((email, index) => {
                                // Transformamos el email
                                const transformedEmail = {
                                    ...email, // Mantenemos el resto de los atributos del email
                                    sender: email.playerSender || email.organizationSender, // Elegimos el sender
                                    imageUrl: (email.playerSender ? email.playerSender.profileImageUrl : email.organizationSender?.logoUrl), // Renombramos la imagen
                                };

                                // Pasamos el email transformado al componente
                                return (
                                    <ChatPreview
                                        key={index}
                                        chat={transformedEmail}
                                        setActualChat={(chatInfo) => setActualEmail(chatInfo)}
                                        messages={false}
                                    />
                                );
                            })
                        }
                    </div>
                </div>

                <div className={styles.mainContent}>
                    {chatContent ?
                        <div>
                            <div className={styles.chatContentHeader}>
                                <div>
                                    <button
                                        className={styles.backButton}
                                        onClick={handleBackToList}
                                    >
                                        <KeyboardBackspaceIcon className={styles.arrowIcon} />
                                    </button>
                                    <div className={styles.mailTarget} onClick={() => navigate(`/profile/user/${chatContent?.playerSender?.username}`)}>
                                        <img src={chatContent?.playerSender ? chatContent?.playerSender?.profileImageUrl || 'https://static.vecteezy.com/system/resources/previews/009/292/244/original/default-avatar-icon-of-social-media-user-vector.jpg' : WorldOrderLogo} />
                                        <p>{chatContent?.playerSender?.name || 'World Order'}</p>
                                    </div>
                                </div>
                                <button className={styles.infoIcon} onClick={() => alert('Próximamente...')}>
                                    <img src={InfoIcon} alt="info" />
                                </button>
                            </div>
                            {/* Contenido del chat seleccionado */}
                            <div className={styles.chatMessagesContainer}>
                                <div className={styles.chatMessagesWrapper}>
                                    <div className={styles.messagesContainer}>

                                        <p className={styles.subject}>{chatContent?.subject}</p>

                                        {/* El contenido del correo debe ser traducido según el idioma de la interfaz de usuario que tenga el player, salvo
                                        la parte del correo que pueda haber sido escrita directamente por otro player */}
                                        <p>{chatContent?.content}</p>

                                        <div>

                                            {chatContent?.emailType === 'SHALE_OF_SHARES' &&
                                                <p className={styles.subtitle}>Company for sale</p>
                                            }




                                        </div>

                                        {/* A partir de aquí widgets concretos para cada caso */}

                                        {/* Aquí dentro va la city o region en la que está el workspace si se trata de un puesto de trabajo o la city donde está la sede de la company u organization,
                                        y el workspace en cuestión. Se pondrá un icono para representarlo dependiendo el tipo */}
                                        {chatContent?.emailType === 'EMPLOYMENT_CONTRACT' &&
                                            <div className={styles.emailDetails}>

                                                <div className={styles.emailDetail} onClick={() => navigate(`${chatContent?.organizationTargetProfileUrl}`)}>
                                                    <p className={styles.subtitle}>Contracting company</p>
                                                    <div className={styles.organizationTargetContainer} style={{ 'paddingBottom': '10px' }}>
                                                        <img src={chatContent?.organizationTargetProfileImageUrl} style={{ borderRadius: '5px', alignSelf: 'center' }} />
                                                        <div>
                                                            <p><b>{chatContent?.organizationTargetName}</b>,</p>
                                                            <p className={styles.username}>{chatContent?.organizationTargetProfileUsername}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={styles.emailDetail} onClick={() => navigate(`${chatContent?.organizationTargetProfileUrl}`)}>
                                                    <p className={styles.subtitle}>Place of work</p>
                                                    <div className={styles.organizationTargetContainer} style={{ 'paddingBottom': '10px' }}>
                                                        <img src='https://upload.wikimedia.org/wikipedia/commons/4/4e/Escut_de_Val%C3%A8ncia.svg' style={{ borderRadius: '5px', alignSelf: 'center' }} />
                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                            <p><b>Valencia</b></p>
                                                            <p className={styles.username}>Reino de España</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={styles.emailDetail} onClick={() => navigate(`${chatContent?.organizationTargetProfileUrl}`)}>
                                                    <p className={styles.subtitle}>Salary</p>
                                                    <div className={styles.organizationTargetContainer} style={{ 'paddingBottom': '10px' }}>
                                                        {/* Escudo del Banco Central que emite la moneda o el logotipo de la criptomoneda, de ser el caso*/}
                                                        <img src='https://upload.wikimedia.org/wikipedia/commons/7/7d/Escudo_de_Espa%C3%B1a_%28variant%29.svg' style={{ borderRadius: '5px', alignSelf: 'center' }} />
                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                            <p><b>1800 EUR</b></p>
                                                            <p className={styles.username}>Weekly</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {/* Aquí va el método de pago, el número de cuenta y el logo del banco si se trata de una cuenta bancaria, así como la divisa en la que se realiza la transferencia*/}
                                        {chatContent?.emailType === 'SHALE_OF_SHARES' &&
                                            <div>
                                                <p className={styles.subtitle}>Payment method</p>
                                                <div className={styles.organizationTargetContainer} onClick={() => navigate(`${chatContent?.organizationTargetProfileUrl}`)} style={{ 'paddingBottom': '10px' }}>
                                                    <img src={chatContent?.organizationTargetProfileImageUrl} style={{ borderRadius: '5px', alignSelf: 'center' }} />
                                                    <div>
                                                        <p><b>{chatContent?.organizationTargetName}</b>,</p>
                                                        <p className={styles.username}>{chatContent?.organizationTargetProfileUsername}</p>
                                                    </div>
                                                </div>
                                                <p>Cantidad y moneda</p>
                                            </div>
                                        }

                                        {chatContent?.emailType === 'ORGANIZATION_FOUNDATION' &&
                                            <div className={styles.emailDetails}>

                                                <div className={styles.emailDetail} onClick={() => navigate(`${chatContent?.potentialCoFounder?.profileUrl}`)}>
                                                    <p className={styles.subtitle}>{formatText(chatContent?.potentialCoFounder?.founding?.organization?.organizationType)} to be founded</p>
                                                    <div className={styles.organizationTargetContainer} style={{ 'paddingBottom': '10px' }}>
                                                        <img src={chatContent?.potentialCoFounder?.founding?.organization?.logoUrl} style={{ borderRadius: '5px', alignSelf: 'center' }} />
                                                        <div>
                                                            <p><b>{chatContent?.potentialCoFounder?.founding?.organization?.name}</b></p>
                                                            <p className={styles.username}>{chatContent?.potentialCoFounder?.founding.organization?.username}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Redirigirá a una lista con los players y organizations invitados a cofundar la organization. Si se trata de una Company pondrá datos adicionales de acciones e inversión inicial de cada socio */}
                                                <div className={styles.emailDetail} onClick={() => navigate(`/org/founding/${chatContent?.potentialCoFounder?.founding?.organization?.username}`)} style={{ alignContent: 'center' }}>
                                                    <p className={styles.subtitle}>More foundation info</p>
                                                    <div className={styles.organizationTargetContainer} style={{ 'paddingBottom': '10px', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                                        {/* Aquí iterar sobre el array de url de fotos de perfil de posibles cofundadores */}
                                                        {chatContent?.potentialCoFounder?.founding?.potentialCoFounders?.slice(0, 3).map((coFounder, index) => (
                                                            <img
                                                                key={index} // Asegúrate de usar una `key` única, aquí usamos `index` como ejemplo
                                                                src={coFounder.imageUrl} // Accede correctamente al atributo `imageUrl`
                                                                alt="Co-founder profile"
                                                                style={{ borderRadius: '30px', alignSelf: 'center' }}
                                                            />
                                                        ))}

                                                    </div>
                                                </div>

                                                {chatContent?.organizationTargetType === 'COMPANY' &&
                                                    <div className={styles.emailDetail} onClick={() => navigate(`${chatContent?.organizationTargetProfileUrl}`)}>
                                                        <p className={styles.subtitle}>Initial investment</p>
                                                        <div className={styles.organizationTargetContainer} style={{ 'paddingBottom': '10px' }}>
                                                            <img src='https://pbs.twimg.com/profile_images/1676225519501582338/BvttXyfZ_400x400.png' style={{ borderRadius: '5px', alignSelf: 'center' }} />
                                                            <div>
                                                                <p><b>150,530 USD</b></p>
                                                                <p className={styles.username}>IBAN ES123456</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                            </div>


                                        }


                                        <p>{chatContent?.customContent}</p>


                                        {chatContent?.emailType === 'ORGANIZATION_FOUNDATION' &&

                                            <div className={styles.optionsContainer}>
                                                <p className={styles.deadline}>Deadline: {formatDateTime(chatContent?.potentialCoFounder?.founding?.deadline)}</p>
                                                <div className={styles.optionsButtons}>

                                                    <button
                                                        disabled={chatContent?.potentialCoFounder?.accepted !== null}
                                                        className={`${styles.decline} ${(chatContent?.potentialCoFounder?.accepted === null ||
                                                            chatContent?.potentialCoFounder?.accepted === false) &&
                                                            (new Date(chatContent?.potentialCoFounder?.founding?.deadline) > new Date())
                                                            ? styles.optionSelectable
                                                            : styles.optionUnselectable
                                                            }`}
                                                        onClick={() => handleAcceptOrganizationFoundation(false)}
                                                    >
                                                        Decline
                                                    </button>

                                                    <button
                                                        disabled={chatContent?.potentialCoFounder?.accepted !== null}
                                                        className={`${styles.accept} ${(chatContent?.potentialCoFounder?.accepted === null ||
                                                            chatContent?.potentialCoFounder?.accepted === true) &&
                                                            (new Date(chatContent?.potentialCoFounder?.founding?.deadline) > new Date())
                                                            ? styles.optionSelectable
                                                            : styles.optionUnselectable
                                                            }`}
                                                        onClick={() => handleAcceptOrganizationFoundation(true)}
                                                    >
                                                        {chatContent?.reviewNeeded ? 'Send for review' : 'Accept'}
                                                    </button>

                                                </div>
                                            </div>


                                        }


                                        <div className={styles.organizationAndUserSender}>
                                            <p className={styles.dateTime}>{formatDateTime(chatContent?.dateTime)}</p>
                                            {chatContent?.organizationSender &&

                                                <div className={styles.signatureContainer} onClick={() => navigate(`/${chatContent?.senderOrganizationProfileUrl}`)} style={{ 'paddingBottom': '10px' }}>
                                                    <img src={chatContent?.senderOrganizationProfileImageUrl} style={{ borderRadius: '5px', alignSelf: 'center' }} />
                                                    <div>
                                                        <p>{chatContent?.senderOrganizationName},</p>
                                                        <p><b>{chatContent?.senderOrganizationPosition}</b></p>
                                                    </div>
                                                </div>
                                            }

                                            <div className={styles.signatureContainer} onClick={() => chatContent?.playerSender?.username && navigate(`/profile/user/${chatContent?.playerSender?.username}`)}>
                                                <img src={chatContent?.playerSender ? chatContent?.playerSender?.profileImageUrl || 'https://static.vecteezy.com/system/resources/previews/009/292/244/original/default-avatar-icon-of-social-media-user-vector.jpg' : WorldOrderLogo} />
                                                <div>
                                                    <p>{chatContent?.playerSender?.name || 'World Order'},</p>
                                                    <p className={styles.signature}>{chatContent?.playerSender?.name || 'World Order'}</p>
                                                </div>
                                            </div>
                                        </div>




                                        {/* Dependiendo el tipo de correo, puede haber unos botones de aceptar o rechazar, 
                                        que envían un correo de vuelta al remitente*/}


                                    </div>
                                </div>
                            </div>

                        </div>
                        :
                        <div className={styles.noChatSelected}>
                            <div>
                                <p className={styles.selectAMessage}>Select an email</p>
                                <p className={styles.noChatSelectedDescription}>Choose from your existing mails, start a<br />new one, or just keep smoking.</p>
                                <button>New email</button>
                            </div>
                        </div>
                    }

                </div>
            </div>

            <ProfilesDropdownMenu isOpen={profilesModalOpen} onClose={() => setProfilesModalOpen(false)}>
                <div>

                </div>
                <p>prueba</p>
            </ProfilesDropdownMenu>
        </div>
    );
};