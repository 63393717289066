import * as React from 'react';
import Modal from '@mui/material/Modal';
import { useFormik } from 'formik';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import styles from './profilemodal.module.css'
import { updateProfile } from '../../../Store/PlayerProfile/Action';
import { dataUrlToFile } from './dataUrlToFile';
import { CustomTextField } from '../../../Utils/Components/CustomTextField';
import { CustomTextArea } from '../../../Utils/Components/CustomTextArea';
import { CustomDropdown } from '../../../Utils/Components/CustomDropdown';

import CloseIcon from '../../Assets/CloseIcon.svg'
import ProfileImageUploader from '../../../Utils/Components/ImageCropResize/ProfileImageUploader';
import BackgroundImageUploader from '../../../Utils/Components/ImageCropResize/Background/BackgroundImageUploader';
import { Avatar } from '@mui/material';


export default function ProfileModal({ open, handleClose }) {
  // const [open, setOpen] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const dispatch = useDispatch();

  const { ownPlayerProfile } = useSelector(store => store.playerProfile);


  const handleSubmit = async (values) => {

    handleClose();

    const formData = new FormData();

    // Convertimos en File la URI de la imagen cropeada y redimensionada, ya que habíamos pasado el formik por parámetro para que esa uri se asignara.
    if (values.profileImage) {
      const profileImageFile = dataUrlToFile(formik.values.profileImage, 'profileImage.jpeg')
      formData.append('profileImageFile', profileImageFile);
    }

    if (values.backgroundImage) {
      const backgroundImageFile = dataUrlToFile(formik.values.backgroundImage, 'backgroundImage.jpeg')
      formData.append('backgroundImageFile', backgroundImageFile);
    }

    // 1. Preparar el objeto de datos excluyendo las imágenes
    // 1. Preparar el objeto de datos excluyendo las imágenes y reagrupando las ideologías
    const formDataObject = {
      name: values.name,
      about: values.about,
      ideology: {
        mainIdeology: values.mainIdeology,
        secondaryIdeology: values.secondaryIdeology,
        economicIdeology: values.economicIdeology,
        formOfGovernmentIdeology: values.formOfGovernmentIdeology,
        powerStructureIdeology: values.powerStructureIdeology
      }
    };

    // 2. Convertir los datos del formulario a JSON y añadirlo como un blob
    formData.append(
      'profileData',
      new Blob([JSON.stringify(formDataObject)], {
        type: 'application/json'
      })
    );

    dispatch(updateProfile(formData));

  }


  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .min(3, 'Minimum three characters')
      .max(15, 'Maximum fifteen characters'),
    about: Yup.string()
      .max(160, 'Maximum one hundred and sixty characters'),
  });


  const formik = useFormik({
    initialValues: {
      name: '',
      about: '',
      backgroundImage: '',
      profileImage: '',
      mainIdeology: '',
      secondaryIdeology: '',
      economicIdeology: '',
      formOfGovernmentIdeology: '',
      powerStructureIdeology: '',
    },
    validationSchema,
    onSubmit: handleSubmit
  })

  React.useEffect(() => {

    if (ownPlayerProfile) {
      formik.setValues({
        name: ownPlayerProfile?.targetUser?.name || null,
        about: ownPlayerProfile?.targetUser?.about || null,
        backgroundImage: ownPlayerProfile?.targetUser?.backgroundImageUrl || null,
        profileImage: ownPlayerProfile?.targetUser?.profileImageUrl || null,
        mainIdeology: ownPlayerProfile?.ideology?.mainIdeology || null,
        secondaryIdeology: ownPlayerProfile?.ideology?.secondaryIdeology || null,
        economicIdeology: ownPlayerProfile?.ideology?.economicIdeology || null,
        formOfGovernmentIdeology: ownPlayerProfile?.ideology?.formOfGovernmentIdeology || null,
        powerStructureIdeology: ownPlayerProfile?.ideology?.powerStructureIdeology || null,
      });
    }
  }, [ownPlayerProfile]);


  const [openProfilePhotoModal, setOpenProfilePhotoModal] = useState(false);
  const [openBackgroundPhotoModal, setOpenBackgroundPhotoModal] = useState(false);

  const [backgroundImageFile, setBackgroundImageFile] = useState(null);
  // Foto extraído directamente de los archivos del usuario, falta refinar, extraer URL y asignársela al valor de formik
  const [profileImageFile, setProfileImageFile] = useState(null);


  // Maneja la carga de la nueva imagen de fondo
  const handleUploadBackgroundImage = (event) => {

    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setBackgroundImageFile(file);
      setOpenBackgroundPhotoModal(true);
      //formik.setFieldValue('backgroundImage', url); // Actualiza el valor en formik
    }
  };

  // Abrir el input file cuando se hace clic en la foto de perfil
  const handleBackgroundImageClick = () => {
    document.getElementById('backgroundImageInput').click();
  };

  // Maneja la carga de la nueva imagen de perfil
  const handleUploadProfileImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImageFile(file);
      setOpenProfilePhotoModal(true);
      // formik.setFieldValue('profileImage', url); // Actualiza el valor en formik
    }
  };


  // Abrir el input file cuando se hace clic en la imagen
  const handleProfileImageClick = () => {
    // Primero activamos el input file
    document.getElementById('profileImageInput').click();
  };



  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(105, 161, 136, 0.2)' } } }}
        className={styles.modalContainer}
      >
        <div className={styles.uploader}>

          <form onSubmit={formik.handleSubmit}>

            <div className={styles.modalHeader}>
              <div>
                <button className={styles.goBack} onClick={handleClose}>
                  <img src={CloseIcon} alt="close" />
                </button>
                <p>Edit profile</p>
              </div>
              <button className={styles.saveButton} type='submit'>Save</button>
            </div>

            <div className={styles.backgroundImageContainer}>
              <img
                className={styles.backgroundImage}
                src={formik.values.backgroundImage != null ? formik.values.backgroundImage : 'https://cdn.pixabay.com/photo/2023/07/25/19/47/milky-way-8149815_640.jpg'}
                alt=''
                onClick={handleBackgroundImageClick}
              />

              {/* Input de tipo file oculto para el backgroundImage */}
              <input
                type="file"
                id="backgroundImageInput"
                className={styles.imageInput}
                onChange={handleUploadBackgroundImage}
                accept="image/*"
              />
            </div>

            <div className={styles.profileImageContainer}>
              <Avatar
                src={formik.values.profileImage}
                className={styles.profileImage}
                alt="Profile"
                onClick={handleProfileImageClick}
              />

              {/* Input de tipo file oculto */}
              <input
                type="file"
                id="profileImageInput"
                className={styles.imageInput}
                onChange={handleUploadProfileImage}
                accept="image/*"
              />

            </div>


            <div className={styles.formContainer}>

              <CustomTextField
                id={'name'}
                name={'name'}
                label={'Name'}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                maxLength={15}
              />

              <CustomTextArea
                id={'about'}
                name={'about'}
                label={'About'}
                value={formik.values.about}
                onChange={formik.handleChange}
                error={formik.touched.about && Boolean(formik.errors.about)}
                helperText={formik.touched.about && formik.errors.about}
                maxLength={160}
              />

              <CustomDropdown
                id={'mainIdeology'}
                name={'mainIdeology'}
                label={'Main ideology'}
                value={formik.values.mainIdeology}
                onChange={formik.handleChange}
                error={formik.touched.mainIdeology && Boolean(formik.errors.mainIdeology)}
                helperText={formik.touched.mainIdeology && formik.errors.mainIdeology}
              >
                <MenuItem value='CONSERVATISM'>Conservatism</MenuItem>
                <MenuItem value='SOCIAL_DEMOCRACY'>Social Democracy</MenuItem>
                <MenuItem value='LIBERALISM'>Liberalism</MenuItem>
                <MenuItem value='COMMUNISM'>Communism</MenuItem>
                <MenuItem value='ANARCHISM'>Anarchism</MenuItem>
                <MenuItem value='FASCISM'>Fascism</MenuItem>
              </CustomDropdown>


              <CustomDropdown
                id={'secondaryIdeology'}
                name={'secondaryIdeology'}
                label={'Secondary ideology'}
                value={formik.values.secondaryIdeology}
                onChange={formik.handleChange}
                error={formik.touched.secondaryIdeology && Boolean(formik.errors.secondaryIdeology)}
                helperText={formik.touched.secondaryIdeology && formik.errors.secondaryIdeology}
              >
                <MenuItem value='NATIONALISM'>Nationalism</MenuItem>
                <MenuItem value='REGIONALISM'>Regionalism</MenuItem>
                <MenuItem value='LIBERTARIANISM'>Libertarianism</MenuItem>
                <MenuItem value='MERITOCRACY'>Meritocracy</MenuItem>
                <MenuItem value='TECHNOCRACY'>Technocracy</MenuItem>
                <MenuItem value='ENVIRONMENTALISM'>Environmentalism</MenuItem>
                <MenuItem value='INTERNATIONALISM'>Internationalism</MenuItem>
                <MenuItem value='SYNDICALISM'>Syndicalism</MenuItem>
                <MenuItem value='FEMINISM'>Feminism</MenuItem>
              </CustomDropdown>

              <CustomDropdown
                id={'economicIdeology'}
                name={'economicIdeology'}
                label={'Economic ideology'}
                value={formik.values.economicIdeology}
                onChange={formik.handleChange}
                error={formik.touched.economicIdeology && Boolean(formik.errors.economicIdeology)}
                helperText={formik.touched.economicIdeology && formik.errors.economicIdeology}
              >
                <MenuItem value='LAISSEZ_FAIRE'>Laissez Faire</MenuItem>
                <MenuItem value='KEYNESIANISM'>Keynesianism</MenuItem>
                <MenuItem value='CORPORATISM'>Corporatism</MenuItem>
                <MenuItem value='PLANNED_ECONOMY'>Planned Economy</MenuItem>
              </CustomDropdown>

              <CustomDropdown
                id={'formOfGovernmentIdeology'}
                name={'formOfGovernmentIdeology'}
                label={'Form of government preference'}
                value={formik.values.formOfGovernmentIdeology}
                onChange={formik.handleChange}
                error={formik.touched.formOfGovernmentIdeology && Boolean(formik.errors.formOfGovernmentIdeology)}
                helperText={formik.touched.formOfGovernmentIdeology && formik.errors.formOfGovernmentIdeology}
              >
                <MenuItem value='MONARCHISM'>Monarchism</MenuItem>
                <MenuItem value='THEOCRACY'>Theocracy</MenuItem>
                <MenuItem value='REPUBLICANISM'>Republicanism</MenuItem>
                <MenuItem value='ONE_PARTY_SYSTEM'>One Party System</MenuItem>
                <MenuItem value='MILITARY_DICTATORSHIP'>Military dictatorship</MenuItem>
              </CustomDropdown>

              <CustomDropdown
                id={'powerStructureIdeology'}
                name={'powerStructureIdeology'}
                label={'Power structure preference'}
                value={formik.values.powerStructureIdeology}
                onChange={formik.handleChange}
                error={formik.touched.powerStructureIdeology && Boolean(formik.errors.powerStructureIdeology)}
                helperText={formik.touched.powerStructureIdeology && formik.errors.powerStructureIdeology}
              >
                <MenuItem value='UNITARY_STATE'>Unitary State</MenuItem>
                <MenuItem value='FEDERALISM'>Federalism</MenuItem>
              </CustomDropdown>

            </div>

          </form>
        </div>
      </Modal>

      <ProfileImageUploader open={openProfilePhotoModal} close={() => setOpenProfilePhotoModal(false)} form={formik} photo={profileImageFile} />
      <BackgroundImageUploader open={openBackgroundPhotoModal} close={() => setOpenBackgroundPhotoModal(false)} form={formik} photo={backgroundImageFile} />
    </div>
  );
}