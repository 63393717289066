import { useNavigate } from 'react-router-dom';
import styles from './marketpage.module.css'

export const MarketPage = () => {

  const navigate = useNavigate();

  return (
    <div className={styles.pageContainer}>

      <div className={styles.mainContent}>


        <div className={styles.sidebar}>
          <p><i>Si yo te debo una libra, tengo un problema; pero si te debo un millón, el problema es tuyo.</i></p>
          <p><a href='https://es.wikipedia.org/wiki/John_Maynard_Keynes' target='_blank' rel='noopener noreferrer'><strong>- John Maynard Keynes -</strong></a></p>
        </div>


        <div className={styles.marketContainer}>

          <div className={styles.market}>
            <div className={styles.titleAndRequiredLevel}>
              <p className={styles.skillTitle}>B2B platform</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Nothing better than plastic trinkets to destroy the national industry by importing products manufactured with perhaps too cheap labor.</p>
              <p>Connect with other companies in a dynamic digital marketplace where you can buy and sell products efficiently. Establish strategic alliances,
                negotiate competitive prices and expand your business opportunities in a safe and transparent environment designed to maximize business growth.</p>
              <a href='https://en.wikipedia.org/wiki/Trade'><strong>Trade</strong> (Wikipedia)</a>
            </div>
            <div className={styles.buttons}>

              <button
                className={styles.learningButton}
                onClick={() => navigate('b2b')}
              >
                Go to section
              </button>

            </div>
          </div>

          <div className={styles.market}>
            <div className={styles.titleAndRequiredLevel}>
              <p className={styles.skillTitle}>Forex market</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Do you need foreign currency to invest in setting up your next factory in an underdeveloped country with non-existent labor rights? This is your market.
                Explore a currency market where you can buy and sell currencies through offers posted by banks and financial institutions.
                Institutions can participate in private Forex networks and platforms,
                collaborating and competing with other financial entities to set exchange rates and facilitate trades in a dynamic and decentralized environment.</p>
              <a href='https://en.wikipedia.org/wiki/Foreign_exchange_market'><strong>Foreign exchange market</strong> (Wikipedia)</a>
            </div>
            <div className={styles.buttons}>

              <button
                className={styles.learningButton}
                onClick={() => navigate('forex')}
              >
                Go to section
              </button>

            </div>
          </div>

          <div className={styles.market}>
            <div className={styles.titleAndRequiredLevel}>
              <p className={styles.skillTitle}>Cryptocurrency market</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Looking for a place to speculate on digital currencies that are likely to change value as quickly as your financial decisions? </p>

              <p>Enter the cryptocurrency market,
                where you can buy and sell volatile tokens in an environment filled with promises of quick riches and dubious opportunities.
                Enjoy the chaos as you navigate an ecosystem where crypto gurus and decentralized platforms mingle in an exciting game of high volatility and low collateral.</p>
              <a href='https://en.wikipedia.org/wiki/Cryptocurrency'><strong>Cryptocurrency</strong> (Wikipedia)</a>
            </div>
            <div className={styles.buttons}>

              <button
                className={styles.learningButton}
                onClick={() => navigate('crypto')}
              >
                Go to section
              </button>

            </div>
          </div>

          <div className={styles.market}>
            <div className={styles.titleAndRequiredLevel}>
              <p className={styles.skillTitle}>Stock market</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Ready to put your money where your mouth is in companies that seem to change strategy as often as they change CEOs? Dive into the stock market,
                where you can buy and sell stakes in companies that fluctuate in value based on news and market movements.
              </p>
              <p>Take advantage of opportunities to trade stocks, diversify your portfolio,
                and explore market reactions to corporate events - all while managing the risk of seeing your investments rise and fall unpredictably.</p>
              <a href='https://en.wikipedia.org/wiki/Stock_market'><strong>Stock market</strong> (Wikipedia)</a>
            </div>
            <div className={styles.buttons}>

              <button
                className={styles.learningButton}
                onClick={() => navigate('stock')}
              >
                Go to section
              </button>

            </div>
          </div>

          <div className={styles.market}>
            <div className={styles.titleAndRequiredLevel}>
              <p className={styles.skillTitle}>Bond market</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>In the bond market, you can invest in debt issued by entities that promise to pay you back your money with interest, as long as they keep their promises.</p>
              <p>Buy and sell bonds while managing credit risk and financial stability.
                Enjoy the apparent security of regular payments, while the real world reminds you that even bond issuers can fail to keep their promises.</p>
              <a href='https://en.wikipedia.org/wiki/Bond_market'><strong>Bond market</strong> (Wikipedia)</a>
            </div>
            <div className={styles.buttons}>

              <button
                className={styles.learningButton}
                onClick={() => navigate('bond')}
              >
                Go to section
              </button>

            </div>
          </div>

          <div className={styles.market}>
            <div className={styles.titleAndRequiredLevel}>
              <p className={styles.skillTitle}>Derivatives market</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>In the derivatives market, you can play with contracts that bet on the future of assets you don’t even own, all with the promise of huge gains or resounding losses.</p>
              <p>Buy and sell options and futures, as you venture into a world where speculation and bets on asset performance are mixed with a dose of uncertainty.
                Experience the thrill of trading in a market full of complexities, where the risk is as high as the promised rewards.</p>
              <a href='https://en.wikipedia.org/wiki/Derivative_(finance)'><strong>Derivative</strong> (Wikipedia)</a>
            </div>
            <div className={styles.buttons}>

              <button
                className={styles.learningButton}
                onClick={() => navigate('derivative')}
              >
                Go to section
              </button>

            </div>
          </div>



        </div>
      </div>

    </div>
  );
};