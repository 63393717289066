import React from 'react'
import SocialRightPart from '../SocialPage/RightPart/SocialRightPart'
import { Routes, Route, useMatch } from 'react-router-dom'
import PlayerProfile from './PlayerProfile/PlayerProfile'

import styles from './profilecontainer.module.css'
import StateProfile from './StateProfile/StateProfile'
import { RegionProfile } from './RegionProfile/RegionProfile'
import { CityProfile } from './CityProfile/CityProfile'
import { PoliticalPartyProfile } from './PoliticalPartyProfile/PoliticalPartyProfile'

const ProfileContainer = () => {

  return (

    <div className={styles.socialContainer}>

      <div className={styles.feedContainer}>


        <Routes>
          <Route path="/org/:username" element={<p>En desarrollo</p>} />
          <Route path="/org/pp/:username" element={<PoliticalPartyProfile />} />
          <Route path="/user/:username" element={<PlayerProfile />} />
          <Route path="/state/:username" element={<StateProfile />} />
          <Route path="/region/:username" element={<RegionProfile />} />
          <Route path="/city/:username" element={<CityProfile />} />
        </Routes>


      </div>

      <div className={styles.socialRightPart}>

        <SocialRightPart />

      </div>

    </div>



  );


}

export default ProfileContainer