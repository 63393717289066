import React from 'react';
import GavelIcon from '@mui/icons-material/Gavel';
import styles from './organizationstatus.module.css';

const OrganizationStatus = ({ status }) => {
  const getStatusClass = () => {
    switch (status) {
      case 'LEGAL':
        return styles.legalStatus;
      case 'ILLEGAL':
        return styles.illegalStatus;
      case 'UNDEFINED':
        return styles.undefinedStatus;
      default:
        return '';
    }
  };

  return (
    <div className={styles.userStat}>
      <GavelIcon className={`${styles.legalityIcon} ${getStatusClass()}`} />
      <p className={getStatusClass()}>
        {status === 'LEGAL' && 'Legal'}
        {status === 'ILLEGAL' && 'Illegal'}
        {status === 'UNDEFINED' && 'Undefined'}
      </p>
    </div>
  );
};

export default OrganizationStatus;
