import GreenPremiumBadge from './static/PremiumBadge.png';
import GoldenPremiumBadge from './static/GoldenPremiumBadge.png';
import styles from './premiumBadge.module.css';

export const RenderPremiumBadge = ({ item }) => {
    if (item?.activeSubscriptionType === 'GREEN') {
      return <img className={styles.premiumBadge} alt='' src={GreenPremiumBadge} />;
    } else if (item?.activeSubscriptionType === 'GOLD') {
      return <img className={styles.premiumBadge} alt='' src={GoldenPremiumBadge} />;
    }
  };