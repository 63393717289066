import React, { useState, useCallback, useEffect, useRef } from "react";
import Cropper from "react-easy-crop";
import Resizer from "react-image-file-resizer";
import { getCroppedImg } from "./cropImageHelper";
import styles from './profileimageuploader.module.css'
import { Modal } from "@mui/material";
import BackArrowIcon from '../../../Components/Assets/BackArrow.svg'

const ProfileImageUploader = ({ open, close, form, photo }) => {
    const fileInputRef = useRef(null);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (photo) {
            const url = URL.createObjectURL(photo);
            setImageSrc(url);
        }
    }, [photo]);

    const [imageSrc, setImageSrc] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => setImageSrc(reader.result);
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
            resizeImage(croppedBlob);
        } catch (e) {
            console.error(e);
        }
    }, [imageSrc, croppedAreaPixels]);

    const resizeImage = (imageBlob) => {
        Resizer.imageFileResizer(
            imageBlob,
            400,
            400,
            "JPEG",
            100,
            0,
            (uri) => {
                form.setFieldValue('profileImage', uri);
                close();
            },
            "base64"
        );
    };

    // Calcular la altura dinámica para el contenedor del cropper
    const getCropperHeight = () => {
        const headerHeight = 53; // Altura del header (11px padding-block * 2 + contenido)
        const controlsHeight = 50; // Altura de los controles
        const maxHeight = Math.min(650, windowHeight - 40); // 40px de margen
        return maxHeight - headerHeight - controlsHeight;
    };

    return (
        <Modal
            open={open}
            onClose={close}
            slotProps={{
                backdrop: {
                    style: { backgroundColor: 'rgba(105, 161, 136, 0.2)' }
                }
            }}
            className={styles.modalContainer}
        >
            <div className={styles.uploader}>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={onFileChange}
                    accept="image/*"
                />

                <div className={styles.modalHeader}>
                    <div>
                        <button className={styles.goBack} onClick={close}>
                            <img src={BackArrowIcon} alt="back" />
                        </button>
                        <p>Edit media</p>
                    </div>
                    <button onClick={showCroppedImage}>Save</button>
                </div>

                {imageSrc && (
                    <div className={styles.cropperContainer}>
                        <div style={{
                            position: "relative",
                            width: '100%',
                            height: getCropperHeight(),
                            backgroundColor: '#0d0f10'
                        }}>
                            <Cropper
                                image={imageSrc}
                                crop={crop}
                                zoom={zoom}
                                aspect={1}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                            />
                        </div>
                        <div className={styles.controls}>
                            <input
                                type="range"
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e) => setZoom(e.target.value)}
                                className={styles.zoomRange}
                            />
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default ProfileImageUploader;