import React, { useEffect, useRef, useState } from 'react'
import styles from './linkedout.module.css'
import SearchIcon from '@mui/icons-material/Search';

import LanguageIcon from '@mui/icons-material/Language';

import LinkedOutLogo from '../LinkedOut/LinkedOut.svg';

import { Avatar, MenuItem } from '@mui/material';

import { useScroll } from '../../../Utils/ScrollContextScrollProvider';
import { CustomDropdown } from '../../../Utils/Components/CustomDropdown';
import { LinkedOutDropdownSearch } from '../../../Utils/Components/LinkedOutDropdownSearch/LinkedOutDropdownSearch';

export const LinkedOut = () => {

    const [placeholderText, setPlaceholderText] = useState('Search for a product');

    const { registerScrollableElement, unregisterScrollableElement } = useScroll();
    const pageContainerRef = useRef(null);

    const { isVisible } = useScroll();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1400) {
                setPlaceholderText('Search...');
            } else {
                setPlaceholderText('Title or company');
            }
        };

        // Llamar la función al montar el componente
        handleResize();

        // Agregar event listener para el resize de la ventana
        window.addEventListener('resize', handleResize);

        if (pageContainerRef.current) {
            registerScrollableElement(pageContainerRef.current);
        }

        // Limpiar el event listener al desmontar el componente
        return () => {
            window.removeEventListener('resize', handleResize);
            if (pageContainerRef.current) {
                unregisterScrollableElement(pageContainerRef.current);
            }
        }
    }, []);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [industrySearch, setIndustrySearch] = useState('');

    return (
        <div className={styles.pageContainer}>

            <div className={`${styles.header} ${isVisible ? styles.visible : styles.hidden}`}>
                <img src={LinkedOutLogo} />
                <div className={styles.searchBarContainer}>
                    <div className={styles.searchBar}>
                        <div >
                            <SearchIcon className={styles.searchIcon} />
                        </div>
                        <input type="text" placeholder={placeholderText} className=''>
                        </input>
                    </div>
                    <div className={styles.searchBar}>
                        <div >
                            <SearchIcon className={styles.searchIcon} />
                        </div>
                        <input type="text" placeholder='State, worldwide...' className=''>
                        </input>
                    </div>
                    <LinkedOutDropdownSearch
                        id={'industry'}
                        width='200px'
                        height='50px'
                        name={'industry'}
                        label={'Industry/Field'}
                        value={industrySearch}
                        onChange={(event)=>setIndustrySearch(event.target.value)}
                        error={false}
                        helperText=""
                    >
                        <MenuItem value='ECONOMICS'>Economics</MenuItem>
                        <MenuItem value='LOGISTICS'>Logistics</MenuItem>
                        <MenuItem value='MARKETING'>Marketing</MenuItem>
                        <MenuItem value='SECURITY'>Security</MenuItem>
                        <MenuItem value='CONSTRUCTION'>Construction</MenuItem>
                        <MenuItem value='PHARMACOLOGY'>Pharmacology</MenuItem>
                        <MenuItem value='AGRONOMY'>Agronomy</MenuItem>
                        <MenuItem value='MINING_GAS_AND_OIL'>Mining, Gas and Oil</MenuItem>
                        <MenuItem value='MECHANICAL_ENGINEERING'>Mechanical Engineering</MenuItem>
                        <MenuItem value='TELECOMMUNICATIONS'>Telecommunications</MenuItem>
                        <MenuItem value='ELECTRICAL_ENGINEERING'>Electrical Engineering</MenuItem>
                        <MenuItem value='COMPUTER_SCIENCE'>Computer Science</MenuItem>
                        <MenuItem value='PHYSICS'>Physics</MenuItem>
                        <MenuItem value='CHEMISTRY'>Chemistry</MenuItem>
                        <MenuItem value='MATHEMATICS'>Mathematics</MenuItem>
                        <MenuItem value='BIOLOGY'>Biology</MenuItem>
                        <MenuItem value='THEOLOGY'>Theology</MenuItem>
                        <MenuItem value='BIOLOGY'>Biology</MenuItem>
                        <MenuItem value='MEDICINE'>Medicine</MenuItem>
                        <MenuItem value='PSYCHOLOGY'>Psychology</MenuItem>
                        <MenuItem value='EDUCATION'>Education</MenuItem>
                    </LinkedOutDropdownSearch>
                </div>


                <button className={styles.country}>
                    <SearchIcon />
                    <p>Search</p>
                </button>

            </div>

            <div className={styles.contentWrapper} ref={pageContainerRef}>



                <div className={styles.buttons}>
                    {/** jOB PICKS FOR YOU, mostrar 3 y botón de show all, como en linkedin.
                     * sección para indicar si estás buscando empleo, para poder recibir ofertas. Utilizar aquí ese botón que se desplaza para indicar que sí o que no
                     * sección de ofertas de empleo a las que has aplicado. Se limpian cada semana si se ha superado la caducidad de la solicitud de la oferta.
                     */}
                </div>


            </div>
        </div>
    )
}
