import React, { useEffect, useState } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';


import styles from './stateprofile.module.css';
import { Tab, Tabs } from '../../Tabs/Tabs';

import StateBadge from './static/StateBadge.png'
import { api, ensureAuthorizationHeader } from '../../../Config/api';

import LoadingRing from '../../../Static/LoadingRingV2.svg';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { PieChartComponent } from '../PieChartComponent/PieChartComponent';
import { flattenObject } from '../../../Utils/flattenObject';
import { formatValueForPieChart } from '../../../Utils/formatValueForPieChart';

import Person2Icon from '@mui/icons-material/Person2';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';



const StateProfile = () => {

    const [tabValue, setTabValue] = useState("1");
    const navigate = useNavigate();

    const [loadingProfile, setLoadingProfile] = useState(true);

    const { username } = useParams();

    const [stateProfile, setStateProfile] = useState(() => {
        const savedProfile = sessionStorage.getItem(`stateProfile-${username}`);
        return savedProfile ? JSON.parse(savedProfile) : null;
    }
    );

    useEffect(() => {
        const fetchStateProfile = async () => {

            setLoadingProfile(true);
            try {
                // Asegúrate de que el JWT esté configurado en la petición a la api
                ensureAuthorizationHeader();

                const response = await api.get(`/api/v1/state/profile/${username}`);
                setStateProfile(response.data); // Asume que la respuesta tiene una propiedad 'data' que contiene el perfil del estado
                sessionStorage.setItem(`stateProfile-${username}`, JSON.stringify(response.data));
            } catch (error) {
                console.error('Error fetching state profile:', error);
            } finally {
                setTimeout(() => {
                    setLoadingProfile(false);
                }, 300)

                // Gestión de sessionStorage para eliminar perfiles antiguos si hay más de 3 almacenados
                const storedProfiles = Object.keys(sessionStorage).filter(key => key.startsWith('stateProfile-'));
                console.log(storedProfiles)

                if (storedProfiles.length > 3) {
                    // Ordenar por la fecha de creación (basado en el orden de inserción de las claves)
                    storedProfiles.sort((a, b) => {
                        return sessionStorage.getItem(a) < sessionStorage.getItem(b) ? -1 : 1;
                    });

                    // Eliminar el perfil más antiguo
                    sessionStorage.removeItem(storedProfiles[0]);
                }

            }
        };

        fetchStateProfile();
    }, [username]);


    const [openProfileModal, setOpenProfileModal] = useState(false);
    const handleOpenProfileModel = () => setOpenProfileModal(true);
    const handleCloseProfileModal = () => setOpenProfileModal(false);


    const handleBack = () => navigate(-1);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

        const [day, month, year] = formattedDate.split(' ');
        return `${year} ${month} ${day}`;
    };

    const [popsDistributionsQuarter, setPopsDistributionsQuarter] = useState(1);

    const handleChangePopsDistributionsQuarter = (event) => {

        let quarterValue;

        if (event !== 1) {
            quarterValue = event.target.value;


        } else {
            quarterValue = event;
        }

        setPopsDistributionsQuarter(quarterValue);

        const currentQuarterData = stateProfile?.quarterlyStatistics.find(stat => stat.quarter === event.target.value);


        if (currentQuarterData) {

            // Aquí procesar los datos según tus necesidades
            setPercentageDistribution({
                popType: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allPopTypesDistribution),
                sex: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allSexDistribution),
                superCulture: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allSuperCulturesDistribution),
                culture: processPopsDistributionsData(flattenObject(currentQuarterData?.hitStatistics?.allCulturesDistribution)),
                superBelief: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allSuperBeliefsDistribution),
                belief: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allBeliefsDistribution),
                ageRange: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allAgeDistribution),
                race: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allRacesDistribution),
                qualification: processPopsDistributionsData(currentQuarterData?.hitStatistics?.allQualificationLevelDistribution),
                // Agrega los demás procesamientos según sea necesario
            });


            // Procesar y establecer los datos absolutos
            setAbsoluteDistribution({
                popType: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allPopTypesDistribution),
                sex: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allSexDistribution),
                superCulture: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allSuperCulturesDistribution),
                culture: processAbsolutePopsDistributionsData(flattenObject(currentQuarterData?.hitStatistics?.allCulturesDistribution)),
                superBelief: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allSuperBeliefsDistribution),
                belief: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allBeliefsDistribution),
                ageRange: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allAgeDistribution),
                race: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allRacesDistribution),
                qualification: processAbsolutePopsDistributionsData(currentQuarterData?.hitStatistics?.allQualificationLevelDistribution),
            });

        }
    };

    const processPopsDistributionsData = (data) => {
        // Filtrar los 9 valores más grandes y agrupar el resto en "Otros"
        const entries = Object.entries(data)
            .filter(([key, value]) => key.endsWith("Percentage") && value > 0) // Filtrar valores mayores a 0 y que sean porcentajes
            .sort((a, b) => b[1] - a[1]) // Ordenar de mayor a menor

        const topEntries = entries.slice(0, 9);
        const othersValue = entries.slice(9).reduce((acc, [_, value]) => acc + value, 0);

        const result = topEntries.map(([label, value], index) => ({
            id: index,
            label: formatValueForPieChart(label),
            value,
        }));

        if (othersValue > 0) {
            result.push({
                id: 9,
                label: "Others",
                value: othersValue,
            });
        }

        return result;
    };

    const processAbsolutePopsDistributionsData = (data) => {
        const entries = Object.entries(data)
            .filter(([key]) => !key.endsWith("Percentage")) // Filtrar las claves que no terminan en Percentage
            .filter(([_, value]) => value > 0) // Filtrar valores mayores a 0
            .sort((a, b) => b[1] - a[1]); // Ordenar de mayor a menor

        const topEntries = entries.slice(0, 9);
        const othersValue = entries.slice(9).reduce((acc, [_, value]) => acc + value, 0);

        const result = topEntries.map(([label, value], index) => ({
            id: index,
            label: formatValueForPieChart(label),
            value,
        }));

        if (othersValue > 0) {
            result.push({
                id: 9,
                label: "Others",
                value: othersValue,
            });
        }

        return result;
    };

    const [percentageDistribution, setPercentageDistribution] = useState({
        popType: [],
        sex: [],
        culture: [],
        superCulture: [],
        belief: [],
        superBelief: [],
        race: [],
        qualification: [],
        ageRange: [],
    });

    const [absoluteDistribution, setAbsoluteDistribution] = useState({
        popType: [],
        sex: [],
        culture: [],
        superCulture: [],
        belief: [],
        superBelief: [],
        race: [],
        qualification: [],
        ageRange: [],
    });


    return (

        <div className={styles.mainContent}>
            {loadingProfile
                ?
                <div className={styles.loadingContainer}>
                    <img src={LoadingRing} />
                </div>
                :
                <>
                    {stateProfile &&
                        <div style={{ border: '1px solid #2c2f32', minHeight: '100vh' }}>
                            <section className={styles.fixedHeader}>
                                <KeyboardBackspaceIcon className={styles.arrowIcon} onClick={handleBack} />
                                <h1 className={styles.headerTitle}>{stateProfile?.name}</h1>
                                <div className={styles.rightContainer}>

                                </div>
                            </section>

                            <img className={styles.backgroundImage} src="https://cdn.pixabay.com/photo/2023/07/25/19/47/milky-way-8149815_640.jpg" alt='' />

                            <div className={styles.profileHeader}>
                                <img className={styles.profileImage} src={stateProfile?.shieldUrl} alt='' />
                            </div>

                            <section className={styles.profileInfo}>
                                <div className={styles.userDetails}>
                                    <div className={styles.userInfo}>
                                        <h1 className={styles.name}>{stateProfile?.name}</h1>
                                        <img className={styles.stateBadge} alt='' src={StateBadge} />
                                    </div>
                                    <h1 className={styles.username}>{stateProfile?.username}</h1>
                                </div>

                                <div className={styles.userDetails}>
                                    <p>{stateProfile?.description}</p>
                                    <div className={styles.userStats}>

                                        <div className={styles.userStat}>
                                            <CalendarMonthIcon className={styles.userStatsIcons} />
                                            <p className={styles.grayText}>Founded on {stateProfile?.foundingDate && formatDate(stateProfile.foundingDate)}</p>
                                        </div>

                                        <div className={styles.userStat}>
                                            <FlagOutlinedIcon />
                                            <img style={{ height: '20px' }} src={stateProfile?.flagUrl} />
                                        </div>

                                    </div>
                                </div>
                            </section>

                            <section className={styles.tabContainer}>
                                <Tabs>
                                    <Tab label="Profile">
                                        <div className={styles.profileTab}>

                                            <div className={styles.profileElement}>
                                                <p>Anthem:</p>

                                                <div className={styles.profileElementContent}>

                                                    {stateProfile?.anthemUrl &&
                                                        <audio controls>
                                                            <source src={stateProfile?.anthemUrl} type="audio/mpeg" />
                                                            <source src={stateProfile?.anthemUrl} type="audio/ogg" />
                                                            Your browser does not support the audio element.
                                                        </audio>}


                                                </div>

                                            </div>

                                            <div className={styles.profileElement}>
                                                <p>Head of State:</p>

                                                <div className={styles.profileElementContent}>



                                                </div>

                                            </div>

                                            {/* Renderizado condicional a si tiene una guerra*/}
                                            <div className={styles.profileElement}>
                                                <p>In war with:</p>

                                                <div className={styles.profileElementContent}>



                                                </div>

                                            </div>


                                            <div className={styles.buttonsSection}>

                                                <button className={styles.button} onClick={()=>navigate('cabinet')}>
                                                    <Person2Icon className={styles.icon} />
                                                    <span>Executive</span>
                                                </button>
                                                <button className={styles.button} onClick={()=>navigate('legislature?lowerHouse=true')}>
                                                    <AccountBalanceIcon className={styles.icon} />
                                                    <span>Legislature</span>
                                                </button>
                                                <button className={styles.button}>
                                                    <GavelIcon className={styles.icon} />
                                                    <span>Judiciary</span>
                                                </button>
                                                <button className={styles.territorialOrganizationButton}>Territorial organization</button>


                                            </div>

                                        </div>
                                    </Tab>
                                    <Tab label="Constitution">
                                        <p>Inminentemente</p>
                                    </Tab>
                                    <Tab label="Orgs">
                                        <div>Próximamente!</div>
                                    </Tab>
                                    <Tab label="Structures">
                                        <div>Próximamente!</div>
                                    </Tab>
                                    <Tab label="Statistics">
                                        <div className={styles.statisticsQuarterAndYear}>
                                            <div className={styles.elementTitle}>
                                                <div className={styles.popsDistributions}>
                                                    <p>POPS DISTRIBUTIONS</p>
                                                </div>
                                                <hr />
                                            </div>

                                        </div>

                                        <div className={styles.quarterDropdown}>
                                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                <InputLabel
                                                    id="demo-select-small-label"
                                                    sx={{
                                                        color: 'white', // Color del label por defecto
                                                        '&.Mui-focused': {
                                                            color: '#099f5d', // Cambia el color del label cuando el Select está enfocado
                                                        },
                                                    }} // Cambia el color del InputLabel a blanco
                                                >
                                                    Quarter
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={popsDistributionsQuarter}
                                                    label="Quarter"
                                                    onChange={handleChangePopsDistributionsQuarter}
                                                    sx={{
                                                        backgroundColor: 'black',
                                                        color: 'white', // Cambia el color del texto seleccionado a blanco
                                                        '& .MuiSelect-select': {
                                                            backgroundColor: 'black', // Color de fondo del select
                                                            color: 'white', // Color del valor seleccionado
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#767b80', // Borde blanco
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#767b80', // Borde blanco al pasar el mouse
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#767b80', // Borde blanco cuando está enfocado
                                                        },
                                                        '& .MuiSelect-icon': {
                                                            color: '#767b80', // Cambia el color del icono del "triangulito"
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={1}>Q1 <span style={{ color: '#767b80' }}><small>2024</small></span></MenuItem>
                                                    <MenuItem value={2}>Q2 <span style={{ color: '#767b80' }}><small>2024</small></span></MenuItem>
                                                    <MenuItem value={3}>Q3 <span style={{ color: '#767b80' }}><small>2024</small></span></MenuItem>
                                                    <MenuItem value={4}>Q4 <span style={{ color: '#767b80' }}><small>2024</small></span></MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div className={styles.statisticsContainer}>
                                            <div className={styles.pieChartWrapper}>
                                                <PieChartComponent
                                                    name='Pop type'
                                                    distributionData={percentageDistribution?.popType}
                                                    absoluteNumbersDistributionData={absoluteDistribution?.popType} />
                                            </div>
                                            <div className={styles.pieChartWrapper}>
                                                <PieChartComponent
                                                    name='Sex'
                                                    distributionData={percentageDistribution?.sex}
                                                    absoluteNumbersDistributionData={absoluteDistribution?.sex} />
                                            </div>
                                            <div className={styles.pieChartWrapper}>
                                                <PieChartComponent
                                                    name='Cultural group'
                                                    distributionData={percentageDistribution?.superCulture}
                                                    absoluteNumbersDistributionData={absoluteDistribution?.superCulture} />
                                            </div>
                                            <div className={styles.pieChartWrapper}>
                                                <PieChartComponent
                                                    name='Culture'
                                                    distributionData={percentageDistribution?.culture}
                                                    absoluteNumbersDistributionData={absoluteDistribution?.culture} />
                                            </div>
                                            <div className={styles.pieChartWrapper}>
                                                <PieChartComponent
                                                    name='Belief group'
                                                    distributionData={percentageDistribution?.superBelief}
                                                    absoluteNumbersDistributionData={absoluteDistribution?.superBelief} />
                                            </div>
                                            <div className={styles.pieChartWrapper}>
                                                <PieChartComponent
                                                    name='Belief'
                                                    distributionData={percentageDistribution?.belief}
                                                    absoluteNumbersDistributionData={absoluteDistribution?.belief} />
                                            </div>
                                            <div className={styles.pieChartWrapper}>
                                                <PieChartComponent
                                                    name='Race'
                                                    distributionData={percentageDistribution?.race}
                                                    absoluteNumbersDistributionData={absoluteDistribution?.race} />
                                            </div>
                                            <div className={styles.pieChartWrapper}>
                                                <PieChartComponent
                                                    name='Qualification'
                                                    distributionData={percentageDistribution?.qualification}
                                                    absoluteNumbersDistributionData={absoluteDistribution?.qualification} />
                                            </div>
                                            <div className={styles.pieChartWrapper}>
                                                <PieChartComponent
                                                    name='Age range'
                                                    distributionData={percentageDistribution?.ageRange}
                                                    absoluteNumbersDistributionData={absoluteDistribution?.ageRange} />
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </section>

                            <section>
                                {/* Para los modal */}
                            </section>
                        </div>
                    }
                </>
            }


        </div >
    );
};

export default StateProfile;
