import React from 'react'
import styles from './foundation.module.css'
import { useNavigate } from 'react-router-dom';

export const Foundation = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.pageContainer}>



      <div className={styles.mainContent}>

        <div className={styles.sidebar}>
          <p><i>Nunca pertenecería a un club que admitiera como socio a alguien como yo.</i></p>
          <p><a href='https://es.wikipedia.org/wiki/Groucho_Marx' target='_blank' rel='noopener noreferrer'><strong>- Groucho Marx -</strong></a></p>
        </div>

        <div className={styles.organizationsContainer}>


          <div className={styles.market}>
            <div className={styles.titleAndRequiredLevel}>
              <p className={styles.skillTitle}>Company</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Ready to give up the security of a paycheck for the thrill of debt? Welcome to the world of business, where you'll be the boss... of your own misery.
                Choose your business wisely, because customers will never know what they want until they give it back, and banks will watch you like vultures,
                waiting for just the right moment to remind you who's really in charge.</p>
              <p>Required knowledge: <strong>Young Entrepreneur</strong>.</p>
              <span><strong>500</strong> arauzita needed</span>
            </div>
            <div className={styles.buttons}>

              <button
                className={styles.learningButton}
                onClick={() => navigate('company')}
              >
                Found organization
              </button>

            </div>
          </div>

          <div className={styles.market}>
            <div className={styles.titleAndRequiredLevel}>
              <p className={styles.skillTitle}>Political party</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Entering the world of politics? Perfect! Here, it’s all about mastering the fine art of speaking for hours without actually saying anything.
                Voters will cheer for your slogans today and forget them tomorrow, but that’s exactly how it works. Craft your party’s message carefully,
                because no matter what you claim to represent, someone will always insist you stood for the opposite.</p>
              <p>Required knowledge: <strong>Young Idealistic</strong>.</p>
              <span><strong>750</strong> arauzita needed</span>
            </div>
            <div className={styles.buttons}>

              <button
                className={styles.learningButton}
                onClick={() => navigate('political-party')}
              >
                Found organization
              </button>

            </div>
          </div>

          <div className={styles.market}>
            <div className={styles.titleAndRequiredLevel}>
              <p className={styles.skillTitle}>Trade union</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Welcome to the world of unions, where solidarity sounds great until it’s time to negotiate. Members will rally behind your cause today but question your leadership tomorrow,
                and that’s just part of the fun. Fight for workers' rights with passion, but keep in mind that corporate sharks are always lurking,
                ready to remind you that collective bargaining often feels more like collective begging.</p>
              <p>Required knowledge: <strong>Young Worker</strong>.</p>
              <span><strong>750</strong> arauzita needed</span>
            </div>
            <div className={styles.buttons}>

              <button
                className={styles.learningButton}
                onClick={() => navigate('trade-union')}
              >
                Found organization
              </button>

            </div>
          </div>

          <div className={styles.market}>
            <div className={styles.titleAndRequiredLevel}>
              <p className={styles.skillTitle}>Religious organization</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Welcome to the world of religious organizations, where enlightenment comes with a side of bewilderment.
                Followers will praise your teachings today and debate their meaning tomorrow, and that’s just how it goes.
                Spread your message with fervor, but be prepared for endless interpretations, because no matter what you preach, someone will always twist it to fit their agenda.
              </p>
              <p>Required knowledge: <strong>Young Believer</strong>.</p>
              <span><strong>750</strong> arauzita needed</span>
            </div>
            <div className={styles.buttons}>

              <button
                className={styles.learningButton}
                onClick={() => navigate('religious-organization')}
              >
                Found organization
              </button>

            </div>
          </div>

          <div className={styles.market}>
            <div className={styles.titleAndRequiredLevel}>
              <p className={styles.skillTitle}>NGO</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>In the world of NGOs, doing good can sometimes feel like a business model. You’ll champion noble causes today,
                but watch out—someone might find a way to turn your altruism into profit tomorrow. Rally supporters with passion,
                but remember that while you’re saving the world, some will always question whether your mission is truly about helping others or just cashing in on compassion.</p>
              <p>Required knowledge: <strong>So Altruistic</strong>.</p>
              <span><strong>500</strong> arauzita needed</span>
            </div>
            <div className={styles.buttons}>

              <button
                className={styles.learningButton}
                onClick={() => navigate('ngo')}
              >
                Found organization
              </button>

            </div>
          </div>

          <div className={styles.market}>
            <div className={styles.titleAndRequiredLevel}>
              <p className={styles.skillTitle}>Think tank</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>In the world of think tanks, lofty ideas come to life—until they’re tangled in endless debates. You’ll generate groundbreaking theories today,
                but be prepared for heated arguments tomorrow. Gather the brightest minds, but remember that while you’re crafting solutions,
                someone will always question whether your ideas will change the world or just gather dust on a shelf.</p>
              <p>Required knowledge: <strong>(-)</strong>.</p>
              <span><strong>1000</strong> arauzita needed</span>
            </div>
            <div className={styles.buttons}>

              <button
                className={styles.learningButton}
                onClick={() => navigate('think-tank')}
              >
                Found organization
              </button>

            </div>
          </div>

          {/* Un grupo de presión puede ser empresarial, sindical o religioso. Dependiendo el caso, se podrán asociar los correspondientes tipos de organizaciones*/}
          <div className={styles.market}>
            <div className={styles.titleAndRequiredLevel}>
              <p className={styles.skillTitle}>Advocacy group</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>In the realm of advocacy groups, influence is the name of the game. You’ll rally support for your cause today,
                but it’s the quiet lobbying behind closed doors that gets results tomorrow. Mobilize passionate followers,
                but remember that while you’re making noise, the real power lies in shaping policies and swaying politicians who know how to play the game.</p>
              <p>Required knowledge: <strong>(-)</strong>.</p>
              <span><strong>1500</strong> arauzita needed</span>
            </div>
            <div className={styles.buttons}>

              <button
                className={styles.learningButton}
                onClick={() => navigate('advocacy-group')}
              >
                Found organization
              </button>

            </div>
          </div>

          <div className={styles.market}>
            <div className={styles.titleAndRequiredLevel}>
              <p className={styles.skillTitle}>Secret society</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>In the world of secret societies, nothing is as it seems and everything is up for grabs.
                You’ll gather in dimly lit rooms today, plotting world domination under the guise of philanthropy tomorrow. Embrace the intrigue,
                but remember that while you’re weaving your web of influence,
                the real power lies in keeping your secrets safe from prying eyes, because in the end, knowledge is power... and ignorance is bliss.</p>
              <p>Required knowledge: <strong>Architect of World Order</strong>.</p>
              <span><strong>3000</strong> arauzita needed</span>
            </div>
            <div className={styles.buttons}>

              <button
                className={styles.learningButton}
                onClick={() => navigate('secret-society')}
              >
                Found organization
              </button>

            </div>
          </div>



        </div>
      </div>

    </div>
  );
};

// Falta añadir el modal para cuando no puede fundar un tipo de organization porque no tiene adquirido el conocimiento correspondiente