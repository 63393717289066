import CheckIcon from '@mui/icons-material/Check';

const RequirementsDisplay = ({ requirementsAndPlace, styles, setDisabled, cofoundersInfoLength }) => {
    // Función para calcular el espacio de posiciones basado en el tipo de suscripción
    const calculatePositionSpace = (subscriptionType, positionsNumber) => {
      // Valor por defecto es 3
      let divisor = 3;
      
      // Ajustar el divisor según el tipo de suscripción
      switch (subscriptionType) {
        case 'GREEN':
          divisor = 4;
          break;
        case 'GOLDEN':
          divisor = 5;
          break;
        default:
          divisor = 3;
      }
      
      // Calcular el espacio de posiciones restante
      return divisor-positionsNumber;
    };
  
    // Obtener el tipo de suscripción y calcular el espacio de posiciones
    const subscriptionType = requirementsAndPlace?.targetUser?.premiumSubscription?.activeSubscriptionType || 'NONE';
    const positionSpace = calculatePositionSpace(subscriptionType, requirementsAndPlace?.positionsNumber);
  
    // Verificar si cumple con el requisito de arauzita
    const hasEnoughArauzita = requirementsAndPlace?.targetUser?.arauzita >= 750;
    const hasAnAssociationWithAPoliticalParty = requirementsAndPlace?.hasAnAssociationWithAPoliticalParty;

    setDisabled(cofoundersInfoLength < 2 || !hasEnoughArauzita || !requirementsAndPlace?.knowledge?.youngIdealisticLearned || requirementsAndPlace?.hasAnAssociationWithAPoliticalParty || positionSpace < 1);
  
    return (
      <p className={styles.infoText}>
        {requirementsAndPlace?.targetUser?.arauzita}/750 arauzita needed 
        {hasEnoughArauzita && <CheckIcon className={styles.checkIcon} />} · Young Idealistic learned 
        {requirementsAndPlace?.knowledge?.youngIdealisticLearned && 
          <CheckIcon className={styles.checkIcon} />} · Not affiliated with another political party 
        {!hasAnAssociationWithAPoliticalParty && 
          <CheckIcon className={styles.checkIcon} />} · {positionSpace}/1 position space needed 
        {positionSpace > 0 && <CheckIcon className={styles.checkIcon} />}
      </p>
    );
  };
  
  export default RequirementsDisplay;