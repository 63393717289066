import React, { useEffect, useState } from 'react';
import SocialRightPart from '../../../SocialPage/RightPart/SocialRightPart';
import styles from './founding.module.css';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from '../../../../Config/api';
import { formatText } from '../../../../Utils/capitalizeFirtsLetter';
import { formatDateTimeToReadableString } from '../../../../Utils/DurationToDateTime';

export const Founding = () => {
  const navigate = useNavigate();
  const handleBack = () => navigate(-1);
  const { username } = useParams();

  const [foundingData, setFoundingData] = useState();

  const inaugurate = () => {
    const response = api.patch(`/api/founding/inaugurate/political-party/${foundingData?.organization?.username}`)
    if (response.status === 200) {
      navigate('/org')
    }
  }

  useEffect(() => {

    const initializeComponent = async () => {
      try {
        const response = await api.get(`api/org/founding?username=${username}`);
        setFoundingData(response.data);
        console.log(response.data)
      } catch (error) {
        console.error('Failed to fetch foundation requirements:', error);
      }
    };

    initializeComponent();

  }, []);

  return (
    <div className={styles.socialContainer}>
      <div className={styles.feedContainer}>
        <section className={styles.fixedHeader}>
          <KeyboardBackspaceIcon className={styles.arrowIcon} onClick={handleBack} />
          <h1 className={styles.headerTitle}>
            {foundingData?.organization?.name} <span>(founding)</span>
          </h1>
        </section>

        <div style={{ marginTop: '52px' }}>
          {/* Organization Section */}
          <div className={styles.infoSection}>
            <div className={styles.infoRow}>
              <span className={styles.label}>Organization:</span>
              <div className={styles.organizationInfo} onClick={() => navigate(`/profile/org/pp/${foundingData?.organization?.username}`)}>
                <img
                  src={foundingData?.organization?.logoUrl}
                  alt="Organization logo"
                  className={styles.orgLogo}
                />
                <div className={styles.orgDetails}>
                  <span className={styles.orgName}>{foundingData?.organization?.name}</span>
                  <span className={styles.orgUsername}>{foundingData?.organization?.username}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Type Section */}
          <div className={styles.infoSection}>
            <div className={styles.infoRow}>
              <span className={styles.label}>Type:</span>
              <span>{formatText(foundingData?.organization?.organizationType)}</span>
            </div>
          </div>

          {/* Deadline Section */}
          <div className={styles.infoSection}>
            <div className={styles.infoRow}>
              <span className={styles.label}>Deadline:</span>
              <span>{formatDateTimeToReadableString(foundingData?.deadline)}</span>
            </div>
          </div>

          {/* Potential Co-founders Section */}
          <div className={styles.infoSection}>
            <span className={styles.label}>Potential co-founders:</span>
            <div className={styles.cofoundersList}>

              {foundingData?.potentialCoFounders?.map((cofounder) => {
                const { text, style } = getCoFounderStatus(cofounder.accepted);
                return (
                  // TODO : aquí hará falta ver si es una organization o un player, para ajustar la ruta
                  <div className={styles.cofounderItem} onClick={() => navigate(`/profile/user/${cofounder?.coFounderPlayer?.targetUser?.username}`)}>
                    <div className={styles.cofounderInfo}>
                      <img
                        src={cofounder?.coFounderPlayer
                          ? cofounder?.coFounderPlayer?.targetUser?.profileImageUrl
                          : cofounder?.coFounderOrganization.logoUrl}
                        alt="Co-founder avatar"
                        className={styles.cofounderAvatar}
                      />
                      <div className={styles.cofounderDetails}>
                        <span className={styles.orgName}>{cofounder?.coFounderPlayer
                          ? cofounder?.coFounderPlayer?.targetUser?.name
                          : cofounder?.coFounderOrganization.name}</span>

                        <span className={styles.orgUsername}>{cofounder?.coFounderPlayer
                          ? cofounder?.coFounderPlayer?.targetUser?.username
                          : cofounder?.coFounderOrganization.username}</span>
                      </div>
                    </div>
                    <span className={style}>{text}</span>
                  </div>)
              })}

            </div>
          </div>

          {/* Deadline Section */}
          <div className={styles.infoSection}>
            <div className={styles.infoRow}>
              <span className={styles.label}>All accept opt:</span>
              <span>{foundingData?.createOnlyIfAllCoFoundersAccept ? 'Yes' : 'No'}</span>
            </div>
          </div>

          <button
            type='submit'
            className={`${styles.submitButton} ${!foundingData?.canInaugurate ? styles.disabledButton : ''}`}
            disabled={!foundingData?.canInaugurate}
            onClick={() => inaugurate()}
          >
            Inaugurate
          </button>

        </div>
      </div>

      <div className={styles.socialRightPart}>
        <SocialRightPart />
      </div>
    </div>
  );
};


const getCoFounderStatus = (accepted) => {
  if (accepted === null) {
    return {
      text: 'Pending',
      style: styles.statusPending,
    };
  } else if (accepted === true) {
    return {
      text: 'Accepted',
      style: styles.statusAccepted,
    };
  } else {
    return {
      text: 'Declined',
      style: styles.statusDeclined,
    };
  }
};
