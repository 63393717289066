export const capitalizeFirstLetter = (text) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

export const replaceUnderscoresWithSpaces = (text) => {
    if(!text) return '';
    let finalText =  text.replace(/_/g, ' ');
    return capitalizeFirstLetter(finalText);
}

export const formatText = (text) => {
  if (!text) return '';
  
  // Remove extra whitespace and trim
  const trimmedText = text.trim().replace(/\s+/g, ' ');
  
  // Replace underscores with spaces
  const textWithSpaces = trimmedText.replace(/_/g, ' ');
  
  // Capitalize first letter and convert rest to lowercase
  return capitalizeFirstLetter(textWithSpaces);
}
