import PublicIcon from '@mui/icons-material/Public';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import VerifiedIcon from '@mui/icons-material/Verified';
import EmailIcon from '@mui/icons-material/Email';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PersonIcon from '@mui/icons-material/Person';
import ContactlessIcon from '@mui/icons-material/Contactless';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import HomeFilled from './HomeIconFilled.svg'
import HomeOutlined from './HomeIconOutlined.svg'
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import SearchIcon from '@mui/icons-material/Search';

export const navigationMenu=[
    {
        title:"Home",
        filledIcon:<img src={HomeFilled} style={{width:'23px', marginLeft:'3px', marginRight:'5.5px'}}/>,
        outlinedIcon:<img src={HomeOutlined} style={{width:'23px', marginLeft:'3px', marginRight:'2px'}}/>,
        path:"/social"
    },
    {
        title:"Explore",
        filledIcon:<SearchIcon style={{ fontSize: '1.8rem', marginRight:'4px' }}/>,
        outlinedIcon:<SearchIcon style={{ fontSize: '1.8rem', color: '#E7E9EA' }}/>,
        path:"/explore"
    },
    {
        title:"Worldview",
        filledIcon:<PublicIcon style={{ fontSize: '1.8rem', marginRight:'4px' }}/>,    
        outlinedIcon:<PublicIcon style={{ fontSize: '1.8rem', color: '#E7E9EA' }}/>,
        path:"/worldview"
    },
    {
        title:"Market",
        filledIcon:<ContactlessIcon style={{ fontSize: '1.8rem', marginRight:'4px' }}/>,
        outlinedIcon:<ContactlessOutlinedIcon style={{ fontSize: '1.8rem', color: '#E7E9EA' }}/>,
        path:"/market"
    },
    {
        title:"Organizations",
        filledIcon:<AccountBalanceIcon style={{ fontSize: '1.8rem', marginRight:'4px' }}/>,
        outlinedIcon:<AccountBalanceOutlinedIcon style={{ fontSize: '1.8rem', color: '#E7E9EA' }}/>,
        path:"/org"
    },
    {
        title:"Conflict",
        filledIcon:<LocalFireDepartmentIcon style={{ fontSize: '1.8rem', marginRight:'4px' }}/>,
        outlinedIcon:<LocalFireDepartmentOutlinedIcon style={{ fontSize: '1.8rem', color: '#E7E9EA' }}/>,
        path:"/conflict"
    },
    {
        title:"Messages",
        filledIcon:<EmailIcon style={{ fontSize: '1.8rem', marginRight:'4px' }}/>,
        outlinedIcon:<EmailOutlinedIcon style={{ fontSize: '1.8rem', color: '#E7E9EA' }}/>,
        path:"/messages"
    },
    {
        title:"Email",
        filledIcon:<AttachEmailIcon style={{ fontSize: '1.8rem', marginRight:'4px' }}/>,
        outlinedIcon:<AttachEmailOutlinedIcon style={{ fontSize: '1.8rem', color: '#E7E9EA' }}/>,
        path:"/email"
    },
    {
        title:"Premium",
        filledIcon:<VerifiedIcon style={{ fontSize: '1.8rem', marginRight:'4px' }}/>,
        outlinedIcon:<VerifiedOutlinedIcon style={{ fontSize: '1.8rem', color: '#E7E9EA' }}/>,
        path:"/premium"
    },
    {
        title:"Personal",
        filledIcon:<AccountBoxIcon style={{ fontSize: '1.8rem', marginRight:'4px' }}/>,
        outlinedIcon:<AccountBoxOutlinedIcon style={{ fontSize: '1.8rem', color: '#E7E9EA' }}/>,
        path:"/personal"
    },
    {
        title:"Profile",
        filledIcon:<PersonIcon style={{ fontSize: '1.8rem', marginRight:'4px' }}/>,
        outlinedIcon:<PersonOutlineOutlinedIcon style={{ fontSize: '1.8rem', color: '#E7E9EA' }}/>,
        path:"/profile/user"
    },
    {
        title:"More",
        filledIcon:<DeviceHubOutlinedIcon style={{ fontSize: '1.8rem', marginRight:'4px' }}/>,
        outlinedIcon:<DeviceHubOutlinedIcon style={{ fontSize: '1.8rem', color: '#E7E9EA' }}/>,
        path:"/more"
    },
]