import React, { useRef } from 'react'
import WorldviewPage from '../WorldviewPage/WorldviewPage'
import SocialPage from '../SocialPage/SocialPage'
import { Route, Routes } from 'react-router-dom'
import Navigation from '../Navigation/Navigation'
import styles from './worldorder.module.css'
import ProfileContainer from '../ProfileContainer/ProfileContainer'
import { MorePage } from '../MorePage/MorePage'
import { KnowledgePage } from '../KnowledgePage/KnowledgePage'
import { SettingsPage } from '../SettingsPage/SettingsPage'
import { MarketPage } from '../Market/MarketPage'
import { B2BPage } from '../Market/B2BPage/B2BPage'
import { ScrollProvider } from '../../Utils/ScrollContextScrollProvider'
import { Organizations } from '../Organizations/Organizations'
import { FoundationContainer } from '../Organizations/Foundation/FoundationContainer'
import { MessagesPage } from '../Messages/MessagesPage'
import { EmailPage } from '../Email/EmailPage'
import { PersonalPage } from '../Personal/PersonalPage'
import { LegislaturePage } from '../Legislature/LegislaturePage'
import {Banking} from '../Personal/Banking/Banking'
import ColdWallet from '../Personal/ColdWallet/ColdWallet'
import { ExplorePage } from '../Explore/ExplorePage'
import { Foundation } from '../Organizations/Foundation/Foundation'
import { LinkedOut } from '../Organizations/LinkedOut/LinkedOut'
import { Founding } from '../Organizations/Foundation/Founding/Founding'
import { GovernmentCabinet } from '../GovernmentCabinetPage/GovernmentCabinet'

const WorldOrder = () => {

    return (
        <ScrollProvider>
            <div className={styles.container}>

                <Navigation />

                <div className={styles.sectionsContent}>

                    <Routes>
                        <Route path='/*' element={<SocialPage />} />

                        <Route path='/worldview' element={<WorldviewPage />} />

                        <Route path='/explore' element={<ExplorePage />} />

                        <Route path='/social/*' element={<SocialPage />} />

                        <Route path='/market' element={<MarketPage />} />
                        <Route path='/market/b2b' element={<B2BPage />} />

                        <Route path='/org' element={<Organizations />} />
                        <Route path='/org/foundation' element={<Foundation />} />
                        <Route path='/org/foundation/*' element={<FoundationContainer />} />
                        <Route path='/org/linkedout' element={<LinkedOut />} />
                        <Route path='/org/founding/:username' element={<Founding />} />
                        

                        <Route path="/messages/*" element={<MessagesPage/>} />
                        <Route path="/messages/:chatId" element={<MessagesPage/>} />

                        <Route path="/email/*" element={<EmailPage/>} />
                        <Route path="/email/:chatId" element={<EmailPage/>} />

                        {/* Para móviles, como directorio para acceder al resto de apartados */}
                        <Route path="/more" element={<MorePage />} />

                        <Route path="/personal" element={<PersonalPage/>} />
                        <Route path="/personal/banking" element={<Banking/>} />
                        <Route path="/personal/crypto" element={<ColdWallet/>} />
                        {/* TODO: ¡Que invierta su puta madre!, frase celebre en el apartado de inversiones, J.H. de Soto*/}

                        <Route path='/knowledge/*' element={<KnowledgePage />} />

                        <Route path="/profile/*" element={<ProfileContainer />} />
                        <Route path='profile/state/:username/legislature' element={<LegislaturePage />} />
                        <Route path='profile/state/:username/cabinet' element={<GovernmentCabinet />} />

                        <Route path="/settings/*" element={<SettingsPage />} />
                    </Routes>

                </div>

            </div>
        </ScrollProvider>
    );
}

export default WorldOrder