import { TextField } from '@mui/material';
import React from 'react'

export const CustomTextField = ({
    id,
    name,
    label,
    value,
    onChange,
    error,
    helperText,
    maxLength,
}) => {
    return (
        <TextField
            fullWidth
            id={id}
            name={name}
            label={label}
            value={value}
            onChange={onChange}
            error={error}
            helperText={helperText}
            sx={{
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: '#2c2f32',
                    },
                    '&:hover fieldset': {
                        borderColor: '#099f5d',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#099f5d',
                    },
                },
                '& .MuiInputLabel-root': {
                    color: '#71767b',
                    '&.Mui-focused': {
                        color: '#71767b',
                    },
                },
                '& .MuiSelect-icon': {
                    color: '#71767b',
                },
            }}
            InputProps={{
                style: {
                    backgroundColor: 'black',
                    color: 'white',
                },
            }}
            inputProps={{
                maxLength: {maxLength}
            }}
        />
    );
};