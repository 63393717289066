import { truncateName } from '../../../Utils/truncateName';
import styles from './banking.module.css';
import AddIcon from '@mui/icons-material/Add';

export const Banking = () => {

  return (
    <div className={styles.pageContainer}>

      <div className={styles.sidebar}>
        <p><i>Creo, sinceramente, como tú, que los sistemas bancarios son más peligrosos que los ejércitos</i></p>
        <p>
          <a href='https://es.wikipedia.org/wiki/Her%C3%A1clito' target='_blank' rel='noopener noreferrer'>
            <strong>- Thomas Jefferson -</strong>
          </a>
        </p>
      </div>

      <div className={styles.accountsContainer}>

        <div className={styles.account}>

          <div className={styles.accountHeader}>
            <div className={styles.accountBank}>
              <img src='https://pbs.twimg.com/profile_images/1863477859789963264/caJnmA6E_400x400.jpg' />
              <div>
                <p className={styles.bankName}><strong>Deutsche Bank</strong></p>
                <p className={styles.bankUsername}>@deutsche</p>
              </div>
            </div>

            <img src='https://upload.wikimedia.org/wikipedia/commons/8/89/Bandera_de_Espa%C3%B1a.svg' className={styles.countryFlag} />
          </div>

          <div className={styles.accountContent}>
            <p className={styles.balance}>2500 <span>(EUR)</span></p>
            <p>Time Deposit</p>
          </div>

          <button>Access</button>
        </div>

        <div className={styles.account}>

          <div className={styles.accountHeader}>
            <div className={styles.accountBank}>
              <img src='https://pbs.twimg.com/profile_images/1676225519501582338/BvttXyfZ_400x400.png' />
              <div>
                <p className={styles.bankName}><strong>{truncateName('BBVA', 14)}</strong></p>
                <p className={styles.bankUsername}>@bbvaES</p>
              </div>
            </div>

            <img src='https://upload.wikimedia.org/wikipedia/commons/8/89/Bandera_de_Espa%C3%B1a.svg' className={styles.countryFlag} />
          </div>

          <div className={styles.accountContent}>
            <p className={styles.balance}>2500 <span>(EUR)</span></p>
            <p>Transaction Account</p>
          </div>

          <button>Access</button>
        </div>

        <div className={styles.openAccount}>

          <div className={styles.add}>
            <AddIcon className={styles.icon}/>
          </div>
          <p>Open account</p>

        </div>



      </div>
    </div>
  );
};