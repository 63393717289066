import { useEffect, useRef } from "react";
import styles from './profilesdropdown.module.css'

// Componente Dropdown
export const ProfilesDropdownMenu = ({ isOpen, onClose }) => {
    const dropdownRef = useRef(null);

    // TODO: hacer petición al backend para extraer los perfiles que el usuario y renderizarlos en el menuList

    // Detecta los clics fuera del menú
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                onClose(); // Si el clic fue fuera del dropdown, lo cierra
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    if (!isOpen) {
        return null; // No renderiza el dropdown si no está abierto
    }

    return (
        <div className={styles.dropdownContainer}>
            <div ref={dropdownRef} className={styles.dropdownMenu}>
                <ul className={styles.menuList}>

                    <div className={styles.positionAndProfile}>
                        <p>Chairperson</p>
                        <div className={styles.profile}>
                            <img src='https://pbs.twimg.com/profile_images/1651219278664720384/6PrhZDPe_400x400.jpg' />
                            <div className={styles.nameAndUsername}>
                                <p>The Coca Cola Company</p>
                                <p>@cocacola</p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.positionAndProfile}>
                        <p>Committee member</p>
                        <div className={styles.profile}>
                            <img src='https://pbs.twimg.com/profile_images/1810271192940429312/EA1tHfpD_400x400.jpg' />
                            <div className={styles.nameAndUsername}>
                                <p>Partido Popular</p>
                                <p>@ppopular</p>
                            </div>
                        </div>
                    </div>

                </ul>
            </div>
        </div>
    );
};