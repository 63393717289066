import { useNavigate } from 'react-router-dom';
import styles from './personalpage.module.css'
import { useEffect, useState } from 'react';
import { api } from '../../Config/api';

export const PersonalPage = () => {

    const navigate = useNavigate();
    const [arauzita, setArauzita] = useState();

    useEffect(() => {
        const initializeComponent = async () => {
            try {
                    const response = await api.get(`/api/user/arauzita`);

                    // TODO según el chatId, consultar a la api para el contenido del correo
                    setArauzita(response.data);
            } catch (error) {
                console.error('Failed to fetch foundation requirements:', error);
            }
        };

        initializeComponent();
    }, []);

    return (
        <div className={styles.pageContainer}>

            <div className={styles.mainContent}>


                <div className={styles.sidebar}>
                    <p><i>Los grandes resultados requieren grandes ambiciones</i></p>
                    <p><a href='https://es.wikipedia.org/wiki/Her%C3%A1clito' target='_blank' rel='noopener noreferrer'><strong>- Heráclito -</strong></a></p>
                </div>


                <div className={styles.marketContainer}>


                    <div className={styles.arauzita}>
                        <div className={styles.titleAndRequiredLevel}>
                            <p className={styles.arauzitaTitle}>Arauzita</p>
                        </div>
                        <div className={styles.contentWrapper}>
                            <p>The arauzita is necessary to found organizations. It also has other utilities that you can find in the documentation on this topic.</p>
                            <p>Current arauzita: <span>{arauzita}</span></p>
                        </div>
                        <div className={styles.buttons}>

                            <button
                                className={styles.arauzitaButton}
                                onClick={() => navigate('member')}
                            >
                                Buy more!
                            </button>

                        </div>
                    </div>

                    <div className={styles.market}>
                        <div className={styles.titleAndRequiredLevel}>
                            <p className={styles.skillTitle}>Knowledge</p>
                        </div>
                        <div className={styles.contentWrapper}>
                            <p>Here you can consult, learn and improve your knowledge, which is necessary to unlock certain actions.</p>
                        </div>
                        <div className={styles.buttons}>

                            <button
                                className={styles.learningButton}
                                onClick={() => navigate('/knowledge')}
                            >
                                Go to section
                            </button>

                        </div>
                    </div>

                    {/* Aquí para mejorar habilidades universitarias, aparece un ránking también de los líderes en cada campo, puesto que es acumulativo por puntos */}
                    <div className={styles.market}>
                        <div className={styles.titleAndRequiredLevel}>
                            <p className={styles.skillTitle}>University</p>
                        </div>
                        <div className={styles.contentWrapper}>
                            <p>Here you can consult, learn and improve your knowledge, which is necessary to unlock certain actions.</p>
                        </div>
                        <div className={styles.buttons}>

                            <button
                                className={styles.learningButton}
                                onClick={() => navigate('/university')}
                            >
                                Go to section
                            </button>

                        </div>
                    </div>

                    <div className={styles.market}>
                        <div className={styles.titleAndRequiredLevel}>
                            <p className={styles.skillTitle}>Contracts</p>
                        </div>
                        <div className={styles.contentWrapper}>
                            <p>Here you can consult the different contracts signed by you, either in your personal name or in the name of an organization.</p>
                        </div>
                        <div className={styles.buttons}>

                            <button
                                className={styles.learningButton}
                                onClick={() => navigate('/knowledge')}
                            >
                                Go to section
                            </button>

                        </div>
                    </div>


                    <div className={styles.market}>
                        <div className={styles.titleAndRequiredLevel}>
                            <p className={styles.skillTitle}>Online banking</p>
                        </div>
                        <div className={styles.contentWrapper}>
                            <p>Here you can access your bank accounts. View the balance of all your accounts, make transfers and check the transaction history.</p>
                        </div>
                        <div className={styles.buttons}>

                            <button
                                className={styles.learningButton}
                                onClick={() => navigate('banking')}
                            >
                                Go to section
                            </button>

                        </div>
                    </div>

                    <div className={styles.market}>
                        <div className={styles.titleAndRequiredLevel}>
                            <p className={styles.skillTitle}>Cold Wallets</p>
                        </div>
                        <div className={styles.contentWrapper}>
                            <p>Here you can access your cryptocurrency cold wallets. View your balances in various cryptocurrencies, make transfers to other wallets and check the transaction history.</p>
                        </div>
                        <div className={styles.buttons}>

                            <button
                                className={styles.learningButton}
                                onClick={() => navigate('crypto')}
                            >
                                Go to section
                            </button>

                        </div>
                    </div>

                    <div className={styles.market}>
                        <div className={styles.titleAndRequiredLevel}>
                            <p className={styles.skillTitle}>Financial position</p>
                        </div>
                        <div className={styles.contentWrapper}>
                            <p>Here you can manage all your financial assets, including stocks, bonds and derivatives. View the performance of each investment, review transaction history and set up your investor profile. You can also check your debts.</p>
                        </div>
                        <div className={styles.buttons}>

                            <button
                                className={styles.learningButton}
                                onClick={() => navigate('crypto')}
                            >
                                Go to section
                            </button>

                        </div>
                    </div>

                    <div className={styles.market}>
                        <div className={styles.titleAndRequiredLevel}>
                            <p className={styles.skillTitle}>Personal life</p>
                        </div>
                        <div className={styles.contentWrapper}>
                            <p>Dive into the world of pops! Buy houses, cars, collect art and build relationships with pops.</p>
                            <p>Only available with premium plan.</p>
                        </div>
                        <div className={styles.buttons}>

                            <button
                                className={styles.learningButton}
                                onClick={() => navigate('b2b')}
                            >
                                Go to section
                            </button>

                        </div>
                    </div>

                    <div className={styles.market}>
                        <div className={styles.titleAndRequiredLevel}>
                            <p className={styles.skillTitle}>Casino</p>
                        </div>
                        <div className={styles.contentWrapper}>
                            <p>Here you can join the casino and play poker against other players in real time by betting currencies or cryptocurrencies.</p>
                            <p>Only available with premium plan.</p>
                        </div>
                        <div className={styles.buttons}>

                            <button
                                className={styles.learningButton}
                                onClick={() => navigate('stock')}
                            >
                                Go to section
                            </button>

                        </div>
                    </div>




                </div>
            </div>

        </div>
    );
};