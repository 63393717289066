
import React, { useEffect, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import PaidIcon from '@mui/icons-material/Paid';
import GroupsIcon from '@mui/icons-material/Groups';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import BusinessIcon from '@mui/icons-material/Business';
import { WorldviewSVGMapV3 } from './WorldviewSVGMapV3';
import styles from './worldviewpage.module.css';
import { calculateInitialPosition } from './InitialPosition';
import { useNavigate } from 'react-router-dom';
import { formatDateWithQuarter } from '../../Utils/DurationToDateTime';

const containerStyle = {
  overflowX: 'hidden', // Oculta el desplazamiento horizontal
  background: '#51699c',
  width: '100%', // Ocupa todo el ancho disponible
  height: '100vh', // Ocupa toda la altura de la ventana
  paddingTop: '18vh', // Establece el margen en 0 para eliminar cualquier margen
  paddingLeft: '26vh'

};

const containerStyle2 = {
  overflowY: 'hidden',
  //overflowX: 'hidden', // Oculta el desplazamiento horizontal
  width: '100%', // Ocupa todo el ancho disponible
  height: '100vh', // Ocupa toda la altura de la ventana
  paddingTop: '0vh', // Establece el margen en 0 para eliminar cualquier margen
  paddingLeft: '26vh',
  display: 'flex',

};


const WorldviewPage = () => {


  const navigate = useNavigate();



  const [cursorStyle, setCursorStyle] = useState('auto');

  // Escudo de la región actual, la última sobre la que se ha pasado el ratón
  const [currentRegion, setCurrentRegion] = useState();

  // Escudo de la región actual, la última sobre la que se ha pasado el ratón
  const [selectedRegionState, setSelectedRegionState] = useState();

  const handleRegionHover = (currentRegion) => {
    setCurrentRegion(currentRegion);
  }

  const handleClickRegion = (regionState) => {
    setSelectedRegionState(regionState);
  }



  const handleZoom = (e) => {
    const value = e.state.scale;
    setCursorStyle(value > 1 ? 'grab' : 'auto');
  }

  const initialPosition = calculateInitialPosition();


  return (


    <div className={styles.container}>

      <div>

        <div className={styles.sidebar}>


          <div className={styles.regionContainer}>

            {currentRegion &&
              <div className={`${styles.region} ${styles.currentRegion}`} onClick={() => navigate(`/profile/region/${currentRegion.username}`)}>
                <img className={styles.shield} src={currentRegion.shieldUrl} />
                <div className={styles.regionDetails}>
                  <p className={styles.regionTitle}><strong>{currentRegion.name}</strong></p>
                  <p className={styles.regionSubtitle}>Current region</p>
                </div>
              </div>}

            {selectedRegionState &&
              <div className={styles.region} onClick={() => navigate(`/profile/region/${selectedRegionState.region.username}`)}>
                <img className={styles.shield} src={selectedRegionState.region.shieldUrl} alt="Escudo de Galicia" />
                <div className={styles.regionDetails}>
                  <p className={styles.regionTitle}><strong>{selectedRegionState.region.name}</strong></p>
                  <p className={styles.regionSubtitle}>Selected region</p>
                </div>
              </div>}

            {selectedRegionState &&
              <div className={styles.region} onClick={() => navigate(`/profile/state/${selectedRegionState.state.username}`)}>
                <img className={styles.flag} src={selectedRegionState.state.flagUrl} alt="Escudo de España" />
                <div className={styles.regionDetails}>
                  <p className={styles.regionTitle}><strong>{selectedRegionState.state.name}</strong></p>
                  <p className={`${styles.regionSubtitle} ${styles.proprietaryStateP}`}>Proprietary state</p>

                </div>
              </div>}

          </div>


          <div className={styles.rightContainer}>
            <p>UTC | {formatDateWithQuarter()}</p>
          </div>

        </div>

        <div className={styles.mapContainer}>

          <TransformWrapper
            initialScale={6.0}
            initialPositionX={initialPosition.x}
            initialPositionY={initialPosition.y}
            minScale={1.3}
            maxScale={30}  // Escala máxima permitida, ajusta este valor según tus necesidades
            disabled={false}
            limitToBounds={true}
            onZoom={(value) => handleZoom(value)}
            wheel={{ smoothStep: 0.01 }}
          >
            <TransformComponent>
              <WorldviewSVGMapV3 onRegionHover={handleRegionHover} clickRegion={handleClickRegion} />
            </TransformComponent>
          </TransformWrapper>

          {/* Esto es para hacerlo sin saltos, pero no es como en RR ---> wheel={{ step: 0.9 }} */}

        </div>

      </div>

    </div>

  );
};

export default WorldviewPage;
